maxkir.ItemLinkExtension = Object.assign({

  ITEM_RE: /(^|[^|'"=([>\w])(https?:\/\/[^/]+)?\/(?:checklists\/\d+(?:\/tasks\/|#task_)|cvt\/)(\d+)/g,
  MARKDOWN_ITEM_RE: /\[([^\]]*)]\((?:https?:\/\/[^/]+)?\/(?:checklists\/\d+(?:\/tasks\/|#task_)|cvt\/)(\d+)\)/g,

  _page_cache: {},

  rerender_links_to: function(task_id) {
    var links = document.querySelectorAll(".itemLink__link[data-task-id='" + task_id + "']");

    links.forEach(function(link) {
      var extraCss = maxkir.Task.renderer(task_id).closed_class();
      link.className = 'itemLink__link' + extraCss + " hoverPopup";

    }, this);
  },

  process_text: function(s, context) {
    var that = this;

    var item_re = this.ITEM_RE;
    item_re.lastIndex = 0;
    var res = s.replace(item_re, function(match, charBefore, host_part, task_id) {
      return host_part ? that._create_task_link(charBefore, match, task_id, context,'') : match;
    })

    item_re = this.MARKDOWN_ITEM_RE;
    item_re.lastIndex = 0;
    return res.replace(item_re, function(match, link_text, task_id) {
      return that._create_task_link('', match, task_id, context, link_text);
    })
  },

  /**
   * @param charBefore
   * @param fallbackText
   * @param task_id
   * @param {RenderContext} context
   * @param [explicit_link_text] Autogenerated if unset
   * @private
   */
  _create_task_link: function(charBefore, fallbackText, task_id, context, explicit_link_text) {
    var linked_task = this._linked_task(task_id, context);
    var that = this;
    if (linked_task) {
      var extraCss = this._closed_class(linked_task);
      var prefix = "<span class='itemLink'><i class='fal fa-link'></i>";

      var new_context = Object.assign({}, context);
      new_context.task_stack = new_context.task_stack || {};
      new_context.task_stack[context.task_id] = 1;
      if (new_context.task_stack[linked_task.id]) {
        // Link cycle
        new_context.no_extensions = true;
      }
      new_context.task_id = linked_task.id;

      var link_text = explicit_link_text ? explicit_link_text :
                      maxkir.CommonRender.format_for_breadcrumbs(linked_task.content, true, new_context);
      link_text = link_text.replace(/\n/g, ' ');

      return charBefore + prefix +
        "<a href='/cvt/" + task_id + "' class='itemLink__link" + extraCss +
        " hoverPopup' data-popup-id='taskPopup' data-task-id='" + task_id + "'>" + link_text + "</a>" +
        "</span>";
    }
    else if (!that.rerendering()) {
      var renderedTaskId = context.task_id;

      that._fetch_task_data_by_id(task_id, context).then(() => {
        that.without_rerender(function() {
          that._update_task(renderedTaskId, context);
        })
      });
    }
    return fallbackText;
  },

  _linked_task: function(task_id, context) {
    if (context.is_pwa) {
      return mxTask.store[task_id] || this._page_cache[task_id];
    }
    return maxkir.Task.data(task_id);
  },

  _fetch_task_data_by_id: function(task_id, context) {
    if (context.is_pwa) {
      return mxTask.findOne({id: task_id}).then((task_data) => {
        this._page_cache[task_id] = task_data;
        return task_data;
      });
    }
    return maxkir.Task.fetch_tasks_from_server_bulk([task_id])
  },

  _closed_class: function(task_data) {
    return task_data.status === 1 ? ' task_closed' : task_data.status === 2 ? ' task_invalidated' : "";
  },


  /**
   * Finds text for the corresponding URL, if possible
   * @param url
   * @return Promise<string>
   */
  resolve_to_text: function(url) {
    var get_text = function(task_data) {
      return maxkir.CommonRender.format_for_breadcrumbs(task_data.content, true);
    };

    var item_re = this.ITEM_RE;
    item_re.lastIndex = 0;
    var match = item_re.exec(url);
    if (match) {
      var task_id = match[3];
      var data = maxkir.Task.data(task_id);
      if (data) {
        return Promise.resolve(get_text(data));
      }
      else {
        return maxkir.Task.fetch_tasks_from_server_bulk([task_id]).then(function() {
          var data = maxkir.Task.data(task_id);
          if (data) {
            return get_text(data);
          }
          else {
            return Promise.reject();
          }
        })
      }
    }
    return Promise.reject();
  }

}, maxkir.BaseRenderExtension)

maxkir.RenderExtensions.add_renderer(maxkir.ItemLinkExtension, true)