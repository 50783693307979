define(function (require, exports, module) {

    var app = require('app');
    var $ = require('jquery');
    var TempObjects = require('app/model/temp_objects');
    var can = app.can;

    /** These parameters should not be used as names of the fields of the tables */
    var special_params = {
        logUUID: true,       // contains logging prefix for the query
        index_name: true,    // contains index name for the query
        force_server: true,  // [TESTS] force loading data from server only
        only_update_from_server: true,  // force loading data from server, and do not remove relevant records from DB
        sort_func: true,     // If present, returned data records are sorted with the passed function
        skip_server: true,    // If true, avoid refreshing data from server after obtaining it from cache/memory
        use_memory_cache: false // If true, data will be stored additionally in a memory cache for faster access
    };

    var dump = app.util.dump;
    var dump_memory_cache = function(map) {
        var res = '';
        for(var k in map) {
            res += " " + k + "\n     " + dump(map[k]) + "\n";
        }
        return res;
    };

    /**
     * CanJS model implementation which performs caching in-memory and in IndexedDB
     *
     * Methods to be implemented in subclasses are at the top
     *
     * To make sure data is updated in DB, should call super when implementing
     * <ul>
     *     <li> update
     *     <li> destroy
     * </ul>
     *
     *
     * @class CachingModel
     */
    var CachingModel = can.Model.extend({

        /**
         * @type {Object.<String, can.Model.List>}
         */
        _memory_cache: null,

        /**
         * Keeps per-id deferreds for synchronization
         * @type {Object.<number, Deferred>}
         */
        _individual_records_updaters: null,

        /**
         * Keeps a map of explicitly deleted items
         * @type {Object.<number, boolean>}
         */
        _deleted_items: null,

        /**
         * @type maxkir.Listeners
         */
        _db_listeners: null,
        
        init_class: function() {
            if (!this._memory_cache) {
                this._memory_cache = {};
                this._individual_records_updaters = {};
                this._deleted_items = {};
            }
        },

        /**
         * @typedef {Object} DBListener
         * @property {Function} onSave(records)
         */

        /**
         * @param {DBListener} listener
         */
        add_db_listener: function(listener) {
            this._listeners().add_listener(listener);
        },
        
        _listeners: function() {
            if (!this._db_listeners) {
                this._db_listeners = new maxkir.Listeners();
            }
            return this._db_listeners;
        },

        /**
         * @param {DBListener} listener
         */
        remove_db_listener: function(listener) {
            this._listeners().remove_listener(listener);
        },
        
        /**
         * @returns {String} IndexedDB table name for this model
         */
        get_table_name: function() {
            throw new Error("To be implemented in subclass")
        },

        /**
         * Return a deferred which resolves to a JSON array of records for this model.
         * In case of findOne - should resolve to the obtained element.
         *
         * @param params findAll/findOne parameters (for findOne, 'id' parameter is mandatory)
         * @returns {Deferred}
         */
        get_load_data_deferred: function(params) {
            throw new Error("To be implemented in subclass " + params);
        },


        /**
         * Implementing CanJS caching layer for findAll operation
         */
        makeFindAll: function(findAllData) {

            // To avoid double-proxy of the function
            if (!this._find_all_cached) {
                this._find_all_cached = function (params, success, error) {

                    var result = new $.Deferred();
                    result.then(success, error);

                    params = params || {};
                    this._normalize_id(params);

                    if (params.id > 0) {
                        throw new Error("Cannot run findAll when id is specified: " + JSON.stringify(params))
                    }
                    if (!Object.keys(this._data_params(params)).length) {
                        // Empty data params - force memory cache
                        params.use_memory_cache = true;
                    }

                    return this._process_find_call(findAllData, params, result);
                }
            }

            return this._find_all_cached;
        },
        
        _data_params: function(params) {
            var res = {};
            for(var k in params) {
                if (params.hasOwnProperty(k)) {
                    if (typeof special_params[k] === 'undefined') {
                        res[k] = params[k];
                    }
                }
            }
            return res;
        },

        _process_find_call: function(find_data_call, params, result) {
            var force_server = params.force_server || params.only_update_from_server;
            var skip_server = params.skip_server || (params.id && TempObjects.is_local(params.id));

            var that = this;
            var call_server = function _call_server() {
                find_data_call.call(that, params).then(result.resolve, result.reject);
            };

            if (force_server) {
                call_server();
                return result;
            }

            if (skip_server || this.has_data_in_db(params) || params.id) {
                this._load_data_from_storage(params).then(function data_loaded_from_storage(data) {
                    result.resolve(data);

                    if (!skip_server) {
                        call_server();
                    }
                }, function fail_load_data_from_storage() {
                    if (skip_server) {
                        result.reject.apply(result, arguments);
                    }
                    else {
                        call_server();
                    }
                });
            }
            else {
                call_server();
            }
            return result;
        },

        /**
         * Implementing CanJS caching layer for findOne operation
         */
        makeFindOne: function(findOneData) {
            // To avoid double-proxy of the function
            if (!this._find_one_cached) {

                this._find_one_cached = function (params, success, error) {

                    var result = new $.Deferred();
                    result.then(success, error);

                    this._normalize_id(params);

                    if (!params.id) {
                        throw new Error("Cannot run findOne when id is not specified: " + JSON.stringify(params))
                    }

                    this._process_find_call(findOneData, params, result);

                    return result;
                };
            }

            return this._find_one_cached;
        },

        /**
         * Update the state of the model in the database.
         * This method is called from save() method when there is ID in the model.
         *
         * @param id id of the model
         * @param attrs attributes of the model
         * @returns {Deferred} which resolves to the resolved updated model
         */
        update: function(id, attrs ) {
            attrs.id = id;
            return this.update_model(attrs, {id: id}, "update" + id + " ");
        },


        /**
         * Destroy model record from the database
         * This method is called from destroy() method when there is ID in the model.
         *
         * @param id id of the model
         * @returns {Deferred} which resolves to success when the record is updated
         */
        destroy: function(id) {
            return this.update_model(null, {id: id}, "destroy" + id + " ");
        },

        /**
         * @param id
         * @return {Deferred}
         */
        findLocal: function(id) {
            return this.findOne({id: id, skip_server: true});
        },

        /**
         * Update model item from given JSON.
         * Existing attributes are preserved unless parameter 'overwrite' is set
         * @param {Array} deferreds this array of deferreds will be populated with deferreds for item update
         * @param json JSON for the model, must contain 'id' field
         * @param {boolean} overwrite if set, existing attributes in the model will be removed
         */
        update_from_json: function(deferreds, json, overwrite) {
            var that = this;
            deferreds.push(this.findLocal(json.id).then(function(t) {
                t.attr(that.parseModel(json), overwrite);
                deferreds.push(t.save());
            }));
        },

        /**
         * Create model item from given JSON.
         * @param json JSON for the model, must contain 'id' field
         * @return Promise for save
         */
        create_from_json: function(json) {
            var model = new this(this.parseModel(json)); 
            return model.save(); 
        },

        /**
         * Calculate number of records in local database, which correspond to given parameters
         * @return {Deferred} Deferred which resolves number of records according to params
         * */
        count_db: function(params) {
            var that = this,
                res = $.Deferred(),
                logUUID = "cnt_" + this._logUUID();

            params = params || {};
            
            app.storage.open_db()
                .then(function(server){
                    if (params.id) {
                        that._get_one_record_query(server, params, res, logUUID);
                    }
                    else {
                        var toExecute = that.build_query_several_records(server, params, logUUID);
                        toExecute.execute().then(function (results) {
                            res.resolve(that.models(results));
                        }, res.reject);
                    }
                }, res.reject);

            return res.then(function(data) {
                return typeof data.length !== 'undefined' ? data.length : data ? 1 : 0;
            }, function(e) {
                that.db_log(logUUID + "Cannot get number of records in " + that.get_table_name(), params);
            });
        },



        findAll: function(params) {
            return this._findXXX(params, 'findAll');
        },

        findOne: function(params) {
            return this._findXXX(params, 'findOne');
        },
        
        _findXXX: function(params, name) {
            return this._load_data_from_server(params || {});
        },
        
        sync_key: function(params) {
            return this.get_table_name();
        },

        _normalize_id: function(params) {
            // Normalize parameters ID
            if (params.id) {
                params.id = this.fix_id(params.id);
            }
        },
        
        fix_id: function(id) {
            if ('' + id === '' + parseInt(id)) {
                return parseInt(id);
            }
            return id;
        },


        _load_data_from_server: function(params) {

            if (!app.current_user) {
                return Promise.reject("No current user");
            }

            var that = this;
            var logUUID = params.logUUID || ("S_" + this._logUUID());
            var get_data_from_server_request = function() {

                return new Promise(function(resolve, reject) {
                    that.log(logUUID + "Loading data from server for " + that.to_cache_key(params));

                    that.get_load_data_deferred(params).then(function(data) {
                        // Update local data cache
                        // Data is either array (for findAll) or single value (for findOne)

                        that.update_model(data, params, logUUID).then(resolve, reject);
                    }, function(error) {
                        
                        var msg = params.id > 0 ? I18N.t("no." + that.get_table_name() + ".with.id", params.id) : 
                            "Fail loading " + that.get_table_name() + " from server";

                        if (error.statusText) {
                            error = app.create_ajax_error(error, msg, "ERR_LOAD_SERVER_DATA");
                        }
                        
                        reject(error);
                    });
                })
            };
            
            return new Promise(function(ok, fail) {

                var resolve = function(data) {
                    data._from_cache = false;
                    ok(data);
                };

                // Make the first request
                get_data_from_server_request().then(resolve,
                    function(initial_reject_reason) {

                        // Process case of 'abort':
                        if (initial_reject_reason.is_abort) {
                            fail(initial_reject_reason);
                            return;
                        }

                        if (app.current_user && initial_reject_reason.auth_problem) {
                            that.log(logUUID + "Cannot get data the first time. Trying to re-authenticate and try again.");
                            app.current_user.refresh_login()
                                .then(function() {
                                    // Login successful. Get data again
                                    get_data_from_server_request().then(resolve, fail)
                                }, function() {
                                    fail(initial_reject_reason);
                                });
                        }
                        else {
                            that.log(logUUID + "No current user or not an authentication problem - rejecting data load");
                            fail(initial_reject_reason);
                        }
                    });

            })
        },

        _logUUID: function() {
            return maxkir.uuid().substr(0, 4) + " ";
        },

        _load_data_from_storage: function(params) {
            var res = $.Deferred();
            var logUUID = params.logUUID || this._logUUID();
            var that = this;

            var key = this.to_cache_key(params);
            var inMemory = this.get_memory_cache(params);
            if (inMemory) {
                that.log(logUUID + "Has data in memory for key " + key, inMemory.length);
                inMemory._from_cache = true;
                res.resolve(inMemory);
            }
            else {
                app.storage.open_db()
                    .then(function db_opened(server){

                        if (params.id) {
                            that._get_one_record_query(server, params, res, logUUID);
                        }
                        else {
                            that.db_log(logUUID + "Requesting records for key: " + key);

                            var toExecute = that.build_query_several_records(server, params, logUUID);

                            toExecute.execute().then(function got_records_from_db(results) {

                                inMemory = that._update_memory_model(params, results, logUUID);
                                inMemory._from_cache = true;
                                that._items_to_update_in_db = null;

                                res.resolve(inMemory);

                                }, res.reject);
                        }

                    }, res.reject );
            }

            var msg = (inMemory ? " from memory for " : " from DB for ") + key;
            res.then(function (data) {
                if (params.id) {
                    that.log(logUUID + "Got record" + msg, data._cid, data.attr());
                }
                else {
                    that.log(logUUID + "Got records" + msg, data._cid, data.length);
                }
            }, function (e) {
                that.log(logUUID + "Could not get data" + msg, e);
            });


            return res;
        },

        _get_one_record_query: function(server, params, res, logUUID) {
            var table_name = this.get_table_name(),
                that = this;
            this._normalize_id(params);
            that.db_log(logUUID + "Requesting record #" + params.id);

            server.get(table_name, params.id).then(function got_record_from_db(data) {
                if (data) {
                    res.resolve(that.model(data));
                }
                else {
                    res.reject(I18N.t("no." + table_name + ".in.storage.with.id", params.id));
                }
            }, res.reject);
        },

        build_query_several_records: function(server, params, logUUID) {
            var table_name = this.get_table_name();
            var to_run, that = this,
                index_name = params['index_name'],
                hasFilter = false;

            if (index_name) {
                that.db_log(logUUID + "Will use index " + index_name);
                to_run = server.query(table_name, index_name).only(params[index_name]);
                hasFilter = true;
            }
            else {
                that.db_log(logUUID + "No index");
                to_run = server.query(table_name);
            }

            for(var k in this._data_params(params)) {
                if (k != index_name) {
                    to_run = to_run.filter(k, params[k]);
                    that.db_log(logUUID + "Added filter on " + k + "=" + params[k]);
                    hasFilter = true;
                }
            }

            if (!hasFilter) {
                to_run = to_run.all();
            }

            return to_run;
        },

        to_cache_key: function(object) {
            var keys = Object.keys(this._data_params(object));
            keys.sort();
            var result = "";
            keys.forEach(function(key) {
                result += key + "=" + JSON.stringify(object[key]) + ";"
            });
            return this._key_prefix() + result;
        },

        /**
         * Update local data cache, both in memory and in IndexedDB
         *
         * @param data null or instance, or array of data. If params.id is not set, should be array of instances or null; if id is set - should be single instance
         * @param params parameters, for which data in the database is updated, mandatory
         * @returns {Deferred} returns deferred which resolves to can.List.Model for the obtained data (even if there is one instance for findAll)
         * @param logUUID Logging identifier helper
         */
        update_model: function(data, params, logUUID) {

            var that = this;
            this.init_class(params);
            this._normalize_id(params);

            if (params.id) {
                var result = $.Deferred();

                // Start synchronize block for this id
                if (this._individual_records_updaters[params.id]) {
                    // Another update in progress, run this one after the existing
                    this._individual_records_updaters[params.id].then(function() {
                        that.update_model(data, params, logUUID).then(result.resolve, result.reject);
                    }, result.reject);
                }
                else {
                    if (this._deleted_items[params.id] && data) {
                        this.db_log(logUUID + " Failed to update deleted item " + params.id);
                        return $.Deferred().reject();
                    }

                    // First update for this ID in progress, save it
                    this._individual_records_updaters[params.id] = result;
                    this._do_update_model(data, params, logUUID).always(function() {
                        delete that._individual_records_updaters[params.id];
                    }).then(result.resolve, result.reject);
                }
                return result;
            }
            else {
                return this._do_update_model(data, params, logUUID);
            }

        },

        _do_update_model: function(data, params, logUUID) {
            var db_cache_mark = !!data ? new Date().getTime() : null;


            var resolve_result = this._update_memory_model(params, data, logUUID);
            var that = this,
                result = this._update_storage(data, resolve_result, params, logUUID);

            result.then(function() {
                that.db_log(logUUID + " Done update_model (memory + DB)");
                if (params.id) {
                    if (!data) {
                        that._deleted_items[params.id] = true;
                    }
                }
                else {
                    that._touch_db_cache_mark(params, db_cache_mark, logUUID);
                }
            });
            return result;
        },

        _sort_and_remove_deleted: function(params, array) {
            var removed = this._removed_ids;
            if (!$.isEmptyObject(removed)) {
                for(var i = 0; i < array.length; i ++) {
                    if (removed[array[i].id]) {
                        array = array.splice(i, 1);
                    }
                }
            }
            this._removed_ids = {};
            if (typeof params.sort_func === 'function') {
                params.sort_func(array);
            }
        },

        /**
         *
         * Resolve to can.Model/list and cache data to memory (if needed)
         *
         * @private
         * @return {can.Model.List|can.Model} resolved data, possibly cached in memory
         */
        _update_memory_model: function(params, data, logUUID) {
            var resolve_result;

            this._removed_ids = this._removed_ids || {};
            this._items_to_update_in_db = null;
            if (params.id) {

                if (data) {
                    resolve_result = this.model(data);
//                    this.log("Updated memory model data #" + params.id, data);
                }
                else {
                    this._removed_ids[params.id] = true;
//                    this.log("Clear memory model data #" + params.id, this.get_table_name(), memory_cache);
                }
                
                // Update memory cache if needed with the new data:
                var memoryCache = this._memory_cache;
                for(var key in memoryCache) {
                    if (memoryCache.hasOwnProperty(key)) {
                        var existing;
                        
                        memoryCache[key].forEach(function(model, idx) {
                            if (model.id == params.id) {
                                existing = model;
                                existing_idx = idx;
                            }
                        });
                        if (!existing && data) {
                            app.debug("Add newly created element to memory cache " + key, resolve_result);
                            memoryCache[key].push(resolve_result)
                        }
                        if (existing && !data) {
                            app.debug("Remove existing element from memory cache " + key, existing);
                            existing.deleted = true; // To allow its removal from model on next update
                        }
                    }
                }

            }
            else {
                data = data || [];


                var cache_key = this.to_cache_key(params);
                resolve_result = this.get_memory_cache(params);

                // console.warn("cache_key: " + cache_key);
                // console.warn("new data: " + dump(data));
                // console.warn("Store: " + dump(Object.values(this.store)));
                // console.warn("Existing resolve: " + dump(resolve_result));
                // console.warn("Memory cache old: " + dump_memory_cache(this._memory_cache));

                if (!resolve_result) {
                    this._sort_and_remove_deleted(params, data);
                    resolve_result = this.models(data);
                    if (params.use_memory_cache) {
                        this._memory_cache[cache_key] = resolve_result;
                        resolve_result.bind('change', this.model_list_listener);
                    }
                } 
                else {

                    this.log(logUUID + "Updating memory model data with key " + cache_key + "; " + resolve_result.length + " existing records");

                    var new_data_map = {};
                    data.forEach(function (item, idx) {
                        new_data_map[item.id] = item;
                    });

                    var existing_ids = {};
                    var items_to_delete = [];
                    var local_items = [];
                    resolve_result.forEach(function (item, idx) {
                        existing_ids[item.id] = item;
                        var keep_local = item.is_local && !item.deleted;
                        if (!new_data_map[item.id] && !keep_local) {
                            items_to_delete.push(item);
                        }
                        if (keep_local) {
                            local_items.push(item);
                        }
                    });

                    var items_to_add = [];
                    for (var id in new_data_map) {
                        if (new_data_map.hasOwnProperty(id) && !existing_ids[id]) {
                            items_to_add.push(new_data_map[id])
                        }
                    }

                    // We should:
                    // 1. update existing items
                    // 2. add new items
                    // 3. remove deleted items
                    // If item is temporary, we should not remove it


                    // TODO: When updating data in the DATABASE - should update only changed records!
                    if (items_to_add.length > 0 || items_to_delete.length > 0) {
                        this.log(logUUID + "DO FULL UPDATE with key " + cache_key, data.length, "local items: " + local_items.length);

                        data = data.concat(local_items);
                        this._sort_and_remove_deleted(params, data);

                        app.replace_model(resolve_result, data);
                    }
                    else {
                        this.log(logUUID + "DO INCREMENTAL UPDATE with key " + cache_key, data.length);
                        var items_to_update_in_db = [];
                        var that = this;
                        resolve_result.forEach(function (item, idx) {
                            // !no incremental update for local item
                            var new_attrs = that.parseModel(new_data_map[item.id]);
                            if (new_attrs && !that._is_equal(new_attrs, item.serialize())) {
                                // console.warn("old", item.serialize())
                                // console.warn("new", new_attrs)
                                item.attr(new_attrs, true);
                                items_to_update_in_db.push(new_attrs);
                            }
                        });
                        this._items_to_update_in_db = items_to_update_in_db;
                    }

                    this.log(logUUID + "Updated  memory model data with key " + cache_key, data.length)
                }

                // console.warn("Store new: " + dump(Object.values(this.store)));
                // console.warn("Resolve new: " + dump(resolve_result));
                // console.warn("Memory cache new: " + dump_memory_cache(this._memory_cache));
            }
            
            return resolve_result;
        },

        model_list_listener: function model_list_listener(ev, attr, how, newVal, oldVal) {
            //console.info("model_list_listener", ev, attr, how, newVal, oldVal);
        },

        _is_equal: function(a, b) {
            return app.util.is_equal(a, b);
        },

        /**
         * @param params
         * @returns {can.Model.List} - cached data for the given params
         */
        get_memory_cache: function(params) {
            if (!params.use_memory_cache) {
                return null;
            }
            
            this.init_class();
            this._normalize_id(params);
            var existingKeys = Object.keys(this._memory_cache);
            if (existingKeys.length && existingKeys[0] != this.to_cache_key(params)) {
                this._reset_memory_cache();
            }
            return this._memory_cache[this.to_cache_key(params)];
        },

        /***
         * @param data could be null, instance, or array of records
         * @param resolve_result value to resolve with on success
         * @param params parameters of the query
         * @param {string} [logUUID=autogenerated] logging UUID
         * @returns {Deferred}
         * @private
         */
        _update_storage: function(data, resolve_result, params, logUUID) {
//            console.info("_update_storage", data, data_model, params);
            var res = $.Deferred();
            var table_name = this.get_table_name();
            var that = this;
            logUUID = logUUID || maxkir.uuid().substr(0, 8) + " ";

            var store_data = function(data) {
                if (data) {

                    if (typeof data.length === "undefined") {
                        data = [data];
                    }
                    if (data.length > 0) {
                        app.storage.update_records_in_db(table_name, data).then(function (recs) {
                            that.db_log(logUUID + "Written " + recs.length + " records");
                            res.resolve(resolve_result);
                            that._listeners().notify_listener('onSave', recs);
                        }, res.reject);
                    }
                    else {
                        res.resolve(resolve_result);
                    }
                }
                else {
                    res.resolve(resolve_result);
                }
            };

            app.storage.open_db()
                .then(function(server){
                    if (!server[table_name]) {
                        app.error(logUUID + "Cannot find table " + table_name);
                        res.resolve([]);
                        return;
                    }

                    if (that._items_to_update_in_db) {
                        var items_to_update = that._items_to_update_in_db;
                        that._items_to_update_in_db = null;

                        that.db_log(logUUID + "Updating DB records incrementally: " + items_to_update.length);
                        store_data(items_to_update);
                        return;
                    }

                    if ($.isEmptyObject(params)) {
                        server[table_name].clear().then(function() {
                            that.db_log(logUUID + "Removing ALL records");
                            store_data(data);
                        }, res.reject);
                    }
                    else {
                        if (params.id && data != null) {
                            that.db_log(logUUID + "Storing single record", data);
                            store_data(data);
                        }
                        else if (params.id) {
                            that.db_log(logUUID + "Removing record for key " + params.id);
                            server[table_name].remove(params.id).then(function() {
                                // Set timeout is needed for commit to pass before loading data
                                setTimeout(function() {
                                    res.resolve();
                                }, 10)
                            }, res.reject);
                        }
                        else {
                            if (params.only_update_from_server) {
                                store_data(data);
                            }
                            else {
                                var toExecute = that.build_query_several_records(server, params, logUUID);
                                toExecute.execute().then(function (results) {
                                    that._remove_obsolete_records(res, data, results, params, logUUID, store_data);
                                }, res.reject);
                            }

                        }

                    }

                }, res.reject);

            return res;
        },

        _remove_obsolete_records: function(res, data, records_from_db, params, logUUID, store_data_function) {
            var that = this;
            var table_name = this.get_table_name();
            var new_data_map = {};
            (data || []).forEach(function(d) { new_data_map[d.id] = d });

            app.storage.open_db().then(function (server){
                // Collect all records which should be removed from the DB
                var removes = [];
                records_from_db.forEach(function(rec) {
                    // remove only those records which are not present in the saved data
                    // Temporary records (those with uuid) are kept as well
                    if (!new_data_map[rec.id]) {
                        var remove = !rec.is_local || rec.deleted; 
                        if (remove) {
                            removes.push(server[table_name].remove(rec.id));
                        }
                    }
                });

                that.db_log(logUUID + "Removing " + removes.length + " records for key " + that.to_cache_key(params));
                app.run_all(removes).then(function() {
                    store_data_function(data);
                }, res.reject);

            }, res.reject);
        },

        reset_cache: function(params) {
            var res = this.update_model(null, params, "reset_cache ");
            this.reset_memory_cache(params);
            return res;
        },

        reset_memory_cache: function(params) {
            this.init_class();
            this._deleted_items = {};
            this._normalize_id(params);
            if ($.isEmptyObject(params)) {

                this._reset_memory_cache();
                app.storage.clear_items_start_with(this._key_prefix());
            }
            else {
                var c = this._memory_cache[this.to_cache_key(params)];
                if (c) {
                    c.unbind('change', this.model_list_listener);
                    delete this._memory_cache[this.to_cache_key(params)];
                }
            }
        },

        _reset_memory_cache: function() {
            this.init_class();

            var memoryCache = this._memory_cache;
            for(var key in memoryCache) {
                if (memoryCache.hasOwnProperty(key)) {
                    memoryCache[key].unbind('change', this.model_list_listener);
                    delete this._memory_cache[key];
                }
            }
        },

        _key_prefix: function() {
            return this.get_table_name() + "|"
        },

        has_data_in_db: function(params) {
            if (!params) throw new Error("Empty params for cache request");
            return app.storage.get_item(this.to_cache_key(params));
        },

        _touch_db_cache_mark: function(params, value, logUUID) {
            if (!params) throw new Error("Empty params for cache request");
//            this.log(logUUID + "Cache marker update for " + this.to_cache_key(params) + ": " + value);
            app.storage.set_item(this.to_cache_key(params), value || null);
        },

        log: function() {
            var args = [];
            args[0] = "[" + this.get_table_name() + "] " + arguments[0];
            for(var i = 1; i < arguments.length; i ++) {
                args.push(arguments[i]);
            }
            app.debug.apply(this, args);
        },

        db_log: function() {
            var args = [];
            args[0] = "[DB] [" + this.get_table_name() + "] " + arguments[0];
            for(var i = 1; i < arguments.length; i ++) {
                args.push(arguments[i]);
            }
            app.debug.apply(app, args);
        }

    }, {
        toString: function() {
            return this.constructor.get_table_name() + "[id=" + this.id + "]";
        },

        serialize: function() {
            var res = can.Model.prototype.serialize.apply(this, arguments);
            delete res._cid;
            delete res._from_cache;
            return res;
        },

        /** Return numeric ID for this record */
        id_num: function() {
            return parseInt(this.id);
        },

        log: function() {
            this.constructor.log.apply(this.constructor, arguments);
        }
    });



    module.exports = CachingModel;
});
