// Uses:
// maxkir.extend
// maxkir.debug
// maxkir.visible
// maxkir.$

// maxkir.hasClassName(el, className)
// maxkir.addClassName(el, className)
// maxkir.removeClassName(el, className)
// maxkir.NodeToggle (opt)

/*-------------- Tree Selection/Navigation support -----------------*/
// maxkir.TreeNavigation
(function(maxkir) {

  maxkir.TreeNavigation = function(treeModel, tree_selection_name, selected_class_name, support_multiple_selection) {
    if (!selected_class_name) {
      selected_class_name = 'selected';
    }
    this._tree = treeModel;
    this._sel_name = tree_selection_name;
    this._selected_class = selected_class_name;
    this._selected_class_focus = selected_class_name + "_focus";
    this._support_multiple = support_multiple_selection;
    this._cycle = true;

    this._positions = [];
    this._position_idx = -1;

    /**
     * @type {Array<String>}
     * @private
     */
    this._selected = [];

    this.restore_selection();
  };

  maxkir.extend(maxkir.TreeNavigation, {
    _tree: null,
    
    tree_model: function() {
      return this._tree;
    },

    disable_cycling: function() {
      this._cycle = false;
    },

    selectFirstInTree: function() {
      var first = this._tree.getFirstNode();
      if (first) {
        this.set_selection(first);
      }
    },

    selectLastInTree: function() {
      var nodes = this._tree.getNodes(this._tree.getRoot());
      if (nodes.length === 0) {
        this.set_selection(this._tree.getRoot());
        return;
      }

      while(true) {
        var last = nodes[nodes.length - 1];
        nodes = this._tree.getNodes(last);
        if (nodes.length === 0) {
          this.set_selection(last);
          return;
        }
      }
    },

    is_first_node: function(node) {
      return this._tree.getFirstNode() === node;
    },

    is_last_node: function(node) {
      var that = this;
      var find_last_in_nodes = function(nodes) {
        var last = nodes[nodes.length - 1];
        var sub_nodes = that._tree.getNodes(last);
        if (sub_nodes.length > 0) {
          return find_last_in_nodes(sub_nodes);
        }
        return last;
      };

      var nodes = this._tree.getNodes(this._tree.getRoot());
      if (nodes.length > 0) {
        return node === find_last_in_nodes(nodes);
      }
      else {
        return true;
      }
    },

    _visible: function(el) {
      return maxkir.visible(el) && !maxkir.hasClassName(el, 'hiddenInit');
    },

    fix_selection: function(do_not_expand, exclude_node_id) {
      var el = this.get_selection();

      if (maxkir.$(el) && el !== exclude_node_id) {
        if (this._visible(el)) {
          this.fix_existing_selection();
        }
        else {
          this._fix_selection_for_element_in_dom(el, !do_not_expand, exclude_node_id);
        }
      }
      else {
        this._set_selection_from_anchor(do_not_expand, exclude_node_id);
      }
    },

    _set_selection_from_anchor: function(do_not_expand, exclude_node_id) {

      var candidate = this.nodeFromPosition(this._tree_position);
      if (maxkir.$(candidate)) {
        this._fix_selection_for_element_in_dom(candidate, !do_not_expand, exclude_node_id);
      }
    },

    _expand_parents: function(node_id) {
      if (maxkir.NodeToggle) {
        // expand parents until node is visible again
        var parents = this._tree.getParents(node_id);
        if (parents) {
          parents.forEach(function (t) {
            maxkir.NodeToggle.expandNode(t);
          });
        }
      }
    },

    _fix_selection_for_element_in_dom: function(el, allowExpand, exclude_node_id) {
      var good_element = function(el) {
        return this._visible(el) && el !== exclude_node_id;
      }.bind(this);

      if (good_element(el)) {
        this.set_selection(el);
      }
      else {

        // element is in DOM, but not visible or should be ignored;

        // Expand parents if needed:
        if (allowExpand) {
          this._expand_parents(el);
        }

        this._do_without_cycle(function () {
          var done = false;
          var go_back = false;
          while (el && !good_element(el) && !done) {

            var el2 = null;
            if (!go_back) {
              el2 = this.getNext(el, false, true);
            }

            if (el2 == null || el2 === el) {
              go_back = true;
              el2 = this.getPrev(el, true, true)
            }

            if (el2 === el) {
              var first = this._tree.getFirstNode();
              if (good_element(first)) {
                this.set_selection(first);
              }
              else {
                this.set_selection(null);
              }
              done = true;
            } else {
              el = el2;
            }
          }

        });

        if (good_element(el)) {
          this.set_selection(el);
        }

      }
    },

    fix_existing_selection: function() {
      this.set_selection(this.get_all_selection());
    },

    key_down: function(selectedNode) {
      if (window.curr_message) {
        window.curr_message.hide();
      }

      var next;
      if (selectedNode) next = this.getNext(selectedNode, true);
      if (next) {
        this.set_selection(next);
      }
      else {
        this.selectFirstInTree();
      }

      return selectedNode != this.get_selection();
    },

    key_up: function(selectedNode) {
      if (window.curr_message) {
        window.curr_message.hide();
      }

      var prev;
      if (selectedNode) prev = this.getPrev(selectedNode, true);
      if (prev) {
        this.set_selection(prev);
      }
      else {
        this.selectLastInTree();
      }

      return selectedNode != this.get_selection();
    },

    key_left: function(selectedNode) {
      if (!maxkir.NodeToggle) return;

      if (this._tree.isLeaf(selectedNode) || maxkir.NodeToggle.isCollapsed(selectedNode)) {
        var parent = this._tree.getParent(selectedNode);
        if (parent) {
          if (parent !== this._tree.getRoot()) {
            this.set_selection(parent);
          }
        }
      }
      else {
        maxkir.NodeToggle.collapseNode(selectedNode);
      }
    },

    key_right: function(selectedNode) {
      if (!maxkir.NodeToggle) return;

      if (this._tree.isLeaf(selectedNode) || !maxkir.NodeToggle.isCollapsed(selectedNode)) {
        this.key_down(selectedNode);
      }
      else {
        maxkir.NodeToggle.expandNode(selectedNode);
      }
    },

    page_up: function() {
      var node = this.get_selection();
      for (var i = 0; i < 25; i ++) {
        if (this.is_first_node(node)) break;
        node = this.getPrev(node, true);
      }
      this.set_selection(node);
    },

    page_down: function() {
      var node = this.get_selection();
      for (var i = 0; i < 25; i ++) {
        if (this.is_last_node(node)) break;
        node = this.getNext(node, true);
      }
      this.set_selection(node);
    },

    /**
     * If node_id is present in the tree model, return the object which describes position of the node in the tree.
     * Can be later used to get node_id by position, see nodeFromPosition method.
     * @param node_id
     */
    get_position: function(node_id) {
      if (!node_id) return null;

      var parent = this._tree.getParent(node_id);
      if (!parent) return null;

      var children = this._tree.getNodes(parent, true);
      var pos = children.indexOf(node_id);
      if (pos < 0) {
        pos = null;
      }
      return [parent, pos];
    },

    /**
     * @param position Object obtained by #get_position method, if passed null, or if node could not be found out, returns null
     */
    nodeFromPosition: function(position) {
      if (!position) {
        return null;
      }

      var parent = position[0];
      if (!this._visible(parent)) {
        return null;
      }

      var childPosition = position[1];
      if (childPosition !== null) {
        var children = this._tree.getNodes(parent, true);
        if (childPosition < children.length) {
          return children[childPosition]
        }
        else {
          return children.length ? this._find_last_child_deep(children[children.length - 1]) : parent;
        }
      }
      else {
        return parent;
      }
    },

    _find_last_child_deep: function(root) {
      var children = this._tree.getNodes(root, true);
      if (!children.length) {
        return root;
      }
      return this._find_last_child_deep(children[children.length - 1]);
    },

    has_history: function(direction) {
      if (direction > 0) {
        return this._position_idx < this._positions.length - 1;
      }
      else {
        return this._position_idx > 0;
      }
    },

    go_back: function() {
      return this._go_history("go back", -1);
    },

    go_forward: function() {
      return this._go_history("go forward", 1);
    },

    _go_history: function(text, directionDiff) {
      maxkir.debug(text + " from " + this.get_selection());
      this.save_selection_position();
      var initialSelection = this.get_all_selection();
      var initialPosition = this._position_idx;
      // debugger
      while (
          this.has_history(directionDiff) &&
          !this._set_selection_from_history(directionDiff, initialSelection))
      {}

      if (initialPosition === this._position_idx) {
        return false;
      }
      if (!this._visible(this.get_selection())) {
        // cannot find visible item in history
        // set selection to initial position
        maxkir.debug("Cannot " + text + " - found selection is not visible: " + this.get_selection());
        this._set_selection_keep_history(initialSelection);
        this._position_idx = initialPosition;
        return false;
      }
      return true;
    },

    _set_selection_keep_history: function(selection) {
      this._keep_history = true;
      this.set_selection(selection);
      this._expand_parents(this.get_selection());
      this._keep_history = false;
    },

    /**
     *
     * @param direction_diff +-1
     * @param initial_selection to avoid re-selecting it
     * @return true if should stop going through the history (when operation is successful)
     */
    _set_selection_from_history: function(direction_diff, initial_selection) {
      this._position_idx += direction_diff;

      var curr_data = this._positions[this._position_idx - direction_diff];
      var prev_data = this._positions[this._position_idx];

      var old_sel = prev_data[0];

      if (old_sel && !this._same(initial_selection, old_sel)) {
        // selection item changed, do the change
        this._set_selection_keep_history(old_sel);
        return this._visible(this.get_selection()) && !this._same(initial_selection, this.get_all_selection());
      }
      else {
        // Same or no item selected - may be need to fix selection after it changed its position
        this._tree_position = prev_data[1];
        this._keep_history = true;
        var position = this.nodeFromPosition(this._tree_position);
        if (!old_sel && position) {
          this.set_selection(position)
        }
        this.fix_selection(false, curr_data[0] ? curr_data[0][0] : null);
        this._keep_history = false;
      }
      return true;
    },

    _same: function(o1, o2) {
      return JSON.stringify(o1) === JSON.stringify(o2);
    },

    reset_history: function() {
      this._positions = [];
      this._position_idx = -1;

      this._tree_position = this.get_position(null);
      this._selected = [];
    },

    /**
     * @param {Array<String>|String|null} selected selection
     * @param {boolean} [add_class_name_if_visible_only]
     * @return {Promise} which resolves when selection is set 
     */
    set_selection: function(selected, add_class_name_if_visible_only) {

      var old_selected = this._sel();
      if (!old_selected.length && (!selected || 0 === selected.length)) {
          return Promise.resolve();
      }

      this.hide_selection();

      this._selected = Array.isArray(selected) ? selected : selected ? [selected] : [];

      if (this._selected.length) {

        // Remember anchors to fix selection if needed
        this._do_without_cycle(function () {
          this._tree_position = this.get_position(this._selected[0])
        });

        this._update_selection_history();
      }

      //this._debug_set_selection();

      // Process case when multiple selection is not supported:
      if (!this._support_multiple && this._selected.length > 1) {
        this._selected = [this._selected[this._selected.length - 1]];
      }

      
      this.remember_selection();

      var addCssClass = !add_class_name_if_visible_only || this._has_selection_class();
      if (addCssClass) {
        this.show_selection();
      }

      if (this._sel().length) {
        maxkir.debug("Select in tree " + this._tree.getRoot() + ': ' + this._sel().toString());
      }
      else {
        maxkir.debug("Clear tree selection for " + this._tree.getRoot());
      }

      return this.onSetSelection(this._sel(), old_selected, addCssClass);
    },

    _debug_set_selection: function() {
      var txt_debug = function(node_id) {
        var res = node_id;
        var node = maxkir.$(node_id);
        if (node && node.querySelector('.userContent')) {
          res = "'" + node.querySelector('.userContent').innerText;//.substr(0, 20) + "...'";
        }
        return 'text: ' + res;
      };

      maxkir.warn("set selection:" +
          ">> " + JSON.stringify(this._selected) + " " +
          txt_debug(this._selected[0]) + " " +
              JSON.stringify(this._tree_position));
    },

    _do_without_cycle: function(to_run) {
      var c = this._cycle;
      try {
        this._cycle = false;
        to_run.bind(this)();
      }
      finally {
        this._cycle = c;
      }
    },

    save_selection_position: function() {
      if (this._save_selection_timeout) {
        this._update_selection_history(true);
      }
    },

    _update_selection_history: function(force_save) {
      var get_selection_position = function () {
        return [this._selected, this._tree_position]
      }.bind(this);

      if (this._save_selection_timeout) {
        clearTimeout(this._save_selection_timeout);
        this._save_selection_timeout = null;
      }

      var state_to_save = get_selection_position();

      if (!this._keep_history) {

        var save_selection_position = function() {
          var state_after_delay = get_selection_position();
          if (this._same(state_to_save, state_after_delay)) {

            this._write_new_position(state_to_save);

          }
        }.bind(this);

        if (maxkir.is_testing() || force_save) {
          save_selection_position()
        }
        else {
          this._save_selection_timeout = setTimeout(save_selection_position, 500);
        }
      }
    },

    _write_new_position: function(state_to_save) {
      // _position_idx points to the idx where the currently selected item
      var prev_state = this._position_idx >= 0 ? this._positions[this._position_idx] : [];
      if (this._same(prev_state, state_to_save)) {
        return;
      }

      if (!state_to_save[0] && !state_to_save[1]) {
        // no current position
        return
      }

      this._position_idx ++;
      this._positions.splice(this._position_idx);
      this._positions.push(state_to_save);

      maxkir.debug("Saved selection state", JSON.stringify(this._positions[this._position_idx]));

      this._cleanup_duplicate_positions();

      for(var i = this._positions.length - 2; i >= 0; i --) {
        var prev = this._positions[i];
        if (prev.length > 0 && this._same(prev[0], state_to_save[0])) {
          // Forget exact selection on the previous state if it matches the new one, to make sure
          // position reflects only nearby items
          prev[0] = null;
          break;
        }
      }

    },

    _cleanup_duplicate_positions: function() {
      // remove old position duplicates from history, collect to map (which remembers the order)
      // and build _positions back from the map
      var map = new Map();
      for(var i = this._positions.length - 1; i >= 0; i --) {
        map.set(JSON.stringify(this._positions[i]), this._positions[i]);
      }
      this._positions = [];
      map.forEach(function (value, key, map) {
        this._positions.push(value)
      }, this);
      this._positions.reverse();
      this._position_idx = this._positions.length - 1;
    },

    /**
     * @return {Array<String>} combined selection, frozen + ordinary 
     * @private
     */
    _sel: function() {
      
      // _frozen_selected and _selected can contain the same items, 
      // but the last 'navigating' selection is taken from _selected
      // so we filter out items from _frozen which are present in _selected
      
      this._selected = this._selected || [];
      
      if (this._frozen_selected) {
        var res = [].concat(this._frozen_selected);
        // Remove items present in _selected
        res = res.filter(function(item) {
          return -1 == this._selected.indexOf(item);
        }, this);
        return res.concat(this._selected);
      } 
      
      return this._selected;
    },

    /**
     * Remember current selection that it is frozen, i.e. ordinary set_selection calls append to this frozen selection
     * So current selection will be frozen selection + ordinary selection, until selection is unfrozen
     * */
    freeze_selection: function() {
      if (!this._support_multiple) return;
      
      this._frozen_selected = this._sel();
      this._selected = [];
    },

    /**
     * Unfreeze currently frozen selection, so selection remains the same, 
     * but changing selection doesn't append to frozen one.
     */
    unfreeze_selection: function() {
      if (!this._support_multiple) return;
      this._selected = this._sel();
      this._frozen_selected = null;
    },
    
    stick_current_selection: function() {
      var sel = this.get_selection();
      if (this.is_frozen(sel)) {
        this.remove_selection([sel]);
        this.add_selection([sel]);
      }
      else {
        this.freeze_selection();
      }
    },

    /**
     * Add more selection (do not remove existing)
     * @param {Array<String>} selected selection
     */
    add_selection: function(selected) {
      var new_sel = [].concat(this._selected);
      selected.forEach(function(sel) {
        if (new_sel.indexOf(sel) == -1) {
          new_sel.push(sel);
        }
      });
      this.set_selection(new_sel);
    },

    /**
     * Remove items from selection
     * @param {String[]} selected items to remove
     */
    remove_selection: function(selected) {
      
      for(var i = 0; i < selected.length; i ++) {
        var sel = selected[i];
        this.hide_selection(sel);

        if (this.is_frozen(sel)) {
          var idx = this._frozen_selected.indexOf(sel);
          this._frozen_selected.splice(idx, 1);
        }
      }
      
      var new_sel = this._sel().filter(function(el) {
        return -1 === selected.indexOf(el);
      });
      this.set_selection(new_sel);
    },

    /**
     * @param {String} sel
     * @return {boolean} true if current selection includes sel
     */
    is_selected: function(sel) {
      return this._sel().indexOf(sel) >= 0;
    },

    /**
     * Processing of extension extending via Shift+Down
     */
    extend_selection_down: function(with_subnodes) {
      var sel = this.get_all_selection();
      if (sel.length === 0) return;

      var last_added = sel[sel.length - 1];

      // Add below:
      var to_add = this.getNext(sel[sel.length - 1], with_subnodes);
      this._process_add_or_remove(to_add, last_added);
    },
    
    /**
     * Processing of extension extending via Shift+Up
     */
    extend_selection_up: function(with_subnodes) {
      var sel = this.get_all_selection();
      if (sel.length == 0) return;

      var last_added = sel[sel.length - 1];

      // Add above:
      var to_add = this.getPrev(last_added, with_subnodes);
      this._process_add_or_remove(to_add, last_added);
    },

    _process_add_or_remove: function(to_add, last_added) {
      if (to_add) {
        if (this.is_selected(to_add) && !this.is_frozen(to_add)) {
          this.remove_selection([last_added]);
        }
        else {
          this.add_selection([to_add]);
        }
      }
    },

    /**
     * Processing shift+click to extend selection up to the node
     * @param node
     */
    extend_selection_to: function(node) {
      if (this._selected.length == 0) {
        this.set_selection(node);
        return;
      }
      
      if (this._tree.getTopParent(node) && $(node)) {
        if ($(this.get_selection()).compareDocumentPosition($(node)) & Node.DOCUMENT_POSITION_FOLLOWING) {
          while(!this.is_selected(node)) {
            this.extend_selection_down(true);
          }
        }
        else {
          while(!this.is_selected(node)) {
            this.extend_selection_up(true);
          }
        }
      }
    },

    /**
     * @return {boolean} true, if node is selected and frozen
     * @param node
     */
    is_frozen: function(node) {
      return this._frozen_selected && this._frozen_selected.indexOf(node) >= 0;
    },

    /**
     * @returns {boolean} Return true if selection was actually hidden
     */
    hide_selection: function(sel) {
      if (sel) {
        var oldEl = maxkir.$(this.node2select(sel));

        if (typeof oldEl === 'object' && maxkir.hasClassName(oldEl, this._selected_class)) {
          maxkir.removeClassName(oldEl, this._selected_class);
          maxkir.removeClassName(oldEl, this._selected_class_focus);
          this.onHideSelection(sel);
          return true;
        }
        return false; 
      }
      
      else {
        var hidden = false;
        var selected = this._sel();
        for(var i = 0; i < selected.length; i ++) {
          hidden = this.hide_selection(selected[i]) || hidden;
        }
        return hidden;
      }
    },

    show_selection: function() {
      var focused = this.get_selection();
      if (focused) {
        var el = maxkir.$(this.node2select(focused));
        maxkir.addClassName(el, this._selected_class_focus);
      }
      
      this._sel().forEach(function(sel) {
        var el = maxkir.$(this.node2select(sel));
        maxkir.addClassName(el, this._selected_class);
        this.onShowSelection(sel);
      }, this);
    },

    selection_visible: function(skip_dom_check) {
      return this._has_selection_class(!skip_dom_check)
    },

    _has_selection_class: function(require_visible) {
      return this._sel().some(function(el) {
        var oldEl = maxkir.$(this.node2select(el));
        var visibleStateOK = !require_visible || maxkir.visible(el); 
        return oldEl && maxkir.hasClassName(oldEl, this._selected_class) && visibleStateOK;
      }, this);
    },


    /**
     * @param {Array<String>} newSelection
     * @param {Array<String>} oldSelection
     * @param {boolean} cssClassAdded
     * @return {Promise} which resolves when selection setting is finished
     */
    onSetSelection: function(newSelection, oldSelection, cssClassAdded) {
      return Promise.resolve();
    },
    /**
     * @param {String} currSelection
     */
    onHideSelection: function(currSelection) {
    },
    /**
     * @param {String} currSelection
     */
    onShowSelection: function(currSelection) {
    },

    node2select: function(node) {
      return node;
    },

    /**
     * @return {String|null} return the last added selection in the current selection
     */
    get_selection: function() {
      var sel = this.get_all_selection(); 
      return sel.length > 0 ? sel[sel.length - 1] : null;
    },

    /**
     * @return {String|null} return the selection, only if it is single-line selection
     */
    get_single_selection: function() {
      var sel = this.get_all_selection(); 
      return sel.length === 1 ? sel[0] : null;
    },

    get_all_selection: function() {
      return this._sel();
    },

    getNext: function(node, recursive, includeHidden) {
      var parent = this._tree.getParent(node);
      if (parent != null) {
        var children = this._tree.getNodes(parent, includeHidden);
        var i = this._findIdx(children, node);
        if (i == children.length) return null; // node is not found under parent
        if (recursive) {
          var children2 = this._tree.getNodes(node, includeHidden);
          if (children2.length > 0) {
            return children2[0];
          }
        }

        if (i < children.length - 1) {
          return children[i + 1];
        }
        else {
          var res = this._getNextDeep(parent, children, recursive, includeHidden);
          return res ? res : node;
        }
      }
      else {
        return this._tree.getFirstNode();
      }
    },

    getSiblings: function(node) {
      var parent = this._tree.getParent(node);
      return parent ? this._tree.getNodes(parent) : [];
    },

    getNextSibling: function(node) {
      var children = this.getSiblings(node);
      var i = this._findIdx(children, node);
      if (i == children.length) return null; // node is not found under parent

      return (i < children.length - 1) ? children[i + 1] : null;
    },

    getPrevSibling: function(node) {
      var children = this.getSiblings(node);
      var i = this._findIdx(children, node);
      if (i == children.length) return null; // node is not found under parent

      return (i > 0) ? children[i - 1] : null;
    },

    get_all_selection_ordered: function() {
      if (!this.selection_visible(true)) {
        return [];
      }

      // Sort by natural items order:
      return this.get_all_selection().filter(function(sel) { return document.getElementById(sel)}).sort(function(sel1, sel2) {
        if (sel1 === sel2) return 0;
        return (maxkir.$(sel1).compareDocumentPosition(maxkir.$(sel2)) & Node.DOCUMENT_POSITION_FOLLOWING) ? -1 : 1
      });
    },

    // Ignores nodes which have 'deleted' attribute
    is_last_child: function(node) {
      var parent = this._tree.getParent(node);
      if (!parent) return true;

      var chld = this._tree.getNodes(parent);
      if (chld.length < 2) return true;

      return node === chld[chld.length - 1] || maxkir.$(chld[chld.length - 1]).deleted;
    },

    _getNextDeep: function(parent, children, recursive, includeHidden) {
      var parent_of_parent = this._tree.getParent(parent);
      // if we are root node
      if (parent_of_parent == null) {
        return this._cycle ? children[0] : null;
      }

      if (!recursive) {
        return children[children.length - 1];
      }

      var parent_of_parent_children = this._tree.getNodes(parent_of_parent, includeHidden);
      var parentIdx = this._findIdx(parent_of_parent_children, parent);
      if (parent_of_parent_children.length - 1 != parentIdx) {
        return parent_of_parent_children[parentIdx + 1];
      }
      else {
        return this._getNextDeep(parent_of_parent, parent_of_parent_children, recursive, includeHidden);
      }
    },

    getPrev: function(node, recursive, includeHidden) {
      var parent = this._tree.getParent(node);
      if (parent != null) {
        var children = this._tree.getNodes(parent, includeHidden);
        var i = this._findIdx(children, node);
        if (i == children.length) return null; // node is not found under parent

        if (i > 0) {
          return this._findPrevTree(children[i - 1], recursive, includeHidden);
        }
        else {
          // if we are root node
          if (this._tree.getParent(parent) == null) {
            return this._cycle ? this._findPrevTree(children[children.length - 1], recursive, includeHidden) : node;
          }
          else {
            return recursive? parent : node;
          }
        }
      }
      else {
        var nodes = this._tree.getNodes(this._tree.getRoot(), includeHidden);
        return this._findPrevTree(nodes[nodes.length - 1], recursive, includeHidden);
      }
    },

    _findPrevTree: function(node, recursive, includeHidden) {

      // No recursion - return requested node
      if (!recursive) return node;

      // Find children of previous node and go to the last child of the node
      var cld2 = this._tree.getNodes(node, includeHidden);
      while (cld2.length > 0) {
        node = cld2[cld2.length - 1];
        cld2 = this._tree.getNodes(node, includeHidden);
      }
      return node;
    },

    _findIdx: function(children, node) {
      var i = 0;
      for (; i < children.length; i ++) {
        if (node == children[i]) {
          break;
        }
      }
      return i;
    },

    restore_selection: function() {
      if (!this._sel_name || !window.sessionStorage) return;
      try {
        this.set_selection(JSON.parse(
            sessionStorage.getItem(this._sel_name) || localStorage.getItem(this._sel_name)
        ));
      }
      catch(e) {}
    },

    remember_selection: function() {
      if (!this._sel_name || !window.sessionStorage) return;
      sessionStorage.setItem(this._sel_name, JSON.stringify(this._sel()));
      localStorage.setItem(this._sel_name, JSON.stringify(this._sel()));
    }

  });


})(window.maxkir);

