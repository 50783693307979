define(function (require, exports, module) {

    var $ = require('jquery');
    var app = require('app');
    var maxkir = require('app/maxkir');
    var sync = require('app/synchronizers');

    /**
     * @class CommandQueue
     */
    var CommandQueue = [];

    /**
     * @typedef {Object} Command
     * @property {Function} run command which executes the command and returns deferred for command execution
     * @property {Function} serialize_json method which returns command serialized to json to store in DB (id should be included)
     * @property {Function} [preload] optional method which returns Promise to be resolved when command is ready to be executed, i.e. preloads its data
     * @property {Function} [sort_array_key] optional method which returns Array to be used as a sort key for commands reordering before execution
     * @property {String} type string identifier used to find a factory for creating such commands
     * @property {String} sync_key string identifier used to get synchronization lock to run such commands
     */

    $.extend(CommandQueue, {
        listeners: [],

        // Factory-related code, to be extracted to a separate entity?
        // @param command_type - string
        // @param factory_method - method which accepts serialized JSON of the command, provided with commands's to_json method
        add_command_factory: function (command_type, factory_method) {
            this._factories = this._factories || {};
            this._factories[command_type] = factory_method;
        },

        /**
         * @param {Object} listener
         * @param {function} listener.show_progress - command processing started
         * @param {function} listener.hide_progress - command processing finished, everything processed
         * @param {function} listener.stop_progress - command processing stopped, but failed
         */
        add_listener: function (listener) {
            if (this.listeners.indexOf(listener) == -1) {
                this.listeners.push(listener);
            }
        },

        remove_listener: function (listener) {
            var index = this.listeners.indexOf(listener);
            if (index >= 0) {
                this.listeners.splice(index, 1);
            }
            else {
                app.error("Removing non-present listener", listener);
            }
        },

        /**
         * @param {Command} command
         * @return Deferred for storing the command in DB and executing it
         */
        push_command: function (command) {
            var that = this;
            this._just_cleared = false;

            if (this.indexOf(command) >= 0) {
                app.debug("Existing command is added - save it (to update) and run command processing");
                this.save_commands_to_db();
                return this.process_commands();
            }


            command.created_at = new Date();


            var internal_push = function () {
                // This push should not be executed while we're loading commands from DB.
                that.push(command);
                command = that[that.length - 1];

                if (command.serialize_json) {
                    that.save_commands_to_db();
                }
                
                return Promise.resolve();
            };

            return this.run_when_inactive(function() {
                if (that._just_cleared) {
                    app.debug("Just cleared all commands, skip saving command for processing");
                    return Promise.resolve();
                }
                return internal_push();
            }, "CommandQueue:internal_push for " + command).then(function() {
                return that.process_commands();
            });
        },

        /**
         *  Remove all elements from the queue from memory
         *  @return Deferred which resolves to true
         */
        clear: function () {
            app.debug("Clear all commands");
            this.splice(0);
            this._just_cleared = true;
            return Promise.resolve();
        },

        /**
         *  Remove all elements from the queue from memory and database
         */
        clear_with_db: function () {
            var that = this;
            return this.clear().then(function() {
                that.save_commands_to_db();
            });
        },

        /**
         * @return Deferred which resolves to number of command records in database
         */
        db_count: function () {
            var calc_result = function() {
                return (app.storage.get_item('all_commands') || []).length; 
            };

            return Promise.resolve(calc_result());
        },

        /**
         *  @return true if queue is empty and nothing is being processed
         */
        is_stale: function () {
            return !sync.is_active('commands') && !this.length;
        },

        /**
         * @returns {Promise}
         */
        process_commands: function () {
            var that = this;

            var result = $.Deferred();

            var call_listeners = function (method) {
                app.debug("Command listeners call: " + method);
                that.listeners.forEach(function (l) {
                    if (typeof l[method] == 'function') {
                        l[method]();
                    }
                });
            };

            var do_process_commands = function () {

                var to_process_count = that.length;

                var on_successful_processing = function () {
                    if (to_process_count > 0) {
                        call_listeners("hide_progress");
                        app.info("Done processing command queue");
                    }
                    result.resolve();
                };

                var on_failed_processing = function (msg, error) {
                    // Still show progress icon to allow to restart synchronization process
                    call_listeners("stop_progress");
                    app.info("Failed processing command queue: " + msg + "; " + that.length + ' commands remain');
                    result.reject(error);
                };

                var repeat_with_optional_relogin = function relogin_and_repeat(array_of_errors) {

                    if (!app.current_user) {
                        on_failed_processing("Failed to process command queue and no current user", array_of_errors[0]);
                        return;
                    }

                    if (that._just_cleared) {
                        on_failed_processing("Failed to process command queue and queue was just cleared", array_of_errors[0]);
                        return;
                    }

                    if (array_of_errors.length) {

                        var has_auth_error = false;
                        array_of_errors.forEach(function (err, idx) {
                            has_auth_error = has_auth_error || err.auth_problem;
                            app.debug("Command error " + (idx + 1) + ": ", err);
                        });

                        if (has_auth_error) {
                            app.debug("Failed to process command queue, will try to refresh login");

                            app.current_user.refresh_login().then(function refresh_login_ok() {
                                app.debug("Token updated, try processing commands again");

                                that._process_commands_on_queue_snapshot().then(on_successful_processing, function () {
                                    on_failed_processing("Could not process commands even after token update");
                                });

                            }, function refresh_login_fail(err) {
                                // Cannot re-authenticate. Pass failure to calling method
                                on_failed_processing("Cannot re-authenticate to process commands", err);
                            });
                        }
                        else {
                            if (to_process_count > that.length) {
                                app.debug("Failed to process command queue, but some commands run, will retry");
                                that._process_commands_on_queue_snapshot().then(on_successful_processing, function () {
                                    on_failed_processing("Could not process commands after retry");
                                });
                            }
                            else {
                                on_failed_processing("Error running failed commands even after retry", array_of_errors[0]);
                            }
                        }

                    }
                    else {
                        on_failed_processing("No specific errors found ???", new Error("No specific errors found ???"));
                    }
                };

                if (to_process_count === 0) {
                    result.resolve();
                }
                else {
                    app.info("Start processing command queue: " + that.length + " command(s)");
                    call_listeners("show_progress");
                    that._process_commands_on_queue_snapshot().then(on_successful_processing, repeat_with_optional_relogin);
                }

                return result;
            };

            // Only one call at a time is allowed:
            that.run_when_inactive(do_process_commands, "CommandQueue: process_commands: " + that.length);
            return Promise.resolve(result);
        },

        _process_commands_on_queue_snapshot: function () {

            var that = this;
            var def = $.Deferred();
            if (this.length > 0) {

                // First, create a list of command copies (not serialize - we need exact elements in List!)
                var copy = [];
                for (var i = 0; i < this.length; i++) {
                    copy[i] = this[i];
                }
                
                var prepare_promises = [];
                copy.forEach(function(c) {
                    if (typeof c.preload === 'function') {
                        prepare_promises.push(c.preload());
                    }
                });

                Promise.all(prepare_promises).then(function() {
                    this._reorder_commands(copy);

                    //console.info("Executing commands: " + copy.length);

                    var saved_errors;
                    var run_next = function (j) {
                        if (j === copy.length) {
                            // Last command in the array
                            //console.info("Done Executing commands");

                            if (saved_errors) {
                                def.reject(saved_errors);
                            }
                            else {
                                def.resolve();
                            }
                        }
                        else {
                            var create_run_command_promise = function create_run_command_promise(j) {
                                return that._single_command_deferred(copy[j], j, copy.length).then(function () {
                                    // Go to next
                                    run_next(j + 1);
                                }, function (err) {
                                    if (!saved_errors) {
                                        saved_errors = [];
                                    }
                                    saved_errors.push(err);
                                    run_next(j + 1);
                                })
                            };

                            if (copy[j].sync_key) {
                                sync.run_sync(copy[j].sync_key, create_run_command_promise.bind(this, j), "command " + j);
                            }
                            else {
                                create_run_command_promise(j);
                            }
                        }
                    };

                    run_next(0);
                    
                }.bind(this))

            }
            else {
                def.resolve();
            }

            return def;
        },

        _single_command_deferred: function (command, index, total_commands) {

            var that = this;

            if (typeof command.run !== "function") {
                return Promise.reject(new Error("Command doesn't have run() method"));
            }
            
            app.info("Running command " + (index + 1) + " of " + total_commands + ": " + command.toString());

            return Promise.resolve(command.run()).then(function () {

                app.info("Command executed: SUCCESS", command.toString());

                that.remove_command(command);
                that.save_commands_to_db();

                return true;
            }, function (err) {
                app.info("FAILED to execute command " + command.toString(), err);
                throw err ? err : new Error("command failed");
            });
        },

        /**
         * @param command to remove from the queue
         */
        remove_command: function (command) {
            var idx = this.indexOf(command);
            if (idx >= 0) {
                this.splice(idx, 1);
            }
        },

        _reorder_commands: function (commands) {
            commands.forEach(function(c, idx) {
                c._sort_id = idx;
            });
            
            commands.sort(function(a, b) {
                var a_key = a.sort_array_key && a.sort_array_key(), 
                    b_key = b.sort_array_key && b.sort_array_key();
                
                if (a_key && b_key && a_key.length === b_key.length) {
                    var l = a_key.length;
                    // Simple array comparison
                    for(var i = 0; i < l; i ++) {
                        if (a_key[i] !== b_key[i] && typeof a_key[i] === typeof b_key[i]) {
                            // Values differ and have same type (number or string): 
                            return a_key[i] < b_key[i] ? -1 : 1;
                        }
                    }
                }
                // Otherwise, keep sort stable
                return a._sort_id < b._sort_id ? -1 : 1;
            })
        },


        /**
         * @return {Promise}
         */
        save_commands_to_db: function () {
            var serialized_commands = [];
            for(var i = 0; i < this.length; i ++) {
                var command = this[i];
                if (command.serialize_json) {
                    serialized_commands.push(this._serialize_command(command));
                }
            }

            app.storage.set_item('all_commands', serialized_commands);
            app.debug("Saved commands to DB: " + serialized_commands.length);
        },
        
        _serialize_command: function (command) {
            var serialized = command.serialize_json();
            serialized.type = command.type;
            if (command.sync_key) {
                serialized.sync_key = command.sync_key;
            }

            if (!serialized.id) {
                serialized.id = command.id;
                if (!serialized.id) {
                    serialized.id = "" + new Date().getTime() + "-" + maxkir.uuid();
                }
            }
            command.id = serialized.id;
            return serialized;
        },

        load_and_run: function () {

            if (this.length) {
                return this.process_commands();
            } 
            else {
                var that = this;
                return this.reload_from_db().then(function () {
                    if (that.length > 0) {
                        return that.process_commands();
                    }
                    else {
                        return [];
                    }
                });
            }

        },


        /**
         * @param {String} name
         * @param {PromiseCreator} deferred_creator
         * @return Promise
         */
        run_when_inactive: function (deferred_creator, name) {
            // console.warn('run_when_inactive: ' + name)
            // console.trace()
            return sync.run_sync('commands', deferred_creator, name);
        },

        reload_from_db: function () {
            var that = this;
            var resolve, reject;
            var res = new Promise(function (res, rej) {
                resolve = res;
                reject = rej;
            });

            that.run_when_inactive(function () {
                // This setTimeout is used to make sure that the previous transaction was committed
                setTimeout(function () {
                    that._do_reload_from_db(resolve, reject);
                }, 1);

                return res;
            }, "CommandQueue::reload_from_db");

            return res;
        },

        _do_reload_from_db: function (resolve, reject) {
            var that = this;

            var commands_from_stored_data = function commands_from_stored_data(stored_data) {
                var commands = [];
                for (var i = 0; i < stored_data.length; i++) {
                    var command_factory = that._factories[stored_data[i].type];
                    if (command_factory) {
                        var command = command_factory(stored_data[i]);
                        command.id = stored_data[i].id;
                        command.type = stored_data[i].type;
                        command.sync_key = stored_data[i].sync_key;
                        commands.push(command);
                    }
                }
                return commands;
            };

            app.debug("Loading commands from localStorage");
            var from_local_storage = commands_from_stored_data(app.storage.get_item('all_commands') || []);
            that.splice.apply(that, [0, 1000000].concat(from_local_storage));

            var data = that;
            app.debug("Loaded " + that.length + " commands", data);

            resolve(data);
        },

        toString: function () {
            var res = "";
            for (var i = 0; i < this.length; i++) {
                res += "Command " + i + ": " + this[i].type + " " + JSON.stringify(this[i].serialize_json()) + "\n";
            }
            return res;
        },


        /**
         * Same as $.ajax, but for background execution, should not be interrupted when navigating through pages
         */
        ajax_no_abort: function (options) {
            if (app.is_testing()) {
                app.info("Command parameters ", JSON.stringify(options));
            }
            
            var old = options.beforeSend || function () {
                };
            options.beforeSend = function (jXhr) {
                jXhr._no_abort_on_navigation = true;
                return old.apply(this, arguments);
            };
            return Promise.resolve($.ajax.call($.ajax, options)).catch(function(jXHR) {
                throw app.create_ajax_error(jXHR, "AJAX problem " + options.url, 'ERR_COMMAND_ERROR')
            });
        }
    });

    window.CQ = CommandQueue;

    module.exports = CommandQueue;
    console.info("CommandQueue initialized");
});