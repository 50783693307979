import app from "app";

import I18N from "app/i18n";

import ListFilterControl from "app/controls/list_filter";

import SlidingPanel from "app/controls/sliding_panel";

import Page from "app/pages/page";
import List from "app/model/list";
import User from "app/model/user";

import ListsPreloader from "app/model/lists_preloader";
import DueTaskLoader from "app/model/due_task_loader";

import install_home_screen from "install_home_screen";

/**
     * @class ListsPage
     */
    const ListsPage = Page.extend('ListsPage', {
            defaults: {
                template: "lists_page.mustache",
                page_title: "Checkvist: " + I18N.t("yourLists").toLocaleLowerCase()
            }
        },
        {

            onRender: function () {
                app.msg.loading_with_timeout("#home_list", I18N.t("loading.your.lists"));

                this._init_listeners();

                const that = this;
                List.all_lists({skip_server: true}).then(function (lists) {
                    if (that.destroyed) return;
                    that._list_filter = new ListFilterControl("section.lists", {lists: lists});
                    that.restore_scroll_position();


                    if (lists._from_cache) {
                        // Now, reload data from server and start preloading lists
                        List.all_lists({force_server: true}).then(function (lists2) {
                            that._preload_lists(lists2);
                        });
                    }
                    else {
                        that._preload_lists(lists);
                    }

                    that._preload_due();
                    that._preload_users();

                }, function () {
                    app.msg.show_error(I18N.t("err.cannot_get_lists_data"));
                });

                this._check_for_https();

            },

            _preload_users: function () {
                // Preload people data, if we don't have any
                User.count_db().then(function (res) {
                    if (res <= 1) {
                        app.debug("Start preloading users");
                        User.findAll().then(function (users) {
                            app.debug("End   preloading users: " + users.length);
                        });
                    }
                });
            },

            _preload_due: function () {
                const show = function () {
                    const value = DueTaskLoader.get_tasks('due').length;
                    if (value > 0) {
                        $('.dueCounter').text(value);
                        $('.dueCounter').show();
                    }
                    else {
                        $('.dueCounter').hide();
                    }
                };

                if (!this._due_callback) {
                    this._due_calback = {
                        on_new_tasks: show
                    }
                }

                DueTaskLoader.add_callback(this._due_calback);

                if (DueTaskLoader.has_data()) {
                    show();
                }

                app.debug("Start preloading due data");
                DueTaskLoader.start_loading();

            },

            _preload_lists: function (lists) {
                if (this.destroyed) return;

                let loadingMsg;
                if (!app._hide_syncing) {
                    loadingMsg = new SlidingPanel('.loadingLists')
                }
                const listArray = [];
                lists.forEach(function (l) {
                    listArray.push(l)
                });
                List.sort_by_user_updated_at(listArray);

                ListsPreloader.preload_lists(listArray, 100, function (fraction) {
                    if (loadingMsg) loadingMsg.show();

                    $(".progressDiv").show();
                    $(".progressDiv").css("width", Math.round(fraction * 100) + "%");

                }, function () {
                    if (loadingMsg) loadingMsg.hide();
                    app._hide_syncing = true;

                    $(".progressDiv").hide();
                });
            },

            _init_listeners: function() {

                this.add_page_click_handler("li.list", "li_list", function(event, element) {
                    const id = element.attr('id').substr('list_'.length);
                    app.debug('Click on list ' + id);
                    cv_route.goTo("/app/list/" + id);
                });

                this.add_page_click_handler(".loadingLists .cancel", "cancel_preload", function(event, element) {
                    ListsPreloader.cancel();
                    event.preventDefault();
                });

                this._init_quick_add();

            },

            /**
             * @return ListFilterControl
             */
            list_filter: function () {
                return this._list_filter;
            },

            destroy: function () {
                ListsPreloader.interrupt();

                DueTaskLoader.destroy();

                if (this._list_filter) {
                    this._list_filter.destroy();
                    this._list_filter = null;
                }

                Page.prototype.destroy.call(this);
            }
        });

export default ListsPage;
