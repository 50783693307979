define(function (require, exports, module) {

    var app = require('app');
    var _sync_runners = {};

    /**
     * @type {{run_sync: run_sync, reset: reset}}
     */
    var Synchronizers = {

        /**
         * @typedef {Function} PromiseCreator a function which returns a Promise
         */

        /**
         * @param {String} sync_key syncronization key for a Promise creator. Promises with the same key will be run sequentially
         * @param {String} debug_name
         * @param {PromiseCreator} deferred_creator
         * @return {Promise} promise which resolves when all appended deferreds for this sync_key are resolved
         */
        run_sync: function (sync_key, deferred_creator, debug_name) {
            return this._get_runner(sync_key).run_sequential(deferred_creator, debug_name);
        },

        is_active: function(sync_key) {
            return this._get_runner(sync_key).is_active(); 
        },

        reset: function() {
            for(var key in _sync_runners) {
                _sync_runners[key].reset();
            }
            _sync_runners = {};
            return Promise.resolve();
        },
        
        _get_runner: function(sync_key) {
            var runner = _sync_runners[sync_key];
            if (!runner) {
                runner = this.create_sync_runner(sync_key);
                _sync_runners[sync_key] = runner;
            }
            return runner;
        },

        /**
         * @param {String} name
         * @return {{run_sequential: run_sequential}}
         */
        create_sync_runner: function(name) {
            var def_creators_queue = [];

            var my_deferred;
            var chainId = 0;

            var log = function(msg) {
                app.debug("[sync runner " + name + "-" + chainId + "] " + msg);
            };

            var cleanup = function() {
                var p = my_deferred;
                my_deferred = null;
                if (p) {
                    p.resolve();
                }
            };

            var process_next = function() {
                var monitor = null;
                var data = def_creators_queue[0];
                var myChainId = chainId;  

                var on_def_finish = function() {
                    if (!def_creators_queue.length || myChainId !== chainId) {
                        return;
                    }

                    var data = def_creators_queue.shift();
                    log("Done  deferred " + data[0] +
                        (def_creators_queue.length ? "; " + def_creators_queue.length + " remains, next is '" + def_creators_queue[0][0] + "'": "; Done"));
                    // log("on_def_finish");
                    // console.trace();

                    clearTimeout(monitor);
                    monitor = null;

                    if (!def_creators_queue.length) {
                        cleanup();
                    }
                    else {
                        // Make UI more responsive
                        setTimeout(function() {
                            if (def_creators_queue.length) {
                                process_next();
                            }
                        }, 10);
                    }
                };

                monitor = setTimeout(function() {
                    if (def_creators_queue.length) {
                        // If the deferred was not processed still in queue
                        app.error("create_sync_runner: possible DEADLOCK: " + name + " in " + data[0] + " length:" + def_creators_queue.length);
                    }
                    // on_def_finish();
                }, 30000);

                log("Start deferred " + data[0]);
                data[1]().then(on_def_finish, on_def_finish);
            };

            return {
                /**
                 * @param {PromiseCreator} deferred_creator
                 * @param {String} name - debug name
                 * @return {Promise} promise which resolves when all appended deferreds are resolved
                 * */
                run_sequential: function(deferred_creator, name) {

                    def_creators_queue.push([name, deferred_creator]);

                    if (def_creators_queue.length === 1) {
                        chainId ++;
                        log("First deferred has been added: '" + name + "'");
                        my_deferred = $.Deferred();
                        process_next();
                    }
                    else {
                        log("Deferred " + name + " just added, new queue size: " + def_creators_queue.length);
                    }
                    // console.trace();

                    return Promise.resolve(my_deferred);
                },

                /**
                 * Remove all queued operations
                 */
                reset: function() {
                    log("Reset deferreds");
                    def_creators_queue.splice(0);
                    cleanup();
                },

                is_active: function() {
                    return my_deferred;
                }
            }
        }

    };
    
    module.exports = Synchronizers;

});