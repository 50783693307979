define(function (require, exports, module) {
    'use strict';

    var app = require('app');
    var CachingModel = require('app/model/caching_model');
    var Task = require('app/model/task');
    var ListState = require('app/model/list_state');
    var TempObjects = require('app/model/temp_objects');

    /**
     * @class List
     *
     * Properties: id, name, updated_at, user_updated_at
     */
    var List = app.List = CachingModel.extend("mxList", {

        /**
         * 
         * @param options
         * @return Promise which resolves to all user lists ordered by access time
         */
        all_lists: function(options) {
            return List.findAll($.extend({sort_func: List.sort_by_user_updated_at}, options || {})); 
        },

        get_table_name: function() {
            return "lists";
        },

        /**
         * Return a deferred which resolves to a JSON array of records for this model, or to the single record if ID is set
         * @param params findAll parameters
         * @returns {Deferred}
         */
        get_load_data_deferred: function(params) {
            if (params.id) {
                if (TempObjects.is_local(params.id)) {
                    return Promise.reject("Local list");
                }
                
                app.debug("Start loading list " + params.id + " from server");
                return $.ajax({
                    dataType: "json",
                    type: "get",
                    url: app.config.api_url + "/checklists/" + params.id + ".json",
                    data: {"skip_stats":true, token: app.current_user.attr("token")}
                });
            }
            else {
                app.debug("Start loading lists from server");
                return $.ajax({
                    dataType: "json",
                    type: "get",
                    url: app.config.api_url + "/checklists.json",
                    data: {"skip_stats":true, token: app.current_user.attr("token")}
                });
            }
        },


        sort_by_user_updated_at: function(lists) {
//            app.debug("Sorting", lists);
            lists.sort(function(a, b) {
                var o1 = a.user_updated_at;
                var o2 = b.user_updated_at;

                if (o1 && o2) {
                    return Date.parse(o2) - Date.parse(o1)
                }
                if (o2) return 1;
                if (o1) return -1;

                o1 = a.updated_at;
                o2 = b.updated_at;
                return Date.parse(o2) - Date.parse(o1);
            })
        },

        /**
         * @return {boolean} true if there are loaded data in the list in the cache
         * */
        has_data: function(list) {
            var has_data_in_db = Task.has_data_in_db({checklist_id: parseInt(list.id)});
            var res = has_data_in_db && (parseInt(has_data_in_db) >= new Date(list.updated_at).getTime());
            // console.info("has_data", has_data_in_db, list.updated_at, new Date(list.updated_at).getTime(), res);
            return res;
        }


    }, {

        serialize: function() {
            var res = CachingModel.prototype.serialize.apply(this, arguments);
            delete res.has_data;
            return res;
        },

        tasks: function(params) {
            params = params || {};
            params.checklist_id = CachingModel.fix_id(this.id);
            params.index_name = 'checklist_id';
            params.use_memory_cache = true;
            params.skip_server = params.skip_server || (this.has_data() && !params.force_server); // to avoid memory leak with bind(updated_at)
            if (TempObjects.is_local(this.id)) {
                params.skip_server = true;
            }
            
            var that = this,
                _touch_data = function() {
                    that.attr('has_data', !that.attr("has_data"));
                };

            if (params.skip_server) {
                that.log("Loading list items for list " + this.id_num() + ", skip server");
            }
            else {
                that.log("Loading list items for list " + this.id_num() +
                    (params.force_server ? ", force server" : ", with server update"));
            }

            return Promise.resolve(Task.findAll(params))
                .then(function (res) {
                    _touch_data();
                    return res;
                }, function (err) {
                    if (!err.is_abort) {
                        app.error(arguments);
                    }
                    throw err;
                });
        },

        init_tasks_refresh_on_updated_at_change: function() {
            // add listener for the update from the server,
            // just in case the update will come a bit later
            var that = this;
            this._bound_updated_at_handler = function updated_at_listener(list, oldValue, newValue) {
//                that.unbind("updated_at");
                if (Date.parse(oldValue) != Date.parse(newValue)) {
                    that.log("Updated_at changed for list, reload tasks from server", arguments);
                    that.tasks({force_server: true});
                }
            }; 
            this.bind("updated_at", this._bound_updated_at_handler);
        },

        dispose_tasks_refresh_on_updated_at_change: function() {
            this.unbind("updated_at", this._bound_updated_at_handler);
        },

        list_state: function() {
            var id = CachingModel.fix_id(this.id);
            return new Promise(function(resolve, reject) {
                ListState.findLocal(id).then(function(res) {
                    resolve(res);
                }, function(error) {
                    var res = new ListState({id: id});
                    res.save();
                    resolve(res);
                });
            });
        },

        /**
         * @param {Task} [task]
         * @return {boolean}
         */
        read_only: function(task, allow_assignee) {
            var ro = this.attr("read_only");
            if (ro && ro !== 'false') {
                if (!allow_assignee) return true;
                if (!task.assignee_ids) return true;

                return -1 === task.assignee_ids.indexOf(app.current_user.id);
            }
            return false;
        },

        /**
         * @return {boolean} true if there are loaded data in the list in the cache
         * */
        has_data: function() {
            return List.has_data(this.attr());
        },

        /**
         * Update internal cache that we do have current data state
         */
        mark_tasks_up_to_date: function() {
            app.debug("Marking list " + this.id + " up-to-date");
            Task._touch_db_cache_mark({checklist_id: CachingModel.fix_id(this.id)}, new Date().getTime(), "mark_tasks_up_to_date");
        }
        

    });
    
    Task.add_db_listener({
        onSave: function(tasks) {
            var list_ids = {};
            tasks.forEach(function(t) {
                if (TempObjects.is_local(t.id)) {
                    list_ids[t.checklist_id] = true
                }
            });
            
            for(var list_id in list_ids) {
                List.findLocal(list_id).then(function touch_list(list) {
                    app.debug("Change user_updated_at to current for " + list);
                    list.attr('user_updated_at', new Date().toISOString());
                })
            }
        }
    });

    module.exports = List;
});
