import Page from "app/pages/page";

const IntroPage = Page.extend('IntroPage', {
        defaults: {
            template: "intro_page.mustache"
        }
    },
    {
        onRender: function () {
            $('.m-carousel').carousel();
        },

        destroy: function () {
            $('.m-carousel').carousel("destroy");
            Page.prototype.destroy.apply(this, arguments);
        }
    });

export default IntroPage;