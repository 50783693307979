import app from "app";
import CommandQueue from "app/command_queue";
import BaseCommand from "app/commands/base_command";
import TempObjects from "app/model/temp_objects";
import List from "app/model/list";

/**
     * @class CreateListCommand
     */
    var CreateListCommand = BaseCommand.extend({

        type: 'create_list'  // to be defined

    }, {

        /**
         */
        init: function(list_name) {
            BaseCommand.prototype.init.call(this);
            this.list_id = maxkir.uuid();
            this.name = list_name;
            this.store_attribute("list_id", "name");
        },

        run: function() {
            var that = this;
            
            return Promise.resolve(List.findLocal(this.list_id))
                .catch(function () {
                    return new List({id: that.list_id, name: that.name, 
                        is_local: true, 
                        updated_at: new Date().toISOString(),
                        user_updated_at: new Date().toISOString()
                    }).save()
                })
                .then(function (list) {
                    that.list = list;
                    var res = $.Deferred();
                    that.run_command_on_server(res);
                    
                    // Init reload of the page after finish of command processing for the new list
                    res.then(function() {
                        CommandQueue.run_when_inactive(function() {
                            return TempObjects.find_replacement_id(that.list_id).then(function(new_id) {
                                CommandQueue.run_when_inactive(function() {
                                    var currentPage = app.current_page();
                                    if (currentPage && currentPage.task_tree && currentPage.task_tree() && 
                                        currentPage.task_tree().list_id === that.list_id) {
                                        cv_route.goTo('/app/list/' + new_id);
                                    }
                                    return Promise.resolve();
                                }, "Change list ID on current page to real one");
                            });
                        });
                    });
                    return res;
                }); 
        },

        update_command_ajax_params: function(ajax_params) {
            $.extend(ajax_params, {
                url: app.config.api_url + '/checklists.json',
                data: {
                    token: app.current_user.token,
                    "checklist[name]": this.name
                },
                dataType: "json",
                type: 'POST'
            })
        },

        /**
         * @param response_json
         * @return {Array<Promise>}
         */
        get_promises_on_server_response: function(response_json) {
            var that = this;
            return [
                new List(response_json).save().then(function(list) {
                    TempObjects.add_replacement(that.list_id, list.id);

                    CommandQueue.run_when_inactive(function () {
                        List.destroy(that.list_id);
                        return Promise.resolve();
                    }, "Removing temp list " + that.list_id);
                })
            ];
        },



        toString: function() {
            return "[" + this.type + "/list#" + this.list_id + "]#" + this.id;
        }
    });
    
    CreateListCommand.init_command_factory();

    export default CreateListCommand;
