import app from "app";
import BaseCommand from "app/commands/base_command";
import Task from "app/model/task";
import TaskTextParser from "app/model/task_text_parser";
import TempTasks from "app/model/temp_objects";
import ImageReducer from "app/controls/image_reducer";
import {
    file_storage_get as storage_table_get,
    file_storage_promise as storage_table_promise
} from "app/model/files_storage";

/**
     * Run callback with task_tree parameter if possible within 2 seconds
     * @param callback - function with one parameter - task_tree
     */
    var do_with_task_tree = function(callback) {
        var p;
        maxkir.wait_for(function() {
            p = app.current_page();
            return p && p.task_tree && p.task_tree();
        }, function() {
            callback(p.task_tree());
        }, 2);
    };

    /**
     * @class BookmarkletCommand
     */
    var BookmarkletCommand = BaseCommand.extend({

        // Command fields and methods:
        type: "bookmarklet"

    }, {
        init: function(task) {
            BaseCommand.prototype.init.call(this);
            this.task_id = task ? task.id : -1;
            this.list_id = task ? task.checklist_id : -1;
            this.task = task;
            this.idempotency_key = maxkir.uuid();

            this.file_uuids = [];
            this.form_options = {
                parse_tasks: true,
                separate_with_empty_line: true
            };


            this.store_attribute("form_options", "file_uuids", "task_uuid", "idempotency_key");
        },

        preload: function() {
            var that = this;

            that._task = null;
            return new Promise(function(resolve, reject) {
                Task.findLocal(that.task_uuid).then(function(t) {

                    that._task = t;
                    TempTasks.find_replacement_id(t.checklist_id).then(function(new_list_id) {
                        app.debug("For newly added task set list_id to " + new_list_id + " instead of " + t.checklist_id);
                        t.attr('checklist_id', new_list_id);
                    }).then(resolve, resolve);

                }, function() {
                    app.warn("Could not get task for bookmarklet command execution: " + that.task_uuid + ", aborting command");
                    that._cleanup_stale_data();
                    resolve();
                });
            });
        },

        sort_array_key: function() {
            var key = this._task ? [this._task.parent_id || 0, this._task.position] : [];
            // console.warn("KEY", key, this._task.content)

            return key;
        },

        /**
         * @param form
         * @returns {Deferred} which resolves to this command (saved and prepared for running)
         */
        init_from: function(form) {
            form = form[0];
            this.list_id = $(form.checklist_id).val();
            app.debug("Create bookmarklet command for form in list " + this.list_id, form);

            this.form_options.separate_with_empty_line = $(form.separate_with_empty_line).val();

            if (form.from_url) {
                this.form_options['from_url'] = $(form.from_url).val();
            }

            var that = this;
            var content = $(form.import_content).val();

            return this.prepare_files_data_to_store(form).then(function(uploads_data) {
                var parent_id = $(form.parent_id).val();
                var position = parseInt($(form.position).val());

                if (/^\s+\S/.test(content)) {
                    return that._add_subtask(parent_id, position, content, uploads_data);
                }

                return that.init_simple(that.list_id, content, parent_id, position, uploads_data);
            });
        },

        _add_subtask: function(parent_id, position, content, uploads_data) {
            var that = this;
            var options = {
                checklist_id: Task.fix_id(that.list_id),
                parent_id: Task.fix_id(parent_id),
                skip_server: true
            };
            if (options.parent_id) {
                delete options.checklist_id;
            }

            return Task.findAll(options).then(function (data) {
                for(var i = 0; i < data.length; i ++) {
                    if (data[i].position + 1 === position) {
                        return that.init_simple(that.list_id, content, data[i].id, 1, uploads_data);
                    }
                }
                return that.init_simple(that.list_id, content, parent_id, position, uploads_data);
            });
        },

        /**
         * @param list_id
         * @param content
         * @param parent_id
         * @param position
         * @param {Array<UploadRec>} uploads_data
         */
        init_simple: function(list_id, content, parent_id, position, uploads_data) {
            var that = this;
            var temp_task;

            var result = TaskTextParser.parse_content(content, app.current_user.pro)
                .then(function task_parsed(parsed_data) {

                    temp_task = that.prepare_temp_task(list_id, content, uploads_data);
                    temp_task.update_from(parsed_data);

                    if (parent_id !== 0 && position > 0) {
                        if (!TempTasks.is_local(parent_id)) {
                            parent_id = parseInt(parent_id);
                        }
                        temp_task.attr('parent_id', parent_id);
                        temp_task.attr('position', position);
                    }

                    that.task_uuid = temp_task.id;
                    var position_increaser = Task.update_positions(list_id, temp_task.parent_id, temp_task.position, 1);

                    return position_increaser.then(function() {
                        return temp_task.save();
                    }).then(function() {
                        if (uploads_data.length > 0) {
                            return that.store_file_data_to_db(uploads_data);
                        }
                        else {
                            return that;
                        }
                    });

                });

            result.then(function() {
                if (temp_task) {
                    do_with_task_tree(function(task_tree) {
                        app.debug("Set selected task to temporary added one");
                        task_tree.select_task(temp_task.id);
                    })
                }
            });

            return result;
        },

        /**
         * @param {String|Number} list_id
         * @param {String} content
         * @param {Array<UploadRec>} uploads_data
         * @returns {Task}
         */
        prepare_temp_task: function(list_id, content, uploads_data) {
            var t = Task.create_local_task(list_id, content);
            if (uploads_data.length > 0) {
                this.file_uuids = uploads_data.map(function(d) { return d.key; });
                t.attr('uploads', this.file_uuids);
            }
            this._task = t;
            return t;
        },

        /**
         * @typedef {Object} UploadItem
         * @property {String} UploadItem.name
         * @property {String} UploadItem.type
         * @property {String} UploadItem.updated_at
         * @property {Number} UploadItem.size
         * @property {String} UploadItem.data URL representing the file's data as a base64 encoded string
         */

        /**
         * @typedef {Object} UploadRec
         * @property {String} UploadRec.key
         * @property {UploadItem} UploadRec.item
         */

        /**
          * @param form
         * @returns {Promise<Array<UploadRec>>} which resolves to array of loaded files with data
         */
        prepare_files_data_to_store: function(form) {
            var update_arguments = [];
            $(form).find(".upload-placeholder").each(function(i, span) {
                var file = $(span).data("upload-file");
                var file_data_to_store = {
                    file_tmp: file,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    updated_at: new Date().toISOString()
                };
                update_arguments.push({item: file_data_to_store, key: maxkir.uuid()});
            });

            var reduce_image = true;

            return new Promise(function(resolve, reject) {
                if (update_arguments.length === 0) {
                    resolve(update_arguments);
                }
                else {
                    var idx = 0;

                    var on_file_processed = function() {
                        idx ++;
                        if (idx === update_arguments.length) {
                            resolve(update_arguments);
                        }
                    };

                    update_arguments.forEach(function(arg) {

                        var file_reader = new FileReader();
                        file_reader.onload = function (event) {

                            delete arg.item.file_tmp;
                            app.debug("[DB] Loaded file contents for " + arg.item.name + " (" + arg.item.size + ")");

                            if (reduce_image) {
                                ImageReducer(event.target.result, 1024, arg.item.type).then(function (new_data_url) {
                                    app.debug("Image reduced for " + arg.item.name);
                                    arg.item.data = new_data_url;
                                    on_file_processed();
                                }, reject);
                            } else {
                                arg.item.data = event.target.result;
                                on_file_processed();
                            }

                        };
                        file_reader.readAsDataURL(arg.item.file_tmp);

                    })
                }
            });
        },


        store_file_data_to_db: function(update_arguments) {
            var that = this;
            app.debug("Store command file data to db");
            return storage_table_promise().then(function (table) {
                return table.update.apply(this, update_arguments);
            })
                .then(function () {
                    for (var i = 0; i < update_arguments.length; i++) {
                        app.debug("Written file command data", update_arguments[i].key, update_arguments[i].item.name);
                    }
                    return that;
                });
        },

        run: function() {
            var result = $.Deferred(),
                that = this;

            var fd = this._build_form_data(),
                execute_on_server = function(message) {
                    app.debug(message);
                    that.run_command_on_server(result);
                },
                fill_form_data_and_run = function(task) {
                    that._fill_form_data(fd, task);
                    that._load_files_from_db(fd).then(
                        execute_on_server.bind(that, "Bookmarklet command data loaded, execute on server"),
                        execute_on_server.bind(that, "Could not load all files for command execution: " + that.task_uuid + ", processing command without files")
                    )
                };

            this.fd = fd;

            var task = this._task;
            if (!task || task.deleted) {
                // Task was not preloaded, skip command  
                app.debug("No local task " + that.task_uuid + " for bookmarklet command");
                result.resolve();
            }
            else {
                TempTasks.find_replacement_id(task.checklist_id)
                    .then(function(new_list_id) {
                        task.attr('checklist_id', new_list_id);
                        that.list_id = task.checklist_id;
                    }, function() {
                        throw new Error("List ID is local, reject execution on server: " + task.checklist_id);
                    })
                    .then(function() {
                        return TempTasks.find_replacement_id(task.parent_id);
                    })
                    .then(function(new_parent_id) {
                        task.attr("parent_id", new_parent_id);
                    })
                    .then(function() {
                        fill_form_data_and_run(task);
                    }, result.reject);
            }

            return result;
        },

        _build_form_data: function() {
            var fd = new FormData();

            this._fd_append(fd, 'token', app.current_user.attr('token'));
            this._fd_append(fd, 'no_prefix_process', "true");
            for(var opt in this.form_options) {
                if (this.form_options.hasOwnProperty(opt) && this.form_options[opt]) {
                    this._fd_append(fd, opt, this.form_options[opt]);
                }
            }
            return fd;
        },

        _fill_form_data: function(form_data, task) {

            this._fd_append(form_data, "import_content", task.content + TaskTextParser.smart_syntax_attributes(task, app.current_user.pro));
            ['status', 'position', 'parent_id'].forEach(function(field) {
                if (typeof task[field] !== 'undefined') {
                    this._fd_append(form_data, field, task[field]);
                }
            }.bind(this));
        },

        _fd_append: function(form_data, field, value) {
            form_data.append(field, value);

            form_data._test_param = form_data._test_param || {};
            form_data._test_param[field] = value;
        },

        /**
         * @param {FormData} formData
         * @private
         * @return {Deferred}
         */
        _load_files_from_db: function(formData) {
            var preload_steps = [],
                that = this,
                file_uuids = this.file_uuids;

            if (file_uuids && file_uuids.forEach ) {
                file_uuids.forEach(function(uuid) {
                    app.debug("loading file: " + uuid);
                    var get_file_step = storage_table_get(uuid).then(function (file) {
                        if (file) {
                            formData.append("add_files[" + uuid + "]", that.file_info_to_Blob(file), file.name);
                        }
                        app.debug("loaded file: " + uuid);
                    });

                    preload_steps.push(get_file_step);
                });
            }

            return app.run_all(preload_steps);
        },

        file_info_to_Blob: function(file_info) {
            var BASE64_MARKER = ';base64,';
            var dataURL = file_info.data || "";

            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                var parts = dataURL.split(',');
                var raw = parts[1];
                return new Blob([raw], {type: file_info.type, name: file_info.name, size: file_info.size});
            }

            var parts = dataURL.split(BASE64_MARKER);
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array.buffer], {type: file_info.type, name: file_info.name, size: file_info.size});
        },

        update_command_ajax_params: function(ajax_params) {
            $.extend(ajax_params, {
                url: app.config.api_url + '/checklists/' + this.list_id + "/import.json",
                data: this.fd,
                headers: {
                    "Idempotency-Key": this.idempotency_key
                },
                processData: false,
                contentType: false,
                dataType: "json",
                type: 'POST'
            });
        },

        get_promises_on_server_response: function(added_tasks_json) {
            var that = this;
            var add_created_tasks_to_local_db = function(added_tasks_json) {
                var to_save = [];
                for(var i = 0; i < added_tasks_json.length; i ++) {
                    to_save.push(Task.create_from_json(added_tasks_json[i]))
                }
                return app.run_all(to_save);
            };

            var rebuild_tree_and_select_added = function(old_task_id, added_tasks_json) {
                do_with_task_tree(function(tree) {
                    var new_id = added_tasks_json.length && added_tasks_json[0].id;

                    if (added_tasks_json[0].checklist_id === that.list_id || $('#task_' + old_task_id).length) {

                        tree.rebuild_local().then(function() {
                            tree.expand_parents(new_id);
                            tree.select_task(new_id);
                        });

                        tree.rebuild("by bookmarklet command - new item(s) added");
                    }
                });
            };

            if (added_tasks_json && added_tasks_json.length > 0 && added_tasks_json[0].id) {
                // Register replacement of task UUID locally
                TempTasks.add_replacement(that.task_uuid, added_tasks_json[0].id);
            }

            return [add_created_tasks_to_local_db(added_tasks_json).then(function() {
                return that._cleanup_stale_data()
            }).then(function() {
                rebuild_tree_and_select_added(that.task_uuid, added_tasks_json);
            })];

        },

        /**
         * @return {Promise.<T>}
         * @private
         */
        _cleanup_stale_data: function() {
            // remove processed data from database
            var file_uuids = this.file_uuids;
            if (file_uuids && file_uuids.length > 0) {
                app.debug("Removing imported files from database", file_uuids);
                storage_table_promise().then(function(table){
                    file_uuids.forEach(function(uuid_to_remove) {
                        app.debug("Removing " + uuid_to_remove);
                        table.remove(uuid_to_remove);
                    })
                }, app.error);
            }
            if (this.task_uuid) {
                app.debug("Removing temporary task from database", this.task_uuid);
                return Promise.resolve(Task.destroy(this.task_uuid));
            }
            return Promise.resolve();
        },


        toString: function() {
            var res = "[" + this.type + "/task#" + this.task_uuid + "]#" + this.id;
            if (this._task) {
                res += ":" + this._task.content;
            }
            return res;
        }
    });

    BookmarkletCommand.init_command_factory();

    export default BookmarkletCommand;
