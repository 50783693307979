"use strict";
// Depends on core.js
// Depends on listeners.js

/**
 * @typedef {object} RenderContext
 * @property {boolean} [is_pwa]
 * @property {number} [task_id]
 * @property {Array<Upload>} uploads
 * @property {boolean} [no_extensions]
 */

maxkir.CommonRender = {

  BREADCRUMB_WIDTH_LIMIT: 100,

  stripTags: function(s) {
    return s.replace(/<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?>|<\/\w+>/gi, '').trim();
  },

  format_due: function(task_due, skip_weekday) {
    if (task_due == "ASAP") {
      return "asap";
    }
    return this.date(task_due).cl_format_date(true, true, skip_weekday);
  },

  date: function(due) {
    return maxkir.dates.date(due);
  },

  is_future_due: function(due) {
    if (!due || "ASAP" == due) {
      return false;
    }

    var due_date = this.date(due);
    
    return due_date.is_after_tomorrow();
  },

  is_local_url: function(url) {
    "use strict";
    var s1 = url.replace(/https?:\/\//,'').replace(/\/.*$/, '');
    var s2 = location.host;
    return s1 == s2;
  },


  due_date_css_class: function(task_data) {
    var result = '';
    
    var d = task_data.details;
    if (d && d.has_repeating) {
      result += ' repeatingDue' + (d.has_repeating > 1 ? ' repeatingPaused' : '');
    }
    
    var due = task_data.due;
    if (!due) return result;

    // Special processing for shortlist
    if ("ASAP" == due) {
      result += " due_asap dueASAP";
      return result;
    }
    else {
        var due_date = this.date(due);

        if (due_date.is_overdue() && task_data.status == 0) {
            result += " due_overdue dueOverdue";
        }
        else {
            result += due_date.is_today() ? " due_today dueToday" :
                due_date.is_tomorrow() ? " dueTomorrow" : due_date.is_after_tomorrow() ? " dueFuture" : "";
        }
    }

    return result;
  },

  sanitize_text: function(txt) {
    if (!this._sanitize_init) {
      this._sanitize_init = true;
      DOMPurify.setConfig({
        WHOLE_DOCUMENT: false,
        ADD_TAGS: ['u', 'table', 'tr', 'td', 'th', 'thead', 'tbody', 'q', 'iframe'],
        FORBID_TAGS: ['marquee'],
        ADD_ATTR: ['border', 'cellspacing', 'cellpadding', 'hspace', 'vspace', 'frameborder', 'allow', 'allowfullscreen'],
        ALLOW_UNKNOWN_PROTOCOLS: true
      });

      DOMPurify.addHook('uponSanitizeElement', function(node, data) {
        if (data.tagName === 'iframe') {
          const src = node.getAttribute('src') || ''
          if (!src.startsWith('https://www.youtube.com/embed/') && !src.startsWith('https://www.youtube-nocookie.com/embed/') ) {
            return node.parentNode ? node.parentNode.removeChild(node) : false;
          }
        }
      })

    }
    return DOMPurify.sanitize(txt).toString();
  },


  /**
   * Renders Checkvist's text by processing Checkvist own syntax + provides Markdown
   * conversion if needed.
   *
   * @param {string} text text to transform
   * @param {boolean} use_markdown if true, output is processed with Markdown
   * @param {RenderContext} [context]
   *
   * @returns {string} transformed text
   */
  format_for_rendering: function (text, use_markdown, context) {
      use_markdown = use_markdown || (maxkir.use_md && maxkir.use_md());
      context = context || {};
      context.is_pwa = !maxkir.DESKTOP;

      var s = text;
      if (!context.no_extensions) {
        s = maxkir.RenderExtensions.process_task_text(s, context);
      }

      s = this._render_md_links_if_needed(s, use_markdown);

      var escapeHtml = function(s) {
          return maxkir.escapeHtml(s);
      };

      var wrap_img = function(img) {
        return "<div class='renderedImages'>" + img + "</div>"
      };


      if (use_markdown) {

      if (!maxkir.md_converter) {
        var options = {
          html:         true,        // Enable HTML tags in source
          xhtmlOut:     false,        // Use '/' to close single tags (<br />)
          breaks:       true,        // Convert '\n' in paragraphs into <br>
          langPrefix:   'language-',  // CSS language prefix for fenced blocks
          linkify:      false        // Autoconvert URL-like text to links

          // Enable some language-neutral replacement + quotes beautification
          //typographer:  false,
          // Double + single quotes replacement pairs, when typographer enabled,
          // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
          //quotes: '“”‘’',

          // Highlighter function. Should return escaped HTML,
          // or '' if the source string is not changed
          //highlight: function (/*str, lang*/) { return ''; }
        };

        if (typeof hljs !== 'undefined') {
          options.highlight = function (str, lang) {
            if (lang && hljs.getLanguage(lang)) {
              try {
                return hljs.highlight(lang, str).value;
              } catch (err) {}
            }

            return ''; // use external default escaping
          }
        }

        maxkir.md_converter = new Remarkable(options);

        // Render images into a variable and append all images at the end of the item
        maxkir.md_converter.renderer.rules.image = function(tokens, idx, options /*, env */) {
          var src = ' src="' + escapeHtml(tokens[idx].src) + '"';
          var title = tokens[idx].title ? (' title="' + escapeHtml(tokens[idx].title) + '"') : '';
          var alt = ' alt="' + (tokens[idx].alt ? escapeHtml(tokens[idx].alt) : '') + '"';
          var suffix = options.xhtmlOut ? ' /' : '';
          return wrap_img('<img' + src + alt + title + suffix + '>');
        };
      }

      s = this._fix_checkmarks(s);
      s = maxkir.md_converter.render(s).trim();
      if (s.endsWith("</div></p>")) {
          var divStart = s.indexOf("<div class='renderedImages");
          if (divStart > 0) {
            s = s.substring(0, divStart) + "</p>" + s.substring(divStart, s.length - 4);
          }
      }

    }
    else {
      s = this._replace_new_lines(s);
    }


    // Process links non processed by Markdown
    s = this.make_clickable(s);
    return s;
  },

  format_for_breadcrumbs: function(s, shouldLimitWidth, context) {
    var rendered = s.replace(/^\[[*1]?]\s*([^(])/, '$1');  // remove [] list prefix
    var cr = maxkir.CommonRender;
    rendered = cr.sanitize_text(cr.format_for_rendering(rendered, true, context || {}));
    var res = cr.stripTags(rendered);
    if (shouldLimitWidth) {
      res = res.substr(0, cr.BREADCRUMB_WIDTH_LIMIT);
    }
    return res;
  },

  _render_md_links_if_needed: function(s, markdown_enabled) {

    if (!markdown_enabled) {
      s = s.replace(this.MARKDOWN_LINK_REG, function(str, pre, text, url) {
        return pre + "<A href=\""+ url+ "\">" + text + "</A>";
      });
    }

    return s;
  },

  _fix_checkmarks: function(s) {
    return s.replace(/- \[ ]/g, '- &#x2610;').replace(/- \[x]/g, '- &#x2611;')
  },

  // Same expression is used in wiki.rb
  LNKREG: /\[(\w+): *(.+?)\|\s*([-\w\/#]+|(([\w-]+:|\/)[^\]]+?))\]/g,
  MARKDOWN_LINK_REG: /([^!]|^)\[([^\n\]]*)\]\(([^)]+)\)/g,

  _replace_new_lines: function(s) {
    return s.replace(/\n/g, "<br>\n");
  },

  //   http://c/x/3ofiCg
  URL_REG: /(^|[^-='"\w])(\w[-\w]+\:(?:\/\/\/?|\\\\)(?:[a-zA-Z0-9]+)[:|_a-zA-Z0-9\-\.]*(?:\:[0-9]+)?(?:[^\s\n\r*<"\]\)\[\(]|\(\w+\)|\[\w*\])*)/gm,
  _URLREG: /(^|[^-='"\w])(\w[-\w]+\:(?:\/\/\/?|\\\\)(?:[a-zA-Z0-9]+)[:|_a-zA-Z0-9\-\.]*(?:\:[0-9]+)?(?:[^\s\n\r*<"\]\)\[\(]|\(\w+\)|\[\w*\])*)/m,

  make_clickable: function(txt) {
    return txt.replace(this.URL_REG, function(match, p1, p2, idx, s) {
      //console.info(match, p1, p2)
      var prev_open_bracket = s.lastIndexOf('<', idx);
      var next = s.charAt(prev_open_bracket + 1);
      if (prev_open_bracket >= 0 && (next === 'A' || next === 'a') ) {

        var prev_close_bracket = s.lastIndexOf('>', idx);
        if (prev_close_bracket > prev_open_bracket) {
          // Already wrapped to link, inside <a >url</a>
          return match;
        }
        
        if (prev_open_bracket > prev_close_bracket) {
          // We're inside HREF?
          return match;
        }
      }
      
      return p1 + '<a class="toShorten" href="' + p2 + '">' + p2 + '</a>';
    });
  },

  /**
   * Return converted username which can be a part of @assignee expression when editing user
   * @param username
   */
  user_short_name: function(username) {
    return username.trim().replace(/ /g, '_').replace(/</g, "&lt;");
  },


  /**
   * This callback maps some ID to a string value
   * @callback ID2Text
   * @param {number} id - id to be converted
   * @return {string} converted text

   * @param task_data
   * @param {ID2Text} tag_name_by_tag_id
   * @param {ID2Text} username_by_user_id
   * @param {boolean} is_pro
   */
  smart_syntax_attributes: function(task_data, tag_name_by_tag_id, username_by_user_id, is_pro) {

    var tokens = this.smart_syntax_tokens(task_data, tag_name_by_tag_id, username_by_user_id, is_pro);
    if (tokens.length) {
      return " " + tokens.join(" ");
    }
    return "";
  },

  /**
   * This callback maps some ID to a string value
   * @callback ID2Text
   * @param {number} id - id to be converted
   * @return {string} converted text

   * @param task_data
   * @param {ID2Text} tag_name_by_tag_id
   * @param {ID2Text} username_by_user_id
   * @param {boolean} is_pro
   */
  smart_syntax_tokens: function(task_data, tag_name_by_tag_id, username_by_user_id, is_pro) {

      var tokens = [];

      // Append due mark
      if (task_data.due) {
        tokens.push("^" + this.format_due(task_data.due, 'skip'));
      }

      var tag_names = [];
      for(var tag_id in task_data.tags) {
        //noinspection JSUnfilteredForInLoop
        tag_names.push(tag_name_by_tag_id(tag_id));
      }
      tag_names = tag_names.sort();

      for(var i = 0; i < tag_names.length; i ++) {
        tokens.push("#" + tag_names[i]);
      }

      var assignee_ids = task_data.assignee_ids;
      if (assignee_ids && assignee_ids.length && is_pro) {
        for(i = 0; i < assignee_ids.length; i++) {
          var username = username_by_user_id(assignee_ids[i]);
          if (username) {
            tokens.push("@" + this.user_short_name(username));
          }
        }
      }

      // Append color/priority mark
      if (task_data.mark > 0) {
        tokens.push("!" + task_data.mark);
      }
      else if (maxkir.Task) {
        const priority = maxkir.Task.priority(task_data);
        if (priority > 0) {
          tokens.push("!" + priority);
        }
      }

      return tokens;
  },

  /**
   * Parse list item txt and find out if list-related CSS classes should be applied due to
   * item prefixing with [], [*], [1]
   *
   * @param {string} txt original text
   * @param {boolean} markdown_enabled whether markdown is enabled for the list
   * @return {{text: string, css_list_class: string}}
   */
  prepare_list_style: function(txt, markdown_enabled) {

    if (txt.length && txt[0] === '[' && markdown_enabled) {
      var patterns = [
        '[]', 'boxesList',
        '[ ]', 'boxesList',
        '[*]', 'bulletsList',
        '[1]', 'numberedList'
      ];

      for (var i = 0; i < patterns.length; i += 2) {
        var pattern = patterns[i]; 
        if (txt.indexOf(pattern) === 0) {
          if (i === 0 && txt.charAt(2) === '(') {
            // Skip markdown link without title at the beginning of a task
            continue;
          }
          var newtxt = txt.substr(pattern.length).trim();
          if (newtxt.length > 0) {
            return {text: newtxt, css_list_class: patterns[i + 1]};
          }
        }
      }
    }
      
    return {text: txt, css_list_class: ''};
  },

  HEADER_RE: /^<h([1-6])/i,
  header_class: function(rendered_content) {
    var header = this.HEADER_RE.exec(rendered_content);
    if (header) {
      return ' header' + header[1];
    }
    return '';
  },


  /**
   * Applies CSS styles for custom color/priority, fetched from /theme/priority_styles.json, to the current Web page
   * by creating a &lt;style> element.
   *
   * @param styles_json
   */
  apply_color_styles: function(styles_json, withBorderRadius) {
    const styles = styles_json;
    const KEY = '_priority_styles';

    if (styles) {
      maxkir.info("Installing custom priority styles");
      var text = "";
      for(var priority in styles) {
        if (priority > 0 && styles.hasOwnProperty(priority)) {

          var data = styles[priority];
          var selectorFg = `.priorityColor_${priority}, .priorityColor_${priority} a,  .priorityColor_${priority} a:visited`;
          var selectorBg = `.priorityColor_${priority}`;

          var styles_text = '';
          var styles_bg = '';
          if (data['color'] !== 'inherit') {
            styles_text += `color: ${data['color']};`;
          }
          if (data['backgroundColor'] !== 'inherit') {
            styles_bg += `background-color:${data['backgroundColor']};`;
          }

          if (withBorderRadius) {
            if (data['color'] === 'inherit' && data['backgroundColor'] !== 'inherit') {
              styles_bg += "border-radius: 3px;"
            }
            else {
              styles_bg += "border-radius: 0;"
            }
          }

          text += selectorFg + " {" + styles_text + "}\n";
          text += selectorBg + " {" + styles_bg + "}\n";
        }
      }

      var el = document.getElementById(KEY);
      if (!el) {
        el = document.createElement("style");
        el.setAttribute("id", KEY);
        el.id = KEY;
      }
      else {
        document.body.removeChild(el);
      }
      el.innerHTML = text;

      document.body.appendChild(el);
      maxkir.info("Custom priority styles have been installed: " + el.innerHTML);
    }
  },

  /**
   * Change given string by replacing content like !!! or ^4 at the beginning of string
   * with the suffix ^3 at the end of it - to ensure it is handled on the server side as a priority mark.
   *
   * @param content
   * @returns {string}
   */
  update_priorities_at_start: (content) => {
    // the last match of !3 syntax in the string
    const priorityMatch = content.match(/(!\d *)(?!.*!\d\b)/);
    if (priorityMatch) {
      if (priorityMatch.index === 0) {
        return content.substring(priorityMatch[1].length) + " " + priorityMatch[1].trim();
      }
    }
    else {
      for(let pr = 9; pr > 0; pr --) {
        if (content.startsWith("!".repeat(pr) + " ")) {
          return content.substring(pr + 1) + " !" + pr
        }
      }
    }
    return content;
  },


  _f: null
};


maxkir.RenderExtensions = {
  _renderers: new maxkir.Listeners(),

  add_renderer: function (renderer, prepend) {
    this._renderers.add_listener(renderer, prepend);
  },

  remove_renderer: function (renderer) {
    this._renderers.remove_listener(renderer);
  },

  /**
   * @param {string} s
   * @param {RenderContext} context
   * @return {string}
   */
  process_task_text: function(s, context) {
    if (context.task_id) {
      var that = this;
      this.render_with_task_context(context.task_id, function () {
        if (!that.context().has_cycle()) {
          s = that.process_text(s, context);
        }
      })
    }
    else {
      s = this.process_text(s, context);
    }
    return s;
  },

  process_text: function(s, render_context) {
    var l = this._renderers.get_listeners();
    for(var i = 0; i < l.length; i ++) {
      s = l[i].process_text(s, render_context);
    }
    return s;
  },

  _rendered_task_ids: [],

  context: function() {
    var that = this;
    return {
      get_current_task_id: function () {
        var stack = that._rendered_task_ids;
        return stack.length ? stack[stack.length - 1] : null;
      },

      has_cycle: function() {
        var peek = this.get_current_task_id();
        if (peek) {
          var stack = that._rendered_task_ids;
          for(var i = 0; i < stack.length - 1; i ++) {
            if (stack[i] === peek) {
              return true;
            }
          }
        }
        return false;
      }
    }
  },

  render_with_task_context: function(task_id, callback) {
    this._rendered_task_ids.push(task_id)
    try {
      return callback()
    }
    finally {
      this._rendered_task_ids.pop()
    }
  }
}



/**
 * @param {{is_collapsed: (function(number): boolean), task_data: (function(*): Task), children_provider: (function(number): Array<number>), origin: String }} context
 * @return {{get_uploads: (function(): Array), generate_plain: (function(*): string)}}
 * @constructor
 */
maxkir.BaseCopyGenerator = function(context) {

  var level = 0;
  var uploads = [];
  var processed = {};

  var fix_content = function(t) {
    return t.replace(/^\[([*1])?]\s*/g, '')
  };

  var generate_task_plain = function(task, prefix) {
    if (processed[task.id]) {
      return '';
    }
    processed[task.id] = true;

    var text = prefix + fix_content(task.content) + "\n";
    uploads = uploads.concat(task.uploads || []);

    if (!context.is_collapsed(task.id)) {
      var tsks = context.children_provider(task.id) || [];
      tsks.forEach(function(child_id) {
        level = level + 1;
        text += generate_from_task_id_plain(child_id, '   '.repeat(level ) + '- ');
        level = level - 1;
      });
    }

    return text;
  };

  var generate_from_task_id_plain = function (task_id, prefix) {
    var task = context.task_data(task_id);
    return task ? generate_task_plain(task, prefix) : '';
  };

  return {
    generate_plain: function (task_ids) {
      var res = '';
      level = 0;
      uploads = [];
      processed = {};

      task_ids.forEach(function(task_id) {
        var task = context.task_data(task_id);
        if (task) {
          res += generate_task_plain(task, '');
        }
      });
      return res;
    },

    generate_from_texts: function(txtArray) {
      return txtArray.join('\n\n');
    },

    get_uploads: function () {
      uploads.forEach(function(u) {
        if (u.url.startsWith('/')) {
          u.url = context.origin + u.url;
        }
      });
      return uploads;
    }
  }
};


