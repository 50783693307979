import $ from "jquery";
import app from "app";

import BaseCommand from "app/commands/base_command";
import Task from "app/model/task";
import TempTasks from "app/model/temp_objects";

const can = app.can;

    /**
     * @class BaseTaskCommand
     *
     * To override:
     *   constructor.type
     *
     * init
     * serialize_json
     * init_from_json
     * update_command_ajax_params
     *
     * fill_task_finders
     * create_update_tasks_local_deferred
     *
     */
    const BaseTaskCommand = BaseCommand.extend({

        /**
         * @param task_id {number}
         * @returns {BaseTaskCommand} existing command, or null
         */
        find_in_commands: function(task_id) {
            const candidates = this.prototype.constructor.find_commands(function(cmd) {return cmd.task_id == task_id} );
            return candidates.length ? candidates[0] : null;
        }

    }, {

        /**
         * @param {Task} task
         */
        init: function(task) {
            BaseCommand.prototype.init.call(this);
            this.task_id = task ? task.id : -1;
            this.list_id = task ? task.checklist_id : -1;
            this.task = task;
            this.store_attribute("task_id", "list_id");
        },

        run: function() {
            var result = $.Deferred(),
                that = this;

            /**
             * @type {Deferred[]}
             */
            var find_tasks_def = [];
            this.tasks_to_update = [];

            this._add_main_task_finder(result, find_tasks_def);

            find_tasks_def[0].then(function() {
                that.tasks_to_update.push(that.task);
            });

            this.fill_task_finders(result, find_tasks_def);

            app.run_all(find_tasks_def).then(function() {

                if (that.why_invalid()) {
                    result.resolve();
                    return;
                }

                var update_tasks_def = that.create_update_tasks_local_deferred();
                app.debug("Running local command: " + that.toString());
                update_tasks_def.then(function(server_update_needed) {
                    app.debug("Local command update successful, server update is needed: " + server_update_needed + " " + that.toString());
                    if (server_update_needed) {
                        try {
                            that.run_command_on_server(result);
                        } catch (e) {
                            result.reject(e);
                        }
                    }
                    else {
                        result.resolve();
                    }
                }, result.reject);
            });

            return result;
        },

        /**
         * Command validity check, if command is invalid, it is ignored.
         * Called when this.task is available
         * @return {string} text description why the command is invalid or null if command is valid
         */
        why_invalid: function() {
            return null;
        },

        _add_main_task_finder: function(result, find_tasks_def) {
            var that = this;
            var add_task_finder = function() {
                return Task.findLocal(that.task_id).then(function(task) {
                    that.task = task;

                    var invalid_reason = that.why_invalid();
                    if (invalid_reason) {
                        app.error("Ignore invalid command " + that.toString() + " for task #" + that.task_id + ": " + invalid_reason);
                        result.resolve();
                    }

                }, function() {
                    app.warn("Cannot find task #" + that.task_id + ", ignore command " + that.toString());
                    result.resolve();
                });
            };

            find_tasks_def.push(
                TempTasks.find_replacement_id(this.task_id, Task).then(function(real_id) {
                    that.task_id = real_id;
                }, function still_temporary(){
                }).then(add_task_finder)
            );
        },

        /**
         * @param {Deferred[]} find_tasks_def
         * @param {Deferred} result can be rejected in a case of a error
         */
        fill_task_finders: function(result, find_tasks_def) {
        },

        /**
         * @param {Array} find_tasks_def
         * @param {Deferred} result can be rejected in a case of a error
         */
        do_fill_task_finders_with_children: function(result, find_tasks_def) {

            var that = this;

            var find_children = function(task_id) {
                find_tasks_def.push(Task.findAll({parent_id: task_id, index_name: "parent_id", skip_server: true}).then(function(tasks) {
                    for(var i = 0; i < tasks.length; i ++) {
                        var t = tasks.attr(i);
                        that.tasks_to_update.push(t);
                        find_children(t.id)
                    }
                }, result.reject));
            };

            find_children(this.task_id);
        },


        /**
         * @return {Deferred} Deferred, which resolves to boolean value whether server update is needed. If rejected - command will be rejected, without server update.
         */
        create_update_tasks_local_deferred: function() {
            return $.Deferred().resolve(false);
        },

        /**
         * Process all tasks collected with do_fill_task_finders_with_children and changes them accordingly
         *
         * @param task_callback {function} callback which takes a task and makes modifications on it. Callback returns a deferred for saving modifications.
         * @return {Deferred} Deferred, which resolves to boolean value whether server update is needed. If rejected - command will be rejected, without server update.
         */
        do_create_update_tasks_local_deferred: function(task_callback) {
            app.debug("Command " + this.type + " collected tasks: " + this.tasks_to_update.map(function(t) { return t.id }));
            var update_tasks_def = [];

            can.batch.start();
            // Update details for collected tasks in memory:
            for(var i = 0; i < this.tasks_to_update.length; i ++) {
                var t = this.tasks_to_update[i];
                var def = task_callback(t);
                if (def) {
                    update_tasks_def.push(def);
                }
            }
            can.batch.stop();

            app.debug("Saving tasks for " + this.type + ": " + this.tasks_to_update.map(function(t) { return t.id }));
            return app.run_all(update_tasks_def).then(function() {return true;});
        },


        update_command_ajax_params: function(ajax_params) {
            ajax_params.data.with_notes = true;
            // To be overriden by overriding classes
        },

        run_command_on_server: function(result) {

            if (this.task.is_local) {
                // Do not run server part if task changed only locally
                result.resolve();
                return;
            }

            BaseCommand.prototype.run_command_on_server.call(this, result);
        },

        /**
         * @param {Array} updated_tasks_json
         */
        get_promises_on_server_response: function(updated_tasks_json) {
            var to_run = [];
            if (typeof updated_tasks_json.length !== 'undefined') {
                for(var i = 0; i < updated_tasks_json.length; i ++) {
                    // todo: this is a place to remember data for UNDO?
                    Task.update_from_json(to_run, updated_tasks_json[i], true);
                }
            }
            else {
                if (updated_tasks_json.id) {
                    Task.update_from_json(to_run, updated_tasks_json, true);
                }
            }
            return to_run;
        },

        toString: function() {
            return "[" + this.type + "/task#" + this.task_id + "]#" + this.id;
        }
    });

export default BaseTaskCommand;
