import app from "app";
import Page from "app/pages/page";

export default Page.extend('HelpPage', {
            defaults: {
                template: "help_page.mustache",
                page_title: "Checkvist: " + I18N.t("help").toLocaleLowerCase()
            }
        },
        {

            default_back_url: function() { return "/app/settings.html"; },

            get_model: function() {
                return app.current_user;
            }

        });
