define(function (require, exports, module) {

    var $ = require('jquery');

    /**
     * @param image_data_url
     * @param max_size limit size by x/y with this maximum
     * @param content_type
     * @returns {Deferred}
     */
    var reducer = function(image_data_url, max_size, content_type) {
        var result = $.Deferred();
        if (content_type.indexOf("image") == -1) {
            result.resolve(image_data_url);
            return result;
        }

        // Reducing the image
        var image = new Image();
        image.onload = function () {

            var canvas = document.createElement('canvas'),
                width = image.width, height = image.height;
            if (width > height) {
                if (width > max_size) {
                    height *= max_size / width;
                    width = max_size;
                }
            } else {
                if (height > max_size) {
                    width *= max_size / height;
                    height = max_size;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);

            result.resolve(canvas.toDataURL(content_type))
        };

        image.src = image_data_url;

        return result;
    };


    module.exports = reducer;

});