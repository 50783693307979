define(function (require, exports, module) {
    var app = require('app');
    var can = app.can;
    var Task = require('app/model/task');
    var List = require('app/model/list');
    var maxkir = require('app/maxkir');
    var commands = require('app/command_queue');

    var task_promises = [];

    var DueTaskLoader = {

        _listeners: new maxkir.Listeners(),
        _tasks: new can.List(),

        interrupt: function() {
            this._interrupted = true;
        },
        
        destroy: function() {
            this._listeners.dispose();
        },

        has_loaded_data: function() {
            return this.has_data() && (this._loaded_from_server || this._loaded_from_db);
        },

        has_data: function() {
            if (this._failure_server && this._failure_db) {
                return false;
            }
            return this._tasks.length;
        },
        
        /**
         * @typedef {Object} DueListener
         * @property {Function} [DueListener.on_new_tasks] - can be called several times
         * @property {Function} [DueListener.on_done]
         * @property {Function} [DueListener.on_error]
         * 
         * @param {DueListener} callback_object
         */
        add_callback: function(callback_object) {
            this._listeners.add_listener(callback_object);
        },

        start_loading: function(force) {

            if (this._loaded_from_server && !force) {
                return;
            }

            if (this._loading_from_db_started && this.has_data() && !force) {
                this._load_due_tasks_from_server();
                return;
            }

            this._reset_tasks();

            this._load_due_tasks_from_server();
            this._load_due_tasks_from_local_db();
        },

        /**
         * @param {String} tab_code one of 'due', 'dueASAP', 'dueLater'
         * @return {Array<Task>} Non-deleted tasks with appropriate due from model
         */
        get_tasks: function(tab_code) {
            var accept_by_due = this._filter_by_code(tab_code);
            var accept_by_ownership = this._accept_by_ownership();
            return this._tasks.filter(function(t) {
                return t.due && t.open() && accept_by_due(t) && accept_by_ownership(t);
            });
        },

        get_cached_due_task: function(task_id) {
            return this._tasks._map && this._tasks._map[task_id];
        },
        
        _filter_by_code: function(code) {
            var asap_filter = function(t) {
                return t.due.toLowerCase && t.due.toLowerCase() === 'asap';
            }; 
            
            if (code === 'dueASAP') {
                return asap_filter; 
            }
            if (code === 'due') {
                return function (t) {
                    return maxkir.dates.within('now', t.due) && !asap_filter(t);
                }
            }
            if (code === 'dueLater') {
                var end_of_today = new Date().end_of_day().getTime();
                return function (t) {
                    return maxkir.dates.msecs(t.due) > end_of_today;
                }
            }
            throw new Error("Unknown filter code: " + code);
        },

        _accept_by_ownership: function() {
            var me = app.current_user;
            var show_not_mine = me.prefs().get_boolean_pref('show_not_mine', false);

            return function(t) {
                return (show_not_mine || !t.due_user_ids || t.due_user_ids.indexOf(me.id) >= 0);
            }
        },
        
        _trigger: function(event, param) {
            app.debug('DueTaskLoader trigger ' + event);
            this._listeners.notify_listener(event, param);
        },

        _on_failure: function(msg, err) {
            app.debug(msg, err);
            if (this._failure_db && this._failure_server) {
                this._trigger('on_error');
                this._trigger('on_done');
            }
        },

        _reset_tasks: function() {
            this._tasks.splice(0);
            this._tasks._map = {};
            task_promises = [];
        },

        _loader_has_new_data: function(tasks, force_empty) {

            if (force_empty || !this._tasks._map) {
                this._reset_tasks();
            }

            for (var i = 0; i < tasks.length; i++) {
                var t = tasks[i];
                var existing = this.get_cached_due_task(t.id);

                if (existing) {
                    if (existing !== t) {
                        existing.attr(t, true);
                    }
                }

                if (!t.due || t.deleted) {
                    if (existing) {
                        delete this._tasks._map[t.id];
                        var idx = this._tasks.indexOf(existing);
                        this._tasks.splice(idx, 1);
                    }
                }
                else if (!existing) {
                    this._tasks._map[t.id] = t;
                    this._tasks.push(t);
                }
            }
            
            this._trigger('on_new_tasks', force_empty);
        },
        
        _load_due_tasks_from_server: function () {
            app.debug("Start loading due tasks from server");
            this._interrupted = false;
            this._loaded_from_server = false;
            this._failure_server = false;

            var that = this;
            
            // Update Due only when current changes are processed
            commands.process_commands().always(function() {

                Task.findDue().then(function (result) {
                    if (that._interrupted) {
                        return;
                    }

                    that._loaded_from_server = true;
                    app.debug("Due tasks are loaded from server: " + result.length);
                    that._loader_has_new_data(result, true);
                    that._trigger('on_done');

                }, function (err) {
                    that._failure_server = true;
                    that._on_failure("Could not load due data from server", err);
                });

            });
            
        },

        _load_due_tasks_from_local_db: function() {
            this._interrupted = false;

            this._loading_from_db_started = true;
            this._failure_db = false;

            var that = this;
            
            var check_can_continue = function(message_suffix) {
                if (that._loaded_from_server) {
                    app.debug("Interrupt due tasks update from local DB cache due to update from server" + message_suffix);
                    return false;
                }
                if (that._interrupted) {
                    app.debug("Interrupted due tasks update from local DB cache" + message_suffix);
                    return false;
                }
                return true;
            };

            return List.all_lists({skip_server: true}).then( function(lists) {
                if (!check_can_continue(": list load")) {
                    return;
                }

                if (!lists.length) {
                    that._failure_db = true;
                    that._on_failure("No lists in the local database");
                    return;
                }

                var create_task_load_promise = function(list) {
                    if (!check_can_continue(": " + list.name)) {
                        return Promise.reject();
                    }

                    return list.tasks({skip_server: true}).then(function (tasks) {

                        if (!check_can_continue(": tasks for " + list.name)) {
                            return Promise.reject();
                        }

                        app.debug("Tasks are loaded for due page from local DB: " + list.name + " " + tasks.length);

                        that._loader_has_new_data(tasks);
                    });
                };

                var current_promise = Promise.resolve();
                lists.forEach(function(lst) {
                    current_promise = current_promise.then(function() {
                        return create_task_load_promise(lst);
                    });
                });

                return current_promise.then(function () {
                    that._loaded_from_db = true;
                    that._trigger('on_done');
                });

            }, function (err) {
                that._failure_db = true;
                that._on_failure("Could not lists to load due data from local DB", err);
            });
        },
        
        reset_due_cache: function() {
            this.interrupt();
            this._reset_tasks();
            
            this._loaded_from_server = false;
            this._loading_from_db_started = false;
            this._loaded_from_db = false;
            this._failure_server = false;
            this._failure_db = false;
            
            app.debug("DueTaskLoader server cache is reset");
            return Promise.resolve();
        }
    };
    
    // Update due model on changes in local database
    var on_loaded_delayed = new maxkir.delay_action(function() {
        var copy = task_promises;
        task_promises = [];
        
        if (!copy.length) return;
        
        Promise.all(copy).then(function (tasks) {
            app.debug("DueTaskLoader: update due tasks on change in DB: " + tasks.length);
            DueTaskLoader._loader_has_new_data(tasks);
        }, function(err) {
            app.error("Cannot load local task from DB for updating DueTaskLoader", err);
        });
    }, 20);
    
    Task.add_db_listener({
        onSave: function(tasks_json) {
            // console.warn('onSave', tasks_json);
            tasks_json.forEach(function(t) {
                if (t.id) {
                    var existing = DueTaskLoader.get_cached_due_task(t.id);
                    if (existing) {
                        task_promises.push(Promise.resolve(existing));
                    }
                    else if (t.due) {
                        task_promises.push(Task.findLocal(t.id));
                    }
                }
            });
            if (task_promises.length) {
                on_loaded_delayed.start();
            }
        }
    });

    DueTaskLoader._tasks.bind("change", function() {
        // Fake listener to keep elements in store[] cache
        
        // console.warn("Due _tasks change", arguments);
    });
    setInterval(function() {
        DueTaskLoader._load_due_tasks_from_server();
    }, 60*60*1000);


    module.exports = DueTaskLoader;
});