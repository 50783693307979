define(function (require, exports, module) {

    var app = require('app');
    var sync = require('app/synchronizers');
    var List = require('app/model/list');
    var ListState = require('app/model/list_state');
    var Task = require('app/model/task');
    var User = require('app/model/user');
    var CommandQueue = require('app/command_queue');
    var DueTaskLoader = require('app/model/due_task_loader');

    /**
     * @class AppCleaner
     */
    var AppCleaner = {
        clean_all: function() {

            return app.storage.open_db().then(function (server) {
                return app.run_all([
                        CommandQueue.clear_with_db(),
                        sync.reset(),
                        DueTaskLoader.reset_due_cache(),
                        List.reset_cache({}),
                        User.reset_cache({}),
                        Task.reset_cache({}),
                        ListState.reset_cache({}),
                        server['temp_objects'] && server['temp_objects'].clear(),
                        server['files_storage'] && server['files_storage'].clear()
                    ]);
            }).always(function() {
                app.storage.close();
                app.debug("FULL Reset completed ---------------------------------");
            });
        }
    };
    
    module.exports = AppCleaner;
});