// This file contains references to maxkir/* files taken from the main Checkvist project
define(function(require, exports, module) {

    require('app/maxkir/core');
    var $ = require('jquery');
    require('app/maxkir/date_ext');
    require('app/maxkir/listeners');
    require('app/maxkir/dates');
    require('app/maxkir/base_filter');
    require('app/maxkir/render_util');
    require('app/maxkir/upload_shared');
    require('app/maxkir/integrations');

    require('app/maxkir/tree_model');
    require('app/maxkir/tree_nav');

    require('app/maxkir/extensions/base_render_extension');
    require('app/maxkir/extensions/list_link_extension');
    require('app/maxkir/extensions/item_link_extension');

    window.DOMPurify = require('app/maxkir/purify');

    var result = window.maxkir;
    
    result.$ = function(id) {
        return typeof id === "string" ? document.getElementById(id) : id && id.nodeType ? id : null;
    };

    result.addClassName = function(el, classname) {
        el = this.$(el);
        if (el) el.classList.add(classname);
    };

    result.removeClassName = function(el, classname) {
        el = this.$(el);
        if (el) el.classList.remove(classname);
    };

    result.hasClassName = function(el, classname) {
        el = this.$(el);
        return el && el.classList.contains(classname);
    };

//    maxkir.debug = maxkir.info;

    maxkir.Integrations.init_base("/app/list/");


    maxkir.enable_debug = function(enable) {
        if (maxkir._debug && enable) return;
        if (!maxkir._debug && !enable) return;

        if (enable) {
            maxkir._debug = true;
            sessionStorage.setItem("debug_session", "true");

            $( document ).on("ajaxSend.debugCv", function( event, jqxhr, settings ) {
                maxkir.info("URL Send     " + settings.url);
            });
            $( document ).on("ajaxComplete.debugCv", function( event, jqxhr, settings ) {
                maxkir.info("URL Complete " + settings.url + " " + jqxhr.status);
                if (jqxhr.status > 400) {
                    maxkir.info(new Error("Request problem"))
                }
            });

            maxkir.info("Debug session is enabled");
        }
        else {
            maxkir._debug = false;
            sessionStorage.removeItem("debug_session");

            $( document ).off(".debugCv");

            maxkir.info("Debug session is disabled");
        }
    };

    maxkir.enable_debug("true" === sessionStorage.getItem("debug_session") || maxkir.is_testing() );

    maxkir.has_errors = 0;
    window.onerror = function(msg,file,line,column) {
        maxkir.error("Uncatched error: " + msg, "curl -o a.js " + file, "norm " + line + "G" + column + "|");
        maxkir.has_errors ++;
    };

    module.exports = result;
});