(function() {
    // Get a handle on the global object
    var local;
    if (typeof global !== 'undefined') local = global;
    else if (typeof window !== 'undefined' && window.document) local = window;
    else local = self;

    if (typeof local.Promise.prototype['finally'] !== 'function') {
        local.Promise.prototype['finally'] = function finallyPolyfill(callback) {
            var constructor = this.constructor;

            return this.then(function(value) {
                return constructor.resolve(callback()).then(function() {
                    return value;
                });
            }, function(reason) {
                return constructor.resolve(callback()).then(function() {
                    throw reason;
                });
            });
        };
    }
    
    // Create an alias for compatibility
    local.Promise.prototype['always'] = local.Promise.prototype['finally'];
    
}());