/**
 * This class performs some matching between dates and some date interval representation.
 *
 * requires date_ext.js
 *
 * */

maxkir = window.maxkir || {};
maxkir.dates = {

  /**
   * Returns true if given date is within named interval.
   * Interval names are:
   *      "any" - returns true if date is set
   *      "none" - returns true if date is falsey
   *      "asap" - returns true if date == 'asap' or "ASAP'
   *      week, this week - current week, starting with monday
   *      last week, previous week - previous week, starting with monday
   *      next week - next week, starting with next monday
   *      1h, 3days, 4weeks
   *
   * @param interval_name
   * @param date
   */
  within: function(interval_name, date) {
    if (!date) {
      return interval_name === 'none';
    }
    else if (interval_name === 'any'){
      return true;
    }
    
    this.initialize();

    var lc = interval_name.toLowerCase();
    if (lc === "asap") {
      return "asap" === date.toString().toLowerCase();
    }

    // special case: now includes asap dues:
    if (lc === "now" && "asap" === date.toString().toLowerCase()) return true;

    var d = this.msecs(date);
    var matcher = this.date_matchers[lc];
    if (matcher) return matcher(d);

    var sinceTimeMatch = /(\d+)([hdw])\w*/.exec(lc);

    if (sinceTimeMatch) {
      var hours = 0;
      if (sinceTimeMatch[2] === 'h') {
        hours = sinceTimeMatch[1];
      }
      if (sinceTimeMatch[2] === 'd') {
        hours = sinceTimeMatch[1] * 24;
      }
      if (sinceTimeMatch[2] === 'w') {
        hours = sinceTimeMatch[1] * 24 * 7;
      }
      var start = new Date().getTime() - hours*3600*1000;
      return d >= start;
    }


    // Another special case - exact date specified as interval_name

    var d1 = maxkir.dates.date(d);
    d1.setHours(0, 0, 0, 0);
    var d2 = this._convert_to_date(interval_name)
    d2.setHours(0, 0, 0, 0);

    // console.warn(d1, d2)
    if (d1.getTime() === d2.getTime()) return true;

    return false;
  },

  _convert_to_date: function(str) {
    // normalize to / separator and place year to the first place if possible
    str = str.replace(/[-\\]/g, "/").replace(/(\d?\d)\/(\d?\d)\/(\d{4})/, "$3/$2/$1")
    if (str.search(/\d{4}/) < 0) {
      str += " " + new Date().getFullYear();
    }
    return new Date(str);
  },
  
  msecs: function(date) {
      if (date && date.replace) {
          return Date.parse(date.replace(/-/g, '/') + " 12:00:00");
      }

      return date > 0 ? date : date.getTime ? date.getTime() : Date.parse(date);
  },

  /**
   * @param date_str "2018-02-22"
   * @return {Date}
   */
  date: function(date_str) {
    return new Date(this.msecs(date_str));
  },

  initialize: function() {
    if (this._initialized) return;
    this.date_matchers = {};

    this.date_matchers["asap"] = function(date) {
      return date.toString().toLowerCase() == "asap";
    };

    this.date_matchers["today"] = function(d) {
      var today_start = new Date().beginning_of_day().getTime();
      var today_end = new Date().end_of_day().getTime();
      return d >= today_start && d <= today_end;
    };

    this.date_matchers["tomorrow"] = function(d) {
      var today_end = new Date().end_of_day().getTime();
      var tomorrow_end = new Date().end_of_day();
      tomorrow_end.setDate(tomorrow_end.getDate() + 1);
      tomorrow_end =  tomorrow_end.getTime();

      return d <= tomorrow_end && d > today_end;
    };

    this.date_matchers["yesterday"] = function(d) {
      var today_start = new Date().beginning_of_day().getTime();
      var day_start = new Date().beginning_of_day();
      day_start.setDate(day_start.getDate() - 1);
      var yesterday_start =  day_start.getTime();
      return d >= yesterday_start && d < today_start;
    };

    this.date_matchers["week"] = function(d) {
      var week_start = new Date().beginning_of_week().getTime();
      var week_end = new Date().end_of_week().getTime();
      return d >= week_start && d <= week_end;
    };
    this.date_matchers["this week"] = this.date_matchers.week;
    this.date_matchers["current week"] = this.date_matchers.week;

    this.date_matchers["last week"] = function(d) {
      var week_start = new Date().beginning_of_week().getTime();
      var prev_week_start = new Date().beginning_of_week();
      prev_week_start.setDate(prev_week_start.getDate() - 1);
      prev_week_start = prev_week_start.beginning_of_week().getTime();
      return d >= prev_week_start && d < week_start;
    };
    this.date_matchers["previous week"] = this.date_matchers["last week"];

    this.date_matchers["last month"] = function(d) {
      var month_start = new Date().beginning_of_month().getTime();
      var prev_month_start = new Date().beginning_of_month();
      prev_month_start.setDate(prev_month_start.getDate() - 1);
      prev_month_start = prev_month_start.beginning_of_month().getTime();
      return d >= prev_month_start && d < month_start;
    };
    this.date_matchers["previous month"] = this.date_matchers["last month"];

    this.date_matchers["next week"] = function(d) {
      var week_end = new Date().end_of_week().getTime();
      var next_week_end = (new Date(week_end + 1)).end_of_week().getTime();
      return d > week_end && d <= next_week_end;
    };

    this.date_matchers["month"] = function(d) {
      var month_start = new Date().beginning_of_month().getTime();
      var month_end = new Date().end_of_month().getTime();
      return d >= month_start && d <= month_end;
    };
    this.date_matchers["this month"] = this.date_matchers.month;
    this.date_matchers["current month"] = this.date_matchers.month;

    this.date_matchers["next month"] = function(d) {
      var month_end = new Date().end_of_month().getTime();
      var next_month_end = (new Date(month_end + 1)).end_of_month().getTime();
      return d > month_end && d <= next_month_end;
    };

    this.date_matchers["this year"] = function(d) {
      var year_end = new Date().end_of_year().getTime();
      var year_start = new Date().beginning_of_year().getTime();
      return d <= year_end && d >= year_start;
    };
    this.date_matchers["year"] = this.date_matchers["this year"];
    this.date_matchers["current year"] = this.date_matchers["this year"];

    this.date_matchers["next year"] = function(d) {
      var year_end = new Date().end_of_year().getTime();
      var next_year_end = new Date(year_end + 1).end_of_year().getTime();
      return d > year_end && d <= next_year_end;
    };

    this.date_matchers["now"] = function(d) {
      var today_end = new Date().end_of_day().getTime();
      return d <= today_end;
    };

    this.date_matchers["overdue"] = function(d) {
      var today_beginning = new Date().beginning_of_day().getTime();
      return d < today_beginning;
    };

    this._initialized = true;
  }
};