define(function (require, exports, module) {

    var app = require('app');
    var maxkir = require('app/maxkir');

    /**
     * @class UserPrefs
     */
    var UserPrefs = function(user_id) {
        this.user_id = user_id;
        
        this.prefs = {};
        var current = app.storage.get_item('user_prefs_' + this.user_id); 
        if (current) {
            this.prefs = JSON.parse(current);
        }
    };
    
    maxkir.extend(UserPrefs, {
        set_pref: function(name, value) {
            this.prefs[name] = value;
            app.storage.set_item('user_prefs_' + this.user_id, JSON.stringify(this.prefs));
        },

        get_boolean_pref: function(name, default_value) {
            if (this.prefs.hasOwnProperty(name)) {
                return !!this.prefs[name];
            }
            return !!default_value;
        }
    });
    
    module.exports = UserPrefs;
});
