define(function (require, exports, module) {

    var app = require('app');

    /**
     * @class ListsPreloader
     */
    var ListsPreloader = {

        /**
         * Cancel lists preloading, explicit command by user
         */
        cancel: function() {
            app._cancel_preload = true;
            app.debug("List preloading canceled manually");
        },

        /**
         * Interrupt lists preloading, may be due to visiting another page
         * @return Promise which resolves when preloading was interrupted
         */
        interrupt: function() {
            this._interrupted = true;
            app.debug("List pre-loading interrupted");
            var that = this;
            return new Promise(function (resolve) {
                (function check_preload() {
                    if (!that._active) {
                        resolve();
                    }
                    else {
                        setTimeout(function() {
                            check_preload();
                        }, 10);
                    }
                })();
            })
        },

        /**
         * @param {List[]} lists - collection of lists to be preloaded
         * @param {number} count - number of outdated list to preload
         * @param {function} show_progress(fraction) - call when preloading starts, with 0..1 floating number designighting the progress
         * @param {function} hide_progress - call when preloading ends
         */
        preload_lists: function(lists, count, show_progress, hide_progress) {

            if (app._cancel_preload) return;
            this._interrupted = false;

            var that = this;
            var to_preload = [];
            
            for(var i = 0; i < lists.length && to_preload.length < count; i ++) {
                var list = lists[i];
                if (!list.has_data()) {   // This if is untested
                    to_preload.push(list);
                }
            }

            app.debug("Start pre-loading lists: " + to_preload.length);
            var stop = function() {
                that._active = false;
                hide_progress();
                app.debug("END   pre-loading lists: " + to_preload.length);
            };

            this._active = true;
            if (to_preload.length === 0) {
                stop();
            } 
            else {

                var error_count = 0;

                var interrupted = function () {
                    return app._cancel_preload || that._interrupted;
                };

                /**
                 * @param {number} which
                 */
                var load_list = function (which) {
                    if (interrupted()) {
                        stop();
                        return;
                    }

                    var move_to_next = function (previous_got_error) {

                        if (interrupted()) {
                            stop();
                            return;
                        }

                        if (!previous_got_error) {
                            show_progress(which / to_preload.length);
                            error_count = 0;
                        }
                        else {
                            error_count++;
                        }

                        if (error_count == 3) {
                            // three error in a row - stop preloading
                            stop();
                            return;
                        }

                        if (which + 1 < to_preload.length && !interrupted()) {
                            load_list(which + 1);
                        }
                        else {
                            stop();
                        }
                    };
                    
                    to_preload[which].tasks({force_server: true}).then(function () {
                        
                        // To allow UI interaction on iOS/Firefox
                        setTimeout(function() {
                            move_to_next(false);
                        }, 20);
                        
                    }, function (err) {
                        if (!err.is_abort) {
                            app.error(err);
                            move_to_next(true);
                        }
                        else {
                            stop();
                        }
                    })
                };

                load_list(0);
            }
        }
    };
    
    module.exports = ListsPreloader;
});