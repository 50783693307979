import app from "../../app";
import SlidingPanel from "./sliding_panel";

/**
 * @param element
 * @param {ClickHandler} clickHandler
 */
var init_textarea_hints = function(element, clickHandler) {
    element = element.find('.flexVerticalPage');

    app.view_template("_import_hints.mustache", {}, {}, function(result) {
        $(element).append(result);

        var hint = document.querySelector(".addNote__hint");
        const hintPanel = new SlidingPanel(hint);
        var closer = document.querySelector(".addNote__closeHint");
        var hintIcon = document.querySelector(".addNote__hintIcon");

        var show_hints = function(show, fast) {
            if (show) {
                setTimeout(function() {
                    $(closer).show();
                }, 400);
                $(hintIcon).hide();
                hintPanel.show(fast);
                app.storage.set_item("import_hints_hidden", false);
            }
            else {
                $(closer).hide();
                setTimeout(function() {
                    $(hintIcon).show();
                }, 400);
                hintPanel.hide(fast);
                app.storage.set_item("import_hints_hidden", true);
            }
        };

        show_hints(!app.storage.get_item("import_hints_hidden"), true);

        clickHandler.add_click_handler(".addNote__hintCloser", "hintCloser", function (e) {
            e.preventDefault();
            show_hints(!maxkir.visible(closer));
        })
    });

};

export default init_textarea_hints;