define(function (require, exports, module) {

    var app = require('app');
    var maxkir = require('app/maxkir');

    /**
     * @class TagRenderer
     */
    var TagRenderer = {
        get_html: function(tags, cssClass) {
            var res = "";
            cssClass = cssClass || "";
            if (tags) {
                for(var tag in tags) {
                    if (tags.hasOwnProperty(tag)) {
                        res += '<span class="' + cssClass + ' tag">' + maxkir.CommonRender.sanitize_text(tag) + '</span>';
                    }
                }
            }
            return res;
        }
    };

    module.exports = TagRenderer;
});