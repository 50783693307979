var Path = {
    'version': "0.8.4",
    /**
     * @param path /foo/:article_id/index.html
     * @param url_param_names Array<String>  allowed names of URL params, all URLs with given params will be matched
     */
    'map': function (path, url_param_names) {
        if (Path.routes.defined.hasOwnProperty(path)) {
            return Path.routes.defined[path];
        } else {
            return new Path.core.route(path, url_param_names);
        }
    },
    'root': function (path) {
        Path.routes.root = path;
    },
    'rescue': function (fn) {
        Path.routes.rescue = fn;
    },
    'history': {
        'initial':{}, // Empty container for "Initial Popstate" checking variables.
        'pushState': function(state, title, path){
            if(Path.history.supported){
                if(Path.dispatch(path)){
                    history.pushState(state, title, path);
                }
            } else {
                if(Path.history.fallback){
                    window.location.hash = "#" + path;
                }
            }
        },
        'popState': function(event){
            var initialPop = !Path.history.initial.popped && location.href == Path.history.initial.URL;
            Path.history.initial.popped = true;
            if(initialPop) return;
            Path.dispatch(document.location.pathname + document.location.search);
        },
        'listen': function(fallback){
            Path.history.supported = !!(window.history && window.history.pushState);
            Path.history.fallback  = fallback;

            if(Path.history.supported){
                Path.history.initial.popped = ('state' in window.history), Path.history.initial.URL = location.href;
                window.onpopstate = Path.history.popState;
            } else {
                if(Path.history.fallback){
                    for(route in Path.routes.defined){
                        if(route.charAt(0) != "#"){
                          Path.routes.defined["#"+route] = Path.routes.defined[route];
                          Path.routes.defined["#"+route].path = "#"+route;
                        }
                    }
                    Path.listen();
                }
            }
        }
    },
    'match': function (path, parameterize) {
        var params = {}, route = null, possible_routes, slice, i, j, compare, routePath;

        var urlStarts = function(start, full) {
            if (start === full) return true;

            if (full.startsWith(start)) {
                var chrEnds = full.charAt(start.length);
                return chrEnds === '?' || chrEnds === '&';
            }
            return false;
        };

        for (routePath in Path.routes.defined) {
            if (routePath !== null && routePath !== undefined) {
                route = Path.routes.defined[routePath];
                possible_routes = route.partition();
                for (j = 0; j < possible_routes.length; j++) {
                    slice = possible_routes[j];
                    compare = path;
                    params = {};

                    if (slice.search(/:/) > 0 || route.has_params()) {
                        var re = /[\/?=&]/;
                        var slices = slice.split(re);
                        var path_slices = compare.split(re);

                        for (i = 0; i < path_slices.length; i++) {

                            if ((i < slices.length) && (slices[i].charAt(0) === ":")) {
                                // old way for obtaining params, order-strict
                                params[slices[i].replace(/:/, '')] = path_slices[i];
                                compare = compare.replace(path_slices[i], slices[i]);
                            }
                            else {
                                // Extra parameters in addition to specified in pattern
                                if (urlStarts(slice, compare)) {
                                    // Make sure there is a fit below
                                    compare = slice;
                                }
                                // new way for obtaining params, any order
                                if (route.allows_param(path_slices[i])) {
                                    if (i < path_slices.length + 1) {
                                        params[path_slices[i]] = path_slices[i + 1];
                                        i ++;
                                    }
                                }
                            }
                        }
                    }
                    if (slice === compare) {
                        if (parameterize) {
                            route.params = params;
                        }
                        return route;
                    }
                }
            }
        }
        return null;
    },
    'dispatch': function (passed_route) {
        var previous_route, matched_route;
        if (Path.routes.current !== passed_route) {
            Path.routes.previous = Path.routes.current;
            Path.routes.current = passed_route;
            matched_route = Path.match(passed_route, true);

            if (Path.routes.previous) {
                previous_route = Path.match(Path.routes.previous);
                if (previous_route !== null && previous_route.do_exit !== null) {
                    previous_route.do_exit();
                }
            }

            if (matched_route !== null) {
                matched_route.last_matched_path = passed_route;
                matched_route.run();
                return true;
            } else {
                if (Path.routes.rescue !== null) {
                    Path.routes.rescue();
                }
            }
        }
    },
    'listen': function () {
        var fn = function(){ Path.dispatch(location.hash); }

        if (location.hash === "") {
            if (Path.routes.root !== null) {
                location.hash = Path.routes.root;
            }
        }

        // The 'document.documentMode' checks below ensure that PathJS fires the right events
        // even in IE "Quirks Mode".
        if ("onhashchange" in window && (!document.documentMode || document.documentMode >= 8)) {
            window.onhashchange = fn;
        } else {
            setInterval(fn, 50);
        }

        if(location.hash !== "") {
            Path.dispatch(location.hash);
        }
    },
    'core': {
        'route': function (path, url_param_names) {
            this.path = path;
            this.last_matched_path = null;
            this.action = null;
            this.do_enter = [];
            this.do_exit = null;

            this._has_params = url_param_names && url_param_names.length;
            this.allowed_param_names = (url_param_names || []).reduce(function(res, name) {
                res[name] = true;
                return res;
            }, {});

            this.params = {};
            Path.routes.defined[path] = this;
        }
    },
    'routes': {
        'current': null,
        'root': null,
        'rescue': null,
        'previous': null,
        'defined': {}
    }
};
Path.core.route.prototype = {
    'to': function (fn) {
        this.action = fn;
        return this;
    },
    'enter': function (fns) {
        if (fns instanceof Array) {
            this.do_enter = this.do_enter.concat(fns);
        } else {
            this.do_enter.push(fns);
        }
        return this;
    },
    'exit': function (fn) {
        this.do_exit = fn;
        return this;
    },
    'partition': function () {
        var parts = [], options = [], re = /\(([^}]+?)\)/g, text, i;
        while (text = re.exec(this.path)) {
            parts.push(text[1]);
        }
        options.push(this.path.split("(")[0]);
        for (i = 0; i < parts.length; i++) {
            options.push(options[options.length - 1] + parts[i]);
        }
        // console.warn(this.path, options)
        return options;
    },
    allows_param: function(param_name) {
        return this.allowed_param_names[param_name];
    },
    has_params: function() {
        return this._has_params;
    },

    params_decoded: function() {
        var decoded = {};
        for(var key in this.params) {
            if (this.params.hasOwnProperty(key)) {
                var v = this.params[key].replace(/\+/g, ' ');
                decoded[key] = decodeURIComponent(v);
            }
        }
        return decoded;
    },

    'run': function () {
        var halt_execution = false, i, result, previous;

        var that = this;
        if (that.hasOwnProperty("do_enter")) {
            if (that.do_enter.length > 0) {
                for (i = 0; i < that.do_enter.length; i++) {
                    result = that.do_enter[i].apply(this, null);
                    if (result === false) {
                        halt_execution = true;
                        break;
                    }
                }
            }
        }
        if (!halt_execution) {
            that.action();
        }
    }
};

window.Path = Path;