
maxkir.ListLinkExtension = Object.assign({

  // Also see LIST_LINK in TaskLinkExtension
  PLAIN_LIST_RE: /(^|[^'"=(>\w])((https?:\/\/[^/]+)?\/checklists\/(\d+)[-\w]*#?)([^/#\w-\]]|$)/g,
  MARKDOWN_LIST_RE: /\[([^\]]*)]\((?:https?:\/\/[^\/]+)?(?:\/checklists\/(\d+)[-\w]*#?)\)/g,
  OLD_LIST_RE: /\[list:[^|]*\|(\d+)]/g,

  // Map from list_id to list data, for quicker list link rendering
  _page_cache: {},

  process_text: function(s, context) {
    s = this._process_old_list_link(s, context);
    s = this._process_markdown_list_link(s, context);
    s = this._process_plain_list_link(s, context);
    return s
  },

  _process_old_list_link: function(s, context) {
    var item_re = this.OLD_LIST_RE;
    item_re.lastIndex = 0;
    var that = this;
    return s.replace(item_re, function(match, list_id) {
      return that._create_link(context, '', list_id, '', match);
    })
  },

  _process_markdown_list_link: function(s, context) {
    var item_re = this.MARKDOWN_LIST_RE;
    item_re.lastIndex = 0;
    var that = this;
    return s.replace(item_re, function(match, link_text, list_id) {
      return that._create_link(context, '', list_id, '', match, link_text);
    })
  },

  _process_plain_list_link: function(s, context) {
    var item_re = this.PLAIN_LIST_RE;
    item_re.lastIndex = 0;
    var that = this;

    return s.replace(item_re, function(match, charBefore, link, host_part, list_id, after) {
      return host_part ? that._create_link(context, charBefore, list_id, after, match) : match;
    })
  },

  /**
   * @param {RenderContext} context Rendering context
   * @param charBefore Character match before link, if matched
   * @param list_id
   * @param after text to append after the link
   * @param fallbackText returned if list data is not found
   * @param [explicitLinkText] If set, this text is used instead of list name text
   * @return {string}
   * @private
   */
  _create_link: function (context, charBefore, list_id, after, fallbackText, explicitLinkText) {
    var linked_list = this._list_data(list_id, context);

    if (linked_list) {
      var title = maxkir.escapeHtml(linked_list.name);
      var prefix = "<span class='listLink' title='" + title + "'><i class='listLink__icon'></i>";
      var link_text = explicitLinkText ? explicitLinkText : maxkir.CommonRender.format_for_breadcrumbs(linked_list.name);
      link_text = link_text.replace(/\n/g, ' ');

      return charBefore + prefix +
          "<a href='" + maxkir.Integrations.LIST_PREFIX + list_id + "' class='listLink__link'>" + link_text + "</a>" +
          "</span>" + after;
    }
    else if (!this.rerendering()) {
      var renderedTaskId = context.task_id;
      var that = this;
      this._fetch_list_data(list_id, context).then(function() {
        that.without_rerender(function() {
          that._update_task(renderedTaskId, context);
        })
      })
    }
    return fallbackText;
  },

  _list_data: function(list_id, context) {
    if (context.is_pwa) {
      return mxList.store[list_id] || this._page_cache[list_id];
    }
    return maxkir.Lists.data(list_id);
  },

  _fetch_list_data: function(list_id, context) {
    if (context.is_pwa) {
      return mxList.findOne({id: list_id}).then((list_data) => {
        this._page_cache[list_id] = list_data;
        return list_data;
      });
    }
    return maxkir.Lists.fetch_lists_from_server_bulk([list_id]);
  },

  resolve_to_text: function(url) {
    var re = this.PLAIN_LIST_RE;
    return new Promise(function (resolve, reject) {
      re.lastIndex = 0;
      var match = re.exec(url);
      if (match) {
        var list_id = match[4];
        resolve(maxkir.Lists.get_data(list_id).then(function() {
          var data = maxkir.Lists.data(list_id);
          if (data) {
            return maxkir.CommonRender.format_for_breadcrumbs(data.name, true);
          }
          else {
            reject()
          }
        }))
      }
      else {
        reject()
      }
    })
  },



}, maxkir.BaseRenderExtension);

maxkir.RenderExtensions.add_renderer(maxkir.ListLinkExtension, true)