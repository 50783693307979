
/**
 * @param {HTMLFormElement} el
 * @constructor
 */
maxkir.ValueKeeper = function(el) {
    var id = el.getAttribute('id');

    if (!el.value) {
        el.value = localStorage.getItem("saved_content_" + id) || "";
    }

    var save_value = function save_value_in_localStorage() {
        var val = el.value || null; 
        if (val) {
            localStorage.setItem("saved_content_" + id, val);
        }
        else {
            localStorage.removeItem("saved_content_" + id);
        }
    };

    el.addEventListener("input", save_value);
    el.addEventListener("change", save_value);
    
    this.clear_value = function() {
        el.value = '';
        save_value();
    };

    this.dispose = function() {
        el.removeEventListener("input", save_value);
        el.removeEventListener("change", save_value);
    }
};
