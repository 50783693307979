define(function (require, exports, module) {

    var app = require('app');


    /**
     * On iOS, position: fixed is very unreliable, see https://github.com/jquery/jquery-mobile/issues/4113
     * @class PositionFixedHider
     * */
    var PositionFixedHider = {
        _cssToHide: ".positionFixed",

        /**
         * Hide visible elements with positionFixed class
         */
        disableFixedPosition: function(filtering) {
            
            app.debug('PositionFixedHider.disableFixedPosition');
            if (this._active) {
                app.debug('PositionFixedHider.disableFixedPosition - already disabled');
                return;
            }
            this._active = true;
            
            $('.bottomPanel,.bottomMenu').hide();
            
            if (!this.is_ios()) {
                return;
            }

            var prevElements = this.elements || $(),
                prevDom = [];

            // Collect previously absolutized elements
            prevElements.each(function (){
                prevDom.push(this);
            });

            var that = this;
            that.elements = $(this._cssToHide).filter(":visible");
            that.elements.each(function() {
                // We should NOT re-show this element later:
                prevDom.remove(this);
                // Absolutize:
                $(this).addClass("positionAbsolute");
            })

            // These elements should be made non absolute
            prevDom.forEach(function(el) {
                $(el).removeClass("positionAbsolute");
            })

            $('.filter_wrapper').addClass('positionAbsolute');

            if (filtering) {
                this._hidden = $('.topBar:visible,.loadingLists:visible'); 
                this._hidden.hide();

                $('.filter_wrapper').css({top: window.pageYOffset + 'px'})
                
                $('.mainContainer').addClass('withFilterNoHeader');
                
                if (!this._action) {
                    this._action = maxkir.delay_action(function() {
                        $('.filter_wrapper').css({top: window.pageYOffset + 'px'})
                    }, 50);
                    
                    $(window).on('gesturechange.hider', function() {});
                    $(window).on('scroll.hider', function() {
                        this._action.start();
                    }.bind(this));
                    
                    // To fix a glitch when search field sticks at the middle of the screen
                    setTimeout(function() {
                        window.scrollTo(window.pageXOffset, window.pageYOffset + 1);
                    }, 1);
                }
            }
        },
        
        is_ios: function() {
            return maxkir.iPad && maxkir.Safari;
            // return true;
        },

        doShow: function() {
            $('.bottomPanel,.bottomMenu').show();
            if (!this.is_ios()) {
                return;
            }

            if (this._hidden) {
                this._hidden.show();
            }

            $('.mainContainer').removeClass('withFilterNoHeader');
            
            $('.filter_wrapper').removeClass('positionAbsolute');
            $('.filter_wrapper').css({top: ''});
            $(window).off('scroll.hider');
            if (this._action) {
                this._action.stop();
                this._action = null;
            }

            if (this.elements) {
                this.elements.removeClass("positionAbsolute");
            }
        },

        /**
         * Show previously hidden elements
         */
        unfixAndHideKeyboard: function() {
            app.debug('PositionFixedHider.unfixAndHideKeyboard');
            if (!this._active) {
                app.debug('PositionFixedHider.unfixAndHideKeyboard - not active');
                return;
            }
            this._active = false;
            this.doShow();

            if (this.elements) {
                this.hideKeyboard();
                this.elements = null;
            }
        },

        hideKeyboard: function() {
            if (app.is_testing())
                return;

            var scrollTop = $(window).scrollTop();

            if (document.activeElement) {
                // Trying to fix broken position:fixed after hidden keyboard
                $(document.activeElement).one('blur', function() {
                    setTimeout(function() {
                        window.scrollTo(document.body.scrollLeft, scrollTop);
                    }, 1);
                });

                document.activeElement.blur();
            }

        }

    };

    module.exports = PositionFixedHider;
});