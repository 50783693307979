
let saved_event = null;
let saved_callback = null;

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault(); // Prevents prompt display
    saved_event = e;
    if (saved_callback) {
        saved_callback();
    }
});

export default {
    add_callback: function(f) {
        if (saved_event) {
            f();
        }
        saved_callback = f;
    },

    request_add: function() {
        saved_event && saved_event.prompt();
    }
}