// Depends on core.js

/**
 * @constructor maxkir.Listeners
 */
maxkir.Listeners = function () {
  this._listeners = [];
};

Object.assign(maxkir.Listeners.prototype, {
  add_listener: function (l, prepend) {
    "use strict";
    if (l) {
      this.remove_listener(l);
      if (prepend) {
        this._listeners.unshift(l);
      }
      else {
        this._listeners.push(l);
      }
    }
  },

  remove_listener: function (l) {
    "use strict";
    if (l) {
      this._listeners = this._listeners.filter(function (i) {
        return i !== l
      });
    }
  },

  notify_listeners_as_function: function(l_param1, l_param2, l_param3) {
    "use strict";
    this._listeners.forEach(function (l) {
      if (typeof l === 'function') {
        l(l_param1, l_param2, l_param3);
      }
    });
  },

  notify_listener: function (listener_method_name, l_param1, l_param2, l_param3) {
    "use strict";
    this._listeners.forEach(function (l) {
      if (typeof l[listener_method_name] === 'function') {
        l[listener_method_name](l_param1, l_param2, l_param3);
      }
    });
  },

  get_listeners: function () {
    return this._listeners;
  },

  dispose: function () {
    this._listeners = [];
  }
});


maxkir.DisposeSupport = function () {
  var _pubsub = maxkir.PubSub();
  return {
    push: function(l) {
      _pubsub.subscribe("dispose", l)
    },

    dispose: function() {
      _pubsub.publish("dispose")
      _pubsub.dispose();
    }
  }
};

/**
 * Publish/Subscribe pattern
 * @return {{subscribe: (function(*, *=): {unsubscribe: function(): void}), publish: publish}}
 * @constructor
 */
maxkir.PubSub = function () {
  // object which will track of all events and subscription
  var subscribers = {};

  function dispose() {
    subscribers = {};
  }

  // Publisher:
  function publish(eventName, data) {
    // return if event is not subscribed
    if (!Array.isArray(subscribers[eventName])) {
      return
    }

    // Whenever you publish any event, it will trigger callback for all stored event in subscriber object
    subscribers[eventName].forEach(function (callback) {
      callback(data)
    })
  }

  // Subscriber
  function subscribe(eventName, callback) {

    subscribers[eventName] = subscribers[eventName] || []
    subscribers[eventName].push(callback);
    var index = subscribers[eventName].length - 1

    // subscribed callbacks to be removed when they are no longer necessary.
    return {
      unsubscribe: function () {
        subscribers[eventName].splice(index, 1);
      },
    }
  }

  return {
    publish: publish,
    subscribe: subscribe,
    dispose: dispose,
  }
};