var DateExtension = function(Date) {

  var DAY = 1000 * 24 * 3600;
  var WEEK = 7 * DAY;
  Date.MD = ['Jan','Feb','Mar','Apr', 'May','Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];
  Date.WD = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  Date.prototype.cl_format_date = function(show_today, enforce_year, enforce_weekday) {

    if (this.is_today()) {
      if (show_today) return "today";
      return "";
    }
    if (this.is_tomorrow()) {
      return "tomorrow";
    }
    if (this.is_yesterday()) {
      return "yesterday";
    }

    var now = new Date();

    var show_weekday = enforce_weekday || ( Math.abs(this.getTime() - now.getTime()) < DAY * 31);
    return this.cl_date(enforce_year, show_weekday && enforce_weekday !== 'skip');
  };

  Date.prototype.cl_date = function(enforce_year, show_weekday) {
    var weekday = "";
    if (show_weekday) {
      weekday = this.weekday() + ", ";
    }

    if (enforce_year || new Date().getFullYear() !== this.getFullYear()) {
      return weekday + this.cl_date_full();
    }
    else {
      return weekday + Date.MD[this.getMonth()] + " " + this.getDate();
    }
  }

  Date.cl_set_date_format = function(format) {
    if (format === 'iso') {
      Date.prototype.cl_date_full = function() {
        var pad = function(n) { return n < 10 ? '0' + n : n };
        return this.getFullYear() + "-" + pad(this.getMonth() + 1) + "-" + pad(this.getDate());
      }
    }
    else {
      Date.prototype.cl_date_full = function() {
        return Date.MD[this.getMonth()] + " " + this.getDate() + ", " + this.getFullYear();
      }
    }
  }

  Date.cl_set_date_format('default');


  Date.prototype.cl_format_time = function() {
    var res = this.cl_format_date(true);

    if (Math.abs(this.getTime() - new Date().getTime()) < 2*DAY + 100) {
      res += ", " + this.cl_time();
    }
    return res;
  };

  Date.prototype.cl_time = function() {
    var pad = function(val) {
      return val > 9 ? val : ("0" + val)
    };
    return pad(this.getHours()) + ":" + pad(this.getMinutes());
  };

  Date.prototype.diff_days = function() {
    var today = new Date().beginning_of_day();
    var that_day = new Date(this.getTime()).beginning_of_day();
    return Math.round((that_day.getTime() - today.getTime())/DAY);
  };

  Date.prototype.is_today = function() {
    return this._same_date(new Date());
  };
  Date.prototype.is_tomorrow = function() {
    var y = new Date(new Date().getTime() + DAY);
    return this._same_date(y);
  };
  Date.prototype.is_after_tomorrow = function() {
    return this.beginning_of_day().getTime() - new Date().beginning_of_day().getTime() > DAY;
  };
  Date.prototype.is_yesterday = function() {
    var y = new Date(new Date().getTime() - DAY);
    return this._same_date(y);
  };
  Date.prototype.is_overdue = function() {
    return new Date().beginning_of_day().getTime() > this.getTime();
  };
  Date.prototype.weekday = function() {
    return Date.WD[this.getDay()];
  };

  Date.prototype._same_date = function(y) {
    return y.getDate() == this.getDate() && Math.abs(y.getTime() - this.getTime()) <= DAY;
  };

  Date.prototype.beginning_of_day = function() {
    this.setHours(0, 0, 0, 0);
    return this;
  };
  Date.prototype.end_of_day = function() {
    this.setHours(23, 59, 59, 999);
    return this;
  };

// Our week starts with monday!
  Date.prototype.beginning_of_week = function() {
    if (this.getDay() == 0) {
      this.setDate(this.getDate() - 6);
    }
    else {
      this.setDate(this.getDate() - this.getDay() + 1);
    }
    return this.beginning_of_day();
  };

  Date.prototype.end_of_week = function() {
    this.setDate(this.getDate() + 7);
    var beg_of_next = this.beginning_of_week();
    return new Date(beg_of_next.getTime() - 1);
  };

  Date.prototype.beginning_of_month = function() {
    this.setDate(1);
    return this.beginning_of_day();
  };

  Date.prototype.end_of_month = function() {
    this.setDate(1);
    this.setMonth(this.getMonth() + 1);
    return new Date(this.beginning_of_month().getTime() - 1);
  };

  Date.prototype.beginning_of_year = function() {
    this.setDate(1);
    this.setMonth(0);
    return this.beginning_of_month();
  };

  Date.prototype.end_of_year = function() {
    this.setDate(1);
    this.setMonth(11);
    return this.end_of_month();
  };


};

DateExtension(Date);


