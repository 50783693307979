// import {html, render} from 'lit-html';

import app from 'app';
import $ from 'jquery';
import ClickHandler from 'app/controls/click_handler';

const can = app.can;

import ProgressIndicatorControl from "app/controls/progress_indicator";
import SlidingPanel from "app/controls/sliding_panel";
import CommandQueue from "app/command_queue";
import darcula_mode from "darcula_mode";

import noty from 'lib/jquery.noty.packaged';

import initVisibilityHandler from 'app/ios_visibility'

import install_home_screen from "install_home_screen";

initVisibilityHandler();

/**
 * Collects all active Ajax requests and terminates them when user navigates between pages
 *
 * @class AjaxMonitor
 */
const AjaxMonitor = {
    _active_ajax: [],

    init: function () {
        const active_ajax = this._active_ajax;
        $(document).on("ajaxSend.monitor ajaxComplete.monitor", function (event, jXhr) {
            if (event.type === "ajaxSend" && !jXhr._no_abort_on_navigation) {
                active_ajax.push(jXhr);
            }
            else {
                const idx = active_ajax.indexOf(jXhr);
                if (idx >= 0) {
                    active_ajax.splice(idx, 1);
                }
            }
        })
    },

    destroy_and_stop_ajax: function () {
        $(document).off("ajaxSend.monitor ajaxComplete.monitor");
        this._active_ajax.forEach(function (jXhr) {
            try {
                app.debug("Aborting AJAX request", jXhr);
                jXhr.abort();
            }
            catch (e) {
                app.error(e)
            }
        });
        this._active_ajax = [];
    }
};


let pageActive;
/**
 // Expected options:
 //  - page_title
 //  - template - path under /ejs
 * @class Page
 */
const Page = can.Control.extend(
    {
        defaults: {
            template: "",
            page_title: "Checkvist"
        }
    },
    {
        init: function (element, options) {
            if (pageActive) {
                throw ">>>>>> Page already active: " + pageActive;
            }
            else {
                app.info(">>>>>> Open page " + this.options.page_title);
            }

            // Add event name for click events
            this.click_handler = app.util.create_click_processor();
            this.element.data("currentPage", this);

            this.clicker = new ClickHandler(this.element);

            const that = this;
            darcula_mode.enable_darcula_if_needed();
            this.render_page().then(function () {
                if (that.element.find(".fullHeightMarker").length) {
                    $('main.container').addClass('fullHeight');
                }
            });

            CommandQueue.load_and_run();
            AjaxMonitor.init();

            this.add_page_click_handler(".cancel_back", "cancel_back", function() {
                that.goBack();
            })
        },

        goBack: function() {
            cv_route.back();
        },

        onRender: function () {

        },

        refresh_options: function (options) {
            const template = this.options.template;
            const title = this.options.page_title;

            this.options = options;
            this.options.template = template;
            this.options.page_title = title;

            app.info("Refresh options for " + this.options.page_title + ": " + JSON.stringify(this.options));
        },

        ".signOutLink click": function (element, e) {
            app.current_user.logout();
            Path.dispatch("/");
            darcula_mode.set_darcula(false, true);
        },

        ".simpleLink click": "_link_click",
        ".simpleLink touchEnd": "_link_click",

        _link_click: function (element, e) {
            const href = element.attr('href') || element.attr('data-href');
            if (!this.click_handler) {
                app.warn("Page is already disposed, click on " + href + " is ignored");
            }
            this.click_handler('simpleLinkClick' + href, e, function () {
                cv_route.goTo(href);
            });
        },

        set_title: function (title) {
            document.title = title;
            pageActive = title + " (" + this.options.template + ")";
        },

        render_page: function () {
            app.debug("render_page " + this.constructor.fullName);

            const that = this;
            return new Promise(function (resolve, reject) {

                if (that.options.template.endsWith('.lit')) {
                    $.ajax(app.config.url_prefix + '/ejs/' + that.options.template).then(function (response) {
                        console.warn(response);
                    })
                }
                else {
                    app.view_template(that.options.template, that.get_model(), function (result) {
                        if (that.destroyed) {
                            app.debug("Skip rendering page " + pageActive + " - already disposed");
                            return;
                        }

                        that.element.html(result);
                        that.set_title(that.options.page_title);
                        app.debug("Rendered page " + pageActive + " with height " + that.element.height());

                        if (!that.skip_progress_icon() && $('header.topBar').length) {
                            that._progress_indicator = new ProgressIndicatorControl($('header.topBar'));
                        }
                        that.onRender();
                        resolve();
                    })

                }

            }).catch(function (e) {
                if (window.QUnit) {
                    window.QUnit.start();
                }
                throw e;
            });
        },

        skip_progress_icon: function () {
            return false;
        },

        get_model: function () {
            return {};
        },

        install_header_menu: function () {
            this.header_menu = new SlidingPanel('.headerMenu', {
                handler: '.fa-cogs',
                on_show: function () {
                    var el = document.querySelector(".topBar .fa-cogs");
                    el.style.fontWeight = 900;
                },
                on_hide: function () {
                    var el = document.querySelector(".topBar .fa-cogs");
                    el.style.fontWeight = 300;
                },

            });
        },

        hide_header_menu: function (timeout) {
            if (timeout > 0) {
                const that = this;
                // Hide header after the change
                setTimeout(function () {
                    if (that.header_menu) {
                        that.header_menu.hide();
                    }
                }, timeout);
            }
            else {
                this.header_menu.hide();
            }
        },

        restore_scroll_position: function () {
            app.util.smooth_scroll(this.constructor[this.scroll_key()]);
        },

        default_back_url: function () {
            return "/app/index.html";
        },

        /**
         * @param {String} cssSelector to listen click on
         * @param {String} identity for debug and identity
         * @param {Function} handler has a param - event
         */
        add_page_click_handler: function(cssSelector, identity, handler) {
            this.clicker.add_click_handler(cssSelector, identity, handler);
        },

        _init_quick_add: function () {
            this.add_page_click_handler(".quickAdd", "quick_add", function(event, element) {
                cv_route.goTo('/app/bookmarklet.html', '/app/index.html');
            });
        },

        destroy: function () {
            this.destroyed = true;
            if (pageActive) {
                app.debug("Destroying page: " + this.options.page_title);
                pageActive = false;

                this.off();
                this.clicker.dispose();

                if (this._progress_indicator) {
                    this._progress_indicator.destroy();
                    this._progress_indicator = null;
                }

                // Remember last scrolling position:
                this.constructor[this.scroll_key()] = $('body').scrollTop();

                AjaxMonitor.destroy_and_stop_ajax();

                $('main.container').removeClass('fullHeight');
                this.element.removeData("currentPage");
                can.Control.prototype.destroy.call(this);
                this.header_menu = null;

                this.click_handler = null;


                app.debug("Page destroyed: " + this.options.page_title);
            }
            app.info("<<<<<< Left page " + this.options.page_title);

            // Looks like this causes hangs under iOS:
            //app.storage.close();
        },

        scroll_key: function () {
            return '_saved_scroll';
        },

        _check_for_https: function () {

            if (document.location.protocol == 'http:' && document.location.hostname != 'localhost' && !app.is_testing()) {
                // Increase the width of the message container
                $.extend($.noty.layouts.topCenter, {
                    container: {
                        object: '<ul id="noty1_topCenter_layout_container" />',
                        selector: 'ul#noty1_topCenter_layout_container',
                        style: function () {
                            $(this).css({
                                top: 20,
                                left: 0,
                                position: 'fixed',
                                width: '470px',
                                maxWidth: '95vw',
                                height: 'auto',
                                margin: 0,
                                padding: 0,
                                listStyleType: 'none',
                                zIndex: 10000000
                            });

                            $(this).css({
                                left: ($(window).width() - $(this).outerWidth(false)) / 2 + 'px'
                            });
                        }
                    },
                    css: {
                        display: 'none',
                        width: '470px',
                        maxWidth: '95vw'
                    }
                });

                noty({
                    text: "<p><b>Important</b></p>" +
                    "<p>Checkvist Mobile is now secured by HTTPS protocol.</p>" +
                    "<p>Please <b>delete</b> this version, and install the new one: https://m.checkvist.com</p>",
                    layout: 'topCenter',
                    theme: 'relax',
                    type: 'checkvist',
                    animation: {
                        open: "animated fadeIn",
                        close: "animated fadeOut"
                    },
                    modal: true,
                    callback: {
                        onShow: function ($noty) {
                            this.$buttons.css({
                                textAlign: 'center'
                            });
                        }
                    },
                    buttons: [
                        {
                            addClass: 'btn buttonPrimary', text: "Open the new version", onClick: function ($noty) {

                                // this = button element
                                // $noty = $noty element
                                window.open('https://m.checkvist.com');
                                $noty.close();
                            }
                        },
                        {
                            addClass: 'btn buttonLight', text: "Do it later", onClick: function ($noty) {
                                $noty.close();
                            }
                        }
                    ]
                });
            }

        }
    });

export default Page;

