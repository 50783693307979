import Page from "app/pages/page";
import $ from "jquery";
import add_page_header from "../controls/add_page_header";

import PositionFixedHider from "app/controls/position_fixed_hider";


/**
 * Base class for AddListPage, BookmarkletPage, AddNotePage
 *
 * @class BaseAddPage
 */
const BaseAddPage = Page.extend('BaseAddPage', {},
        {
            onRender: function () {
                Page.prototype.onRender.apply(this, arguments);

                add_page_header(this.get_add_button_code());
            },

            // Do not show progress offline icon on this page
            skip_progress_icon: function () {
                return true;
            },

            ".addNote focus": "on_note_focus",
            ".addNote blur": "on_note_blur",

            on_note_focus: function() {
                PositionFixedHider.disableFixedPosition();
            },

            // Trying to cure header fixed position
            on_note_blur: function () {
                PositionFixedHider.unfixAndHideKeyboard();
            },

            ".addPageHeader__submit click": "_submit_handler",
            ".addPageHeader__submit touchstart": "_submit_handler",

            _submit_handler: function (el, event) {
                this.click_handler('submit', event, this.do_submit.bind(this));
            },

            do_submit: function () {
                if ($('.addPageHeader__submit')[0].disabled) {
                    return;
                }

                if (this.get_main_field_value().trim() !== "") {
                    $('.addPageHeader__submit')[0].disabled = true;

                    this.create_and_run_command();
                }
                else {
                    this.on_empty_submit();
                }
            },

            get_add_button_code: function() {
                alert("todo: get_add_button_code");
            },

            get_main_field_value: function() {
                alert("todo: get_main_field_value");
            },

            create_and_run_command: function() {
                alert("todo: create_and_run_command");
            },

            on_empty_submit: function() {
                // Actions on attempt to submit a field with empty form
            }

        });


export default BaseAddPage;
