define(function (require, exports, module) {

    var $ = require('jquery');

    /**
     * @class Messages
     */
    module.exports = {
        show_error: function(txt, infoStyle) {
            $(".errorMessage__text").html(txt);
            setTimeout(() => {
                const errMsg = $(".errorMessage");
                errMsg.addClass("errorMessage--visible");
                if (infoStyle) {
                    errMsg.addClass("errorMessage--info");
                }
            }, 30)
        },

        hide_error: function() {
            $(".errorMessage__text").html('');
            setTimeout(() => {
                const errMsg = $(".errorMessage");
                errMsg.removeClass("errorMessage--visible");
                errMsg.removeClass("errorMessage--info");
            }, 30)
        },

        hide_single_page_message: function() {
            $('.singlePageMessage').remove();
        },
        
        show_single_page_message: function(msg) {
            this.hide_single_page_message();
            $('section.main').append("<section class='singlePageMessage'>" + msg + "</section>");
        },

        single_page_message_shown: function() {
            return $('section.singlePageMessage').length;
        },

        /**
         * @param containerSelector CSS selector for container which should get content or we'll put loading message
         * into it
         * @param message to show when there are no content within 500ms
         * @param {Deferred} [deferred] optional deferred for a case when we want to re-show loading message based on existing resolved deferred 
         * @return Deferred which is resolved is message is shown, and rejected if message was not shown
         */
        loading_with_timeout: function(containerSelector, message, deferred) {
            var result = deferred || $.Deferred();
            var that = this;
            var should_show_progress_message = function() {
                var container = $(containerSelector);

                // return true;
                return  container.length == 1 && container.children().size() == 0 && !that.single_page_message_shown();
            };
            
            setTimeout(function() {
                if (should_show_progress_message()) {
                    result.resolve();
                }
                else {
                    result.reject();
                }
            }, 500);
            
            var that = this;
            
            result.then(function() {
                if (should_show_progress_message()) {
                    if (result._second_time) {
                        that.show_single_page_message("<span class='loading'>" + message + "</span>");
                    }
                    else {
                        that.show_single_page_message("<span class='loading' style='opacity:0'>" + message + "</span>");
                        
                        var el = $('.loading');
                        result._second_time = true;
                        maxkir.animate(300, function(p) {
                            el.css('opacity', p);
                            if (p == 1) {
                            }
                        })
                    }
                }
            });
            
            return result;
        }
    };
});