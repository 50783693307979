// !!!!! check integrations2.js.erb
maxkir.Integrations = {
  LNKREG: /\[(\w+): *(.+?)\|\s*([-\w\/#]+|(([\w-]+:|\/)[^\]]+?))]/g,
  EMBEDREG: /\[(\w+): ([\/\w\\?=&-]+)]/g,
  LIST_PREFIX: "/checklists/",

  init_base: function(list_prefix) {
    this.LIST_PREFIX = list_prefix;
    this.enable_icons(true);
  },

  enable_icons: function(icons_enabled) {
    this.disable_icons = !icons_enabled;
  },

  /**
   * For maxkir.RenderExtensions:
   * @param text
   * @param {RenderContext} render_context
   */
  process_text: function(text, render_context) {
    return this.process_links(text);
  },

  process_links: function (text) {
    text = text.replace(/\[(\d*)]/g, "#_CV_#BR$1##");
    return text
      .replace(this.EMBEDREG, this._extension_embed_replacer.bind(this))
      .replace(this.LNKREG, this._extension_link_replacer.bind(this))
      .replace(/#_CV_#BR(\d*)##/g, "[$1]");
  },

  /**
   * Process [link: some text|some id] and similar links
   *
   * [zendesk: ##ID##|FULL_URL] ##TITLE##
   *
   * @param str
   * @param type could be "link", "list", "gmail", "jira", "youtrack", "github", "zendesk"
   * @param text part (some text)
   * @param id some id, after | part of the link
   * @returns {string}
   * @private
   */
  _extension_link_replacer: function(str, type, text, id) {
    switch(type) {
      case "link": return "<A href=\""+ id+ "\">" + text + "</A>";
      case "gmail":
        if (id.indexOf('#') == -1) {
          // Old-style link, default account
          id = '0/#all/' + id;
        }
        return this.favicon(type) + "<A href=\"https://mail.google.com/mail/u/"+ id+ "\">" + text + "</A>";
      case "list": return "<span class='listLink'><i class='fal fa-link'></i><A href=\"" + this.LIST_PREFIX + id+ "\" class='listLink__link'>" + text + "</A></span>";
      case "github":
      case "gitlab":
      case "jira":
      case "space":
      case "youtrack":
      case "zendesk":
        return this.favicon(type) + "<A href=\""+ id+ "\">" + text + "</A>";
    }
    return str;
  },

  /**
   * Process [youtube: some id] and similar items, for embeds
   *
   * [youtube: ##ID##]
   *
   * @param str
   * @param type could be "youtube"
   * @param id corresponding ID
   * @returns {string}
   * @private
   */
  _extension_embed_replacer: function(str, type, id) {
    switch(type) {
      case "youtube": return this._youtube(id);
    }
    return str;
  },


  _youtube: function(id) {
    var params = id.replace(/&t=/, "&start=").replace(/&list=[\w-]+/, "");
    return `<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${params}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
  },


  /**
   * @param iconName could be "list", "gmail", "jira", "youtrack", "github"
   * @returns {string}
   */
  favicon: function(iconName) {
    if (this.disable_icons) {
      return '';
    }
    // This code is used both on mobile and main app! Change accordingly

    if (iconName === 'list') {
      iconName = 'list_2'
    }

    var srcset = '/assets/favicons/' + iconName + '_32.png 1.5x,/assets/favicons/' + iconName + '_16.png 1x';
    return '<img src="/assets/favicons/' + iconName + "_32.png\" srcset='" + srcset +
        "' style='border-radius:1px; width: 16px; height: 16px; border: none; vertical-align: text-bottom; margin-right: .3em;padding-bottom:1px;'/>";
  },

  f_:null
};
maxkir.RenderExtensions.add_renderer(maxkir.Integrations);

