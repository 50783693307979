define(function (require, exports, module) {

    var app = require('app');
    var CachingModel = require('app/model/caching_model');
    var ListState = require('app/model/list_state');

    /**
     * @typedef {Object} Note
     * @property {number} Note.id
     * @property {string} Note.comment
     * @property {string} Note.username
     * @property {number} Note.user_id
     * @property {number} Note.updated_at
     */

    /**
     * @class Task
     * @property {String|number} id
     * @property {String|number} parent_id
     * @property {String|number} checklist_id
     * @property {String} [uuid]
     *
     * @property {String} content
     * @property {String} [mark]  - color mark
     * @property {number} [childrenCount]  - number of children of the task
     * @property {String|null} due
     * @property {Array<number>} [due_user_ids]
     * @property {Array<number>} [assignee_ids]
     *
     * @property {boolean} [is_local]
     * @property {number} position
     * @property {number} status
     * @property {Object} [details]
     * @property {String} [details.has_repeating]
     *
     * @property {Object} tags Map from tagName to "isPrivate" status
     * @property {number} updated_at msecs
     *
     */
    var Task = app.Task = CachingModel.extend("mxTask", {

        create_local_task: function(list_id, content) {
            var temp_task_id = maxkir.uuid();
            return new Task({
                is_local: true,
                id: temp_task_id,
                parent_id: 0,
                position: 1,
                status: 0,
                uuid: temp_task_id,
                checklist_id: CachingModel.fix_id(list_id),
                content: content,
                updated_at: new Date().getTime()
            });
        },

         /**
         * Synchronization key for findXXX operations (they are expected to be executed sequentially on the server side
         * @param params
         * @return {*}
         */
        sync_key: function(params) {
            if (params.checklist_id) {
                return 'checklist_id_' + params.checklist_id;
            }
            return this.get_table_name();
        },

        /**
         * @callback TaskCallback
         * @param {Task} task - task being processed
         */

         /**
         * Change position of the list items to a given increment
         * @param list_id {number}
         * @param parent_id {String}
         * @param position {number} position is changed for all items which have position >= given one
         * @param increment {number} value to increment/decrement position to
         * @param id_to_ignore Task with this ID won't be updated
         * @param {TaskCallback} [before_save_callback]
         * @return {Deferred}
         */
        update_positions: function(list_id, parent_id, position, increment, id_to_ignore, before_save_callback) {
            var self = this,
                logUUID = "pos_" + self._logUUID();

            var to_run = [];
            parent_id = CachingModel.fix_id(parent_id); 

            var params = {
                logUUID: logUUID,
                skip_server: true,
                index_name: parent_id ? "parent_id" : "checklist_id",
                parent_id: parent_id || 0
            };
            if (!parent_id) {
                params['checklist_id'] = CachingModel.fix_id(list_id);
            }

            self.db_log(logUUID + "Updating position of tasks " + JSON.stringify(params) + " starting from " + position + " to " + increment);
            var updated = false;

            
            /*
             to_run.push(Task.findAll(params).then(function(tasks) {

             var change_position = function change_position(t) {
             if (t && t.position >= position && t.id !== id_to_ignore) {
             updated = true;
             self.db_log(logUUID + "Changing position of task " + t.id + " from " + t.position + " to " + (t.position + increment));
             t.attr("position", t.position + increment);
             if (before_save_callback) {
             before_save_callback(t);
             }
             return t.save();
             }
             return Promise.resolve();
             };

             var promise = Promise.resolve();

             if (increment > 0) {
             for(var i = tasks.length - 1; i >= 0; i --) {
             promise = promise.then(change_position(tasks[i]));
             }
             }
             else {
             for(var i = 0; i <= tasks.length; i++) {
             promise = promise.then(change_position(tasks[i]));
             }
             }
             return promise;
             }))
            */
            
            
            
            to_run.push(app.storage.open_db().then(function (server){

                var toExecute = Task.build_query_several_records(server, params, logUUID);
                toExecute.modify({
                    position: function(t) {
                        var new_position = t.position;
                        if (t.position >= position && t.id !== id_to_ignore) {
                            updated = true;
                            new_position = t.position + increment;
                        }
                        
                        if (t.position != new_position) {
                            self.db_log(logUUID + "Changing position of task " + t.id + " from " + t.position + " to " + new_position);
                            Task.findLocal(t.id).then(function(tt) {
                                tt.attr('position', new_position);
                                if (before_save_callback) {
                                    before_save_callback(tt);
                                }
                            })
                        }
                        return new_position;
                    }
                });

                to_run.push(toExecute.execute());

            }));

            var result = app.run_all(to_run);
             result.then(function () {
                     self.db_log(logUUID + (updated ? "Done changing task positions" : "No need for position update"));
                 }, function () {
                     self.db_log(logUUID + "FAIL changing task positions");
                 }
             );
            return  result;
        },
        
        findDue: function() {
            return Task.findAll({with_due: true, only_update_from_server: true});
        },

        get_table_name: function() {
            return "tasks";
        },

        touch_list_has_data: function(list_id) {
            if (!this.has_data_in_db({checklist_id: list_id})) {
                this._touch_db_cache_mark({checklist_id: list_id}, 1, 'touch_list_has_data ');
            }
        },

        _reopen_parent_if_needed: function(id, attrs) {

            if (attrs.parent_id > 0) {

                var res = $.Deferred();
                Task.findLocal(id).then(function(task) {
                    if (task.open()) {
                        Task.findLocal(attrs.parent_id).then(function(parent) {
                            if (!parent.open()) {
                                parent.attr({status: 0});
                                parent.save().then(res.resolve, res.reject);
                            }
                            else {
                                res.resolve();
                            }
                        }, res.resolve); // Skip updating parent when cannot find it
                    }
                    else {
                        res.resolve();
                    }
                }, res.resolve); // Ignore tasks we cannot find
                return res;
            }

            return $.Deferred().resolve();
        },

        /**
         * Return a deferred which resolves to a JSON array of records for this model
         * @param params findAll parameters
         * @returns {Promise}
         */
        get_load_data_deferred: function(params) {

            if (params.id) {
                app.debug("So far cannot load data for a single task: " + params.id + ", rejecting");
                return Promise.reject(new Error('Cannot load data for a single task yet'));
            } else {
                if (params.checklist_id) {
                    var that = this;
                    app.debug("Start loading tasks from server for list " + params.checklist_id);
                    return this._get_list_ajax(params.checklist_id).then(function (xml) {
                        return that._create_tasks_from_xml(xml, params.checklist_id);
                    });
                }
                else if (params.with_due) {
                    return this._get_due_ajax();
                }
            }
            app.error("Neither list ID nor with_due are is not specified: " + JSON.stringify(params));
            return Promise.reject();
        },

        _create_tasks_from_xml: function(xml, list_id) {
            var data = [],
                positions = {},
                processed = {},
                that = this;

            $(xml).find('body > outline').each(function(idx, outline) {
                that._process_outline_node(data, outline, 0, list_id, positions, processed);
            });
            return data;
        },

        _get_list_ajax: function(list_id) {
            return $.ajax({
                dataType: 'xml',
                type: "get",
                url: app.config.api_url + "/checklists/" + list_id + ".opml",
                data: {
                    token: app.current_user.attr("token"),
                    export_id: true,
                    export_status: true,
                    export_notes: true,
                    export_details: true,
                    export_color: true
                }
            });
        },
        
        _get_due_ajax: function() {
            return $.ajax({
                dataType: "json",
                type: "get",
                url: app.config.api_url + "/checklists/due.json",
                data: {
                    token: app.current_user.attr("token"),
                    with_notes: true
                }
            });
        },
        
        parseModel: function(attrs) {
            if (!attrs) return attrs;

            if (attrs.updated_at) {
                attrs.updated_at = new Date(attrs.updated_at).getTime();
            }
            if (attrs.details) {
                if (attrs.details.mark) {
                    var colorMark = null;
                    switch (attrs.details.mark) {
                        case 'fg1': colorMark = 1; break;
                        case 'fg2': colorMark = 2; break;
                        case 'fg3': colorMark = 3; break;
                        case 'bg1': colorMark = 4; break;
                        case 'bg2': colorMark = 5; break;
                        case 'bg3': colorMark = 6; break;
                        case 'bg7': colorMark = 7; break;
                        case 'bg8': colorMark = 8; break;
                        case 'bg9': colorMark = 9; break;
                    }
                    attrs.mark = colorMark;
                }
                var has_repeating = attrs.details.has_repeating;
                
                delete attrs['details'];
                
                if (has_repeating) {
                    attrs.details = {has_repeating: has_repeating};
                }
            }

            if (attrs['tasks']) {
                attrs.childrenCount = attrs['tasks'].length;
            }
            
            delete attrs['color'];
            delete attrs['tags_as_text'];
            delete attrs['comments_count'];
            delete attrs['collapsed'];
            delete attrs['update_line'];
            delete attrs['tasks'];
            delete attrs['children'];
            delete attrs['repeats'];

            // Due and tags are always expected
            if (!attrs.due) {
                attrs.due = null;
            }
            if (!attrs.tags) {
                attrs.tags = {};
            }

            // Assingnee_ids are not:
            if (attrs.assignee_ids && !attrs.assignee_ids.length) {
                delete attrs.assignee_ids;
            }
            return attrs;
        },

        
        update: function(id, attrs) {
            this.touch_list_has_data(attrs.checklist_id);

            var res = $.Deferred();
            can.batch.start();
            res.always(function() {
                can.batch.stop();
            });

            var that = this;
            CachingModel.update.call(this, id, attrs).then(function(task) {
                that._reopen_parent_if_needed(id, task.attr()).then(function() {
                    res.resolve(task);
                }, res.reject);
            }, res.reject);
            return res;
        },


        _process_outline_node: function(result, outline, parent_item_id, checklist_id, positions, processed) {

            // Skip outline notes/comments
            if (outline.getAttribute('type') == 'note') return;

            var id = parseInt(outline.getAttribute("id"));

            if (processed[id]) return;
            processed[id] = true;

            positions[parent_item_id] = positions[parent_item_id] || 0;
            positions[parent_item_id] ++;

            var task_data = {
                id: id,
                parent_id: parent_item_id,
                checklist_id: CachingModel.fix_id(checklist_id),
                content: outline.getAttribute("text"),
                position: positions[parent_item_id]
            };

            // Process status
            var status = outline.getAttribute("status");
            task_data.status = Task.num_status(status);

            // Process due
            task_data.due = outline.getAttribute('due') || null;

            // Process tags
            task_data.tags = {};
            var tags = outline.getAttribute('tags');
            if (tags) {
                tags = tags.split(",");
                for(var i = 0; i < tags.length; i ++) {
                    task_data.tags[tags[i]] = false;
                }
            }

            // Process attachments/uploads
            var uploads = outline.getAttribute('uploads');
            if (uploads) {
                task_data.uploads = uploads.split(",");
            }

            var priority = outline.getAttribute('priority');
            if (priority) {
                task_data.mark = parseInt(priority);
            }
            else {
                // OBSOLETE: Process color/priority:
                // mark attribute gets value 1..6 according to priority
                var mark = outline.getAttribute('bgColor');
                if (mark) {
                    switch (mark) {
                        case 'red': task_data.mark = 4; break;
                        case 'blue': task_data.mark = 5; break;
                        case 'green': task_data.mark = 6; break;
                    }
                }
                else {
                    mark = outline.getAttribute('color');
                    if (mark) {
                        switch (mark) {
                            case 'red': task_data.mark = 1; break;
                            case 'blue': task_data.mark = 2; break;
                            case 'green': task_data.mark = 3; break;
                        }
                    }
                }
            }


            var repeating = outline.getAttribute('has_repeating');
            if (repeating) {
                task_data.details = {has_repeating: repeating};
            }

            // Process assignee_id attribute -> assignee_ids array for task
            var assignee_ids = outline.getAttribute('assignee_id');
            if (assignee_ids) {
                task_data.assignee_ids = assignee_ids.split(/,/).map(function(v) { return parseInt(v)});
            }

            // Process due_user_ids attribute -> due_user_ids array for task
            var due_user_ids = outline.getAttribute('due_user_ids');
            if (due_user_ids) {
                task_data.due_user_ids = due_user_ids.split(/,/).map(function(v) { return parseInt(v)});
            }

            // Process dateModified attribute -> updated_at string
            var updated_at = outline.getAttribute('dateModified');
            if (updated_at) {
                task_data.updated_at = new Date(updated_at).getTime();
            }


            result.push(task_data);

            var childrenCount = 0;
            var children = outline.childNodes;
            for (var i = 0; i < children.length; i ++) {
                var child = children[i];
                if (child.nodeName === 'outline') {
                    if (child.getAttribute('type') !== 'note') {
                        this._process_outline_node(result, child, id, checklist_id, positions, processed);
                        childrenCount++;
                    }
                    else {
                        task_data.notes = task_data.notes || [];
                        var note = {
                            id: parseInt(child.getAttribute("note_id")),
                            comment: child.getAttribute("text"),
                            username: child.getAttribute("user"),
                            user_id: child.getAttribute("_user_id")
                        };

                        var updated_at = child.getAttribute("dateModified");
                        if (updated_at) {
                            note.updated_at = new Date(updated_at).getTime();
                        }
                        task_data.notes.push(note)
                    }
                }
            }

            task_data.childrenCount = childrenCount;
        },

        /*
         <?xml version="1.0"?>
         <opml version="2.0">
         <head>
         <title>new list</title>
         <dateModified>Sat, 12 Oct 2013 17:17:11 +0200</dateModified>
         <ownerName>kir</ownerName>
         </head>
         <body>
         <outline text="some list item" status="open" _status="indeterminate" action="created" user="!@#$%^&amp;*()&quot;'f" dateModified="Sat, 12 Oct 2013 15:14:15 +0000" color="red" id="3234234">
             <outline text="subitem" status="open" _status="indeterminate" due="2013-10-12" assignee_id="1" tags="tag1,tag2" action="note added" user="kir" dateModified="Sat, 12 Oct 2013 15:15:20 +0000" bgColor="blue">
                 <outline type="note" isComment="true" text="some interesting note" user="kir" _user_id="1" note_id="3333" dateModified="2018/01/04 17:52:41 +0000" />
         </outline>
         </outline>
         </body>
         </opml>
       */

        num_status: function(status_txt) {
            return status_txt === "closed" ? 1 : status_txt === "invalid" ? 2 : 0;
        },
        
        _f: null

    }, {

        serialize: function() {
            var res = CachingModel.prototype.serialize.apply(this, arguments);
            delete res.children;
            delete res._read_only_list;
            return res;
        },

        /**
         * @param [children_provider] a function which accepts task_id and returns array of child task_ids
         * @return Promise which resolves into object for mobile sharing, with properties title,text,url.
         */
        create_share_object: function(children_provider) {
            var that = this;
            children_provider = children_provider || function() { return null };

            return new Promise(function (resolve, reject) {
                ListState.findOne({id: that.checklist_id, skip_server: true}).then(function(listState) {
                    resolve(that.do_create_share_object(children_provider, listState))
                }, function (no_list_state) {
                    resolve(that.do_create_share_object(children_provider, null))
                });
            });
        },

        do_create_share_object: function(children_provider, list_state) {
            var textGenerator = new maxkir.BaseCopyGenerator({
                is_collapsed(task_id) {
                    return list_state ? list_state.task_collapsed(task_id) : true;
                },
                task_data(task_id) {
                    return mxTask.store[task_id];
                },
                children_provider: children_provider,
                origin: "https://checkvist.com"
            });

            return {
                url: "https://checkvist.com/checklists/" + this.checklist_id + "/tasks/" + this.id,
                text: textGenerator.generate_plain([this.id])
            };
        },

        open: function() {
            return !this.status;
        },

        /**
         * @return {Promise} which resolves to the list object for this task
         */
        list: function() {
            if (!this.checklist_id) {
                return Promise.reject(new Error("List ID is not set for list item " + this.id));
            }

            // Cannot use 'require' here due to a cyclic dependency
            var cachedList = mxList.store[this.checklist_id]; 
            if (cachedList) return Promise.resolve(cachedList);
            
            return Promise.resolve(mxList.findOne({id: this.checklist_id}));
        },

        /**
         * @returns {Array<Note>}
         */
        get_notes_array: function() {
            var notes = this.attr('notes');
            return notes ? notes.attr() : [];
        },

        /**
         * @param {String|Number} note_id
         * @return Note
         */
        find_note: function(note_id) {
            return this.get_notes_array().find(function (note) {
                return note_id == note.id;
            });
        },

        /**
         * @param note_id
         * @return {Promise} which resolves to whether note is editable
         */
        can_edit_note: function(note_id) {
            const that = this;
            return this.list().then( list => {
                const note = that.find_note(note_id);
                return note && note.user_id == app.current_user.id && !list.read_only(that, true);
            });
        },

        /**
         * @param note_id
         * @return {Promise} which resolves to whether note is deletable
         */
        can_delete_note: function(note_id) {
            const that = this;
            return this.list().then((list) => {
                if (!list.read_only(that)) {
                    return true;
                }
                else {
                    // list is read-only; assignee can delete own notes
                    const note = that.find_note(note_id);
                    return note && note.user_id == app.current_user.id && !list.read_only(that, true);
                }
            });
        },

        /**
         * Return promise which resolves only if the current task is editable
         * @param allow_assignee true if the operation should be allowed to the task assignee
         * @return {Promise}
         */
        can_edit_def: function (allow_assignee) {
            const task = this;

            if (!task.checklist_id) return Promise.reject(new Error(I18N.t("error.item.not.found")));

            return this.list().then(list => {
                if (list.read_only(task, allow_assignee)) {
                    throw new Error(I18N.t("error.read_only.task", task.content));
                }
            });
        },


        markdown: function() {
            var cachedList = mxList.store[this.checklist_id];
            return cachedList ? cachedList['markdown?'] : true;
        },
        
        read_only: function() {
            return this.attr('_read_only_list');
        },

        status_css: function() {
            var st = this.status;
            return "task" + (st == 1 ? "Closed" : st == 2 ? "Invalid" : "Open");
        },

        /**
         * @returns {string} like priorityColor_4
         */
        color_css: function() {
            var mark = this.priority_index();
            return mark ? " priorityColor_" + mark + " " : "";
        },

        add_note_url: function() {
            return "/app/list/" + this.checklist_id + "/item/" + this.id + "/new_note.html";
        },

        priority_index: function() {
            "use strict";
            if (this.mark > 0) {
                return parseInt(this.mark);
            }
            return 0;
        },

        /**
         * @param {number} priority
         */
        set_priority_index: function(priority) {
            if (!priority) {
                this.attr('mark', null);
            }
            else if (priority > 0) {
                this.attr('mark', parseInt(priority));
            }
        },

        isDivider: function() {
            return /^---+$/.test(this.attr('content'));
        },
        
        isRepeating: function() {
            return this.details && this.details.has_repeating;
        },

        /**
         * @param {ParsedContent} data
         */
        update_from: function(data) {
            can.batch.stop();
            if (!this.attr('tags') || !app.util.is_equal(this.attr('tags').attr(), data.tags)) {
                this.attr("tags", data.tags);
            }
            if (this.due != data.due) {
                this.attr("due", data.due);
            }
            if (data.mark && this.mark !== data.mark) {
                this.attr("mark", data.mark);
            }
            if (!data.mark && this.attr("mark")) {
                this.attr("mark", null);
            }

            if (data.is_pro) {
                if (data.assignee_ids && data.assignee_ids.length) {
                    this.attr("assignee_ids", data.assignee_ids);
                }
                else if (this.assignee_ids) {
                    this.removeAttr('assignee_ids');
                }
            }

            this.attr("content", data.new_content);
            can.batch.start();
        },

        toString: function() {
            return this.id + " at " + this.position + " " + this.content;
        }

    });

    module.exports = Task;
});
