
import app from 'app'

export default function() {
    function visibilityHandler() {
        var hash = '#bg';
        if (document.hidden && !window.location.hash) {
            window.history.replaceState(null, null, window.location + hash);
            app.info("Page hidden");
        } else if (!document.hidden && window.location.hash == hash) {
            var l = '' + window.location;
            window.history.replaceState(null, null, l.substr(0, l.length - hash.length));
            app.info("Page shown");
        }
    };
    document.addEventListener('visibilitychange', visibilityHandler, false);

    visibilityHandler();
};