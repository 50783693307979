define(function (require, exports, module) {

    var app = require('app');

    var CachingModel = require('app/model/caching_model');
    var SlidingPanel = require('app/controls/sliding_panel').default;
    var UserPrefs = require('app/model/user_prefs');

    /**
     * @class User
     */
    var User = CachingModel.extend("mxUser", {

        get_table_name: function() {
            return "users";
        },

        /**
         * Return a deferred which resolves to a JSON array of collaborators for the currently logged in user
         * @param params findAll parameters
         * @returns {Deferred}
         */
        get_load_data_deferred: function(params) {

            if (params.id) { // Not allowed!
                var res = $.Deferred();
                res.reject("Cannot load user from server by ID:" + JSON.stringify(params));
                return res;
            }
            
            var promise = this._get_users_cache;
            if (!this._get_users_cache) {
                app.debug("Start loading users/collaborators from server");
                try {
                    promise = Promise.resolve($.ajax({
                        dataType: "json",
                        type: "get",
                        url: app.config.api_url + "/auth/collaborators.json",
                        data: {token: app.current_user.attr("token")}
                    })).catch(function (jhr) {
                        throw app.create_ajax_error(jhr, "Loading users error", 'ERR_LOAD_SERVER_DATA');
                    });
                } catch (e) {
                    return Promise.reject(e);
                } 
                
                promise.then(function() {
                    app.debug("Caching loaded users/collaborators");
                    this._get_users_cache = promise; 
                }.bind(this));
            }

            return promise; 
        },

        reset_cache: function() {
            this._get_users_cache = null;
            return CachingModel.reset_cache.apply(this, arguments);
        },

        /**
         * Login of the current user from LocalStorage data, if possible
         * */
        silent_login: function(onSuccess, onFailure) {
            if (app.current_user) {
                onSuccess();
                return;
            }

            var user_attr = app.storage.get_item("current_user");
            if (user_attr) {
                app.current_user = app.current_user || new User(user_attr);

                onSuccess();
            }
            else {
                onFailure();
            }
        },
        
        token_login: function(token) {
            var user = new User({token: token});
            return user.fetch_user_and_save();
        },
        
        parseModel: function(attrs) {
            // Keep token for logged-in user when current user is refreshed
            if (app.current_user && app.current_user.id == attrs.id) {
                attrs.token = app.current_user.token;
            }
            return attrs;
        }
        

    }, {

        /**
         * @return {UserPrefs}
         */
        prefs: function() {
            if (!this._prefs) {
                this._prefs = new UserPrefs(this.id);
            }
            return this._prefs;
        },

        signIn: function(username, password, token2fa, success, on_error) {
            return this.sign_in(username, password, token2fa).then(success, on_error);
        },

        /**
         * @return {Deferred}
         */
        refresh_login: function() {

            // Caching and synchronization
            if (this._relogin_def && this._relogin_old_token === this.attr('token')) {
                return this._relogin_def;
            }
            
            this._relogin_old_token = this.attr('token');
            
            app.info("Relogin with old token " + this._relogin_old_token);
            
            var self = this;
            var result = new Promise(function(resolve, reject) {
                $.ajax({
                    dataType: "json",
                    type: "POST",
                    url: app.config.api_url + "/auth/refresh_token.json",
                    data: {old_token: self._relogin_old_token}
                }).then(function token_refreshed(data) {
                    app.debug("Token refresh OK.");
                    return self._on_good_token(data);

                }, function token_refresh_error(jqXHR, textStatus, errorThrown) {
                    // Either bad login, or no internet
                    reject(app.create_ajax_error(jqXHR, "Token refresh error", 'ERR_INTERNET_DISCONNECTED'));
                }).then(resolve);
            });

            this._relogin_def = result;

            //// When credentials are invalid, require user to relogin
            result.then(function() {
                self._relogin_def = null;
                new SlidingPanel(".authErrorMessage").hide();
            }, function(error) {
                self._relogin_def = null;
                if (error.auth_problem) {
                    new SlidingPanel(".authErrorMessage").show();
                }
                else {
                    new SlidingPanel(".authErrorMessage").hide();
                }
            });

            return result;
        },

        /**
         * @param username
         * @param password
         * @param token2fa
         * @return {Deferred}; when error, resolves either to a Error with e.error_code "ERR_INTERNET_DISCONNECTED" or "ERR_AUTH_PROBLEM" or "ERR_GET_USER_DATA"
         */
        sign_in: function(username, password, token2fa) {
            var self = this;
            // this.attr({token: "123", username: "kir", email: "kir@maxkir.com", id: 1});
            // this._save_to_db();
            // return Promise.resolve();

            return new Promise(function(resolve, reject) {
                $.ajax({
                    type: "POST",
                    url: app.config.api_url + "/auth/login.json",
                    data: {username: username, remote_key: password, token2fa: token2fa}
                })
                    .then(function (data) {
                        app.debug("Authenticated.");
                        resolve(self._on_good_token(data));
                    }, function(jhr) {
                        reject(app.create_ajax_error(jhr, "Sign in error", 'ERR_INTERNET_DISCONNECTED'));
                    })
                ;
            });
        },
        
        _on_good_token: function(token) {
            this.attr({token: token});
            this._save_to_db();
            return this.fetch_user_and_save();
        },

        /**
         * Passed deferred resolves to 'ERR_GET_USER_DATA' on a error
         * @return passed deferred
         */
        fetch_user_and_save: function() {
            var self = this;
            
            return new Promise(function(resolve, reject) {
                $.ajax({
                    dataType: "json",
                    url: app.config.api_url + "/auth/curr_user.json",
                    data: "token=" + self.attr("token")})
                    .then(function fetch_user_data_ok(data) {
                        app.debug("Loaded current user data", data);
                        self.attr(data);
                        app.current_user = self;
                        self._save_to_db();
                        resolve();
                    }, function fetch_user_data_err(jXHR) {
                        reject(app.create_ajax_error(jXHR, "Cannot fetch current user details", "ERR_GET_USER_DATA"));
                    });
            });
        },
        
        _save_to_db: function() {
            app.storage.set_item('current_user', this.attr());
        },

        start_trial: function() {
            var self = this;
            return new Promise(function (resolve, reject) {

                $.ajax({
                    dataType: "json",
                    method: "post",
                    url: app.config.api_url + "/subscriptions/create_trial.json",
                    data: "token=" + self.attr("token")})
                    .then(function start_trial_ok() {
                        self.fetch_user_and_save().then(resolve, reject);
                    }, function fetch_user_data_err(jXHR) {
                        var reject_orig = function() {
                            reject(app.create_ajax_error(jXHR, "Cannot start trial", "ERR_START_TRIAL"));
                        };

                        self.fetch_user_and_save().then(reject_orig, reject_orig);

                    });

            })
        },

        logout: function() {
            app.current_user = null;
            app.storage.set_item('current_user', null);
            this._relogin_def = null;
            this.removeAttr('hash');
            this.removeAttr('token');
        }

    });

    module.exports = User;
});
