import app from "app";
import CommandQueue from "app/command_queue";

const can = app.can;

    /**
     * @class BaseCommand
     *
     * To override:
     *   constructor.type
     *
     * init
     * run
     * serialize_json
     * init_from_json
     * update_command_ajax_params
     *
     */
    const BaseCommand = can.Construct.extend({

        type: null,  // to be defined

        init_command_factory: function() {
            var that = this;
            CommandQueue.add_command_factory(this.type, function(command_json) {
                return new that.prototype.constructor().init_from_json(command_json);
            });
        },

        /**
         * @param {function} accept_function
         * @returns {Array<BaseCommand}} existing commands which match accept_function
         */
        find_commands: function(accept_function) {
            return CommandQueue.filter(function (cmd) {
                return cmd instanceof this.prototype.constructor && accept_function(cmd);
            }, this)
        }


    }, {

        init: function() {
            this.type = this.constructor.type;
            this._stored_attributes = {};
        },

        store_attribute: function() {
            for(var i = 0; i < arguments.length; i ++) {
                this._stored_attributes[arguments[i]] = true;
            }
        },

        // Command fields and methods:
        serialize_json: function() {
            var result = {};
            for(var key in this._stored_attributes) {
                if (this._stored_attributes.hasOwnProperty(key)) {
                    result[key] = this[key];
                }
            }
            return result;
        },
        init_from_json: function(json) {
            for(var key in this._stored_attributes) {
                if (this._stored_attributes.hasOwnProperty(key)) {
                    this[key] = json[key];
                }
            }
            return this;
        },

        run_command_on_server: function(result) {

            var that = this;

            app.debug("Run " + this.toString() + " command on server");

            var ajax_params = {
                data: {token: app.current_user.token},
                dataType: "json",
                type: 'POST',
                timeout: 5000
            };

            this.update_command_ajax_params(ajax_params);

            CommandQueue.ajax_no_abort(ajax_params).then(function (response_json){
                can.batch.start();
                var to_run = that.get_promises_on_server_response(response_json);
                app.run_all(to_run).always(can.batch.stop).then(result.resolve, result.reject);
            }, function(err) {
                that.on_server_error(err, result);
            });
        },

        update_command_ajax_params: function(ajax_params) {
            throw new Error("to be overriden");
        },

        /**
         * @param response_json
         * @return {Array<Promise>}
         */
        get_promises_on_server_response: function(response_json) {
            throw new Error("to be overriden");
        },

        on_server_error: function(err, result) {
            if (err && err.xhr && err.xhr.status == 404) {
                var that = this;
                app.check_for_internet_connection().then(function() {
                    // todo: should remove this task from the DB? Update UI?
                    // todo: untested
                    app.warn("Could not find this task (or this list) - ignore this command", err);
                    result.resolve([]);
                }, function() {
                    result.reject("OFFLINE");
                });
                return;
            }
            result.reject.call(result, err);
        }

    });

export default BaseCommand;