import app from "app";
import Page from "app/pages/page";
import Cleaner from "app/model/app_cleaner";

export default Page.extend('AboutPage', {
            defaults: {
                template: "about_page.mustache",
                page_title: "Checkvist: " + I18N.t("about").toLocaleLowerCase()
            }
        },
        {
            
            onRender: function() {
                if (window._newCheckvistVersion) {
                    $('.version').html($('.version').html() + "<br>(restart to load the new version)");
                }
            },
            
            get_model: function() {
                return app.current_user;
            },
            
            default_back_url: function() { return "/app/settings.html"; },

            "#cleanAllData click": function(link, event) {
                Cleaner.clean_all().then(function() {
                    Path.dispatch("/app/index.html");
                }, function(err) {
                    app.msg.show_error("Cannot clean data: " + err);
                });
            },

            "#viewLog click": function(link, event) {
                app.view_log();
            }


        });