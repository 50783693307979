define(function (require, exports, module) {

    var app = require('app');

    var cache = {};
    
    var TEMP_OBJ_TABLE = 'temp_objects';

    /**
     * @class TempObjects
     */
    var TempObjects = {

        /**
         * @param object_id {*}
         * @return {boolean} true, if given object_id corresponds to a local object_id, i.e. not numeric one
         */
        is_local: function(object_id) {
            return object_id && parseInt(object_id) != object_id;
        },
        
        _init_cache: function() {
            if (!this._inited) {
                var that = this;
                return app.storage.open_db()
                    .then(function(server) {
                        return server.query(TEMP_OBJ_TABLE).all().execute();
                    })
                    .then(function (records) {
                        app.debug("Loaded temp_object records: " + records.length);
                        that._inited = true;
                        records.forEach(function(r) { cache[r.id] = r.new_id;})
                    });
            }
            return Promise.resolve();
        },

        /**
         * Resolves to
         * <ul>
         *   <li> task replacement ID, if such replacement is present, or passed temp_uuid is numeric
         *   <li> rejects otherwise
         * </ul>
         * @param {string} temp_uuid
         * @return {Promise} see above
         */
        find_replacement_id: function (temp_uuid) {

            if (!this.is_local(temp_uuid)) {
                return Promise.resolve(temp_uuid);
            }

            return this._init_cache()
                .then(function () {
                    if (typeof cache[temp_uuid] === 'number') {
                        // console.warn("Return " + cache[temp_uuid] + " instead of " + temp_uuid);
                        return cache[temp_uuid];
                    }
                    else {
                        throw new Error("Cannot find replacementID for " + temp_uuid);
                    }
                });
        },

        add_replacement: function(temp_uuid, object_id) {
            if (typeof object_id !== 'number') {
                throw new Error("add_replacement: expect number instead of " + object_id + " for " + temp_uuid);
            }
            cache[temp_uuid] = object_id;
            
            return app.storage.update_records_in_db(TEMP_OBJ_TABLE, [{id: temp_uuid, new_id: object_id, created_at: new Date()}]);
        },
        
        reset_local: function() {
            cache = {};
        }
    };

    module.exports = TempObjects;
});
