var messages = {
    en: {
        // Intro page
        'intro_text.1': "Minimalist online outliner and task manager for teams and individuals.",
        'intro_text.2': "Capture your ideas and notes, create checklists and plans,",
        'intro_text.3': "Share with colleagues, and get everything done — together.",
        'intro_text.4': "This is a work in progress.",
        "signIn" : "Sign in",

        // Sign-in page
        "password" : "and password, please",
        "email" : "Your e-mail",
        "token2fa" : "2-step verification code",
        "forgot_pwd": "I forgot the password",

        "err.cannot_login": "Can't recognise the email and password combination",
        "err.no_internet": "Internet connection is not available",
        "err.need_2fa": "Please enter 2-step verification code",


        // Lists page
        "yourLists": "Lists",
        "loading.your.lists": "Loading your lists",
        "quick.add": "Quick add",
        "add.to": "Add to:",
        "err.cannot_get_lists_data": "Cannot get lists from the server",
        "resentListsSync": "Syncing...",
        "readOnly": "read-only",

        // Add List page
        "addNewList": "Create new list",
        "enterNewListName": "Give this list a name",
        "create": "Create",

        // Add Note page
        "addNewNoteTitle": "Add new note",
        "enterNewNotePlaceholder": "Enter text of the new note",

        // List page
        "no.lists.with.id": "Cannot find list with ID %s on the server",
        "no.tasks.with.id": "Cannot find list item with ID %s on the server",
        "no.lists.in.storage.with.id": "Cannot find list with ID %s in local storage",
        "no.tasks.in.storage.with.id": "Cannot find list item with ID %s in local storage",
        "error.cannot.load.tasks.for.list": "Cannot load list items for the list",
        'error.cannot.save.state': "Cannot save list state",
        "no.list": "No list",
        "loading.list.items": "Loading list items",
        "empty.list": "There are no items in the list, yet",
        "empty.list.add.item": "Add items",
        "option.hide.completed": "Hide completed list items",
        "option.show.checkboxes": "Show checkboxes",
        'list.is.read_only.text': "You can only read this list",

        'delete.item': "Delete",
        'delete.button': "Delete",
        'delete.confirmation': "Delete this list item?",

        'change_status_close.button': "Mark all completed",
        'change_status_reopen.button': "Reopen all",
        'change_status.confirmation': "This will change the status of all sub-tasks. Proceed?",

        'notes.item.add': "Add note",
        'notes.item.view': "Notes",

        'priority.item': "Priority",
        'priority.item.none': "Remove priority",

        'mark.completed': "Mark done",
        'make.open': "Reopen",

        'add.item.on.top': "Add on top",
        'add.item.at.bottom': "Add at the bottom",
        'add.item.above': "Add above",
        'add.item.below': "Add below",
        'add.item.child': "Create a subtask",


        // Due page
        "dueNowNav": "Now",
        "dueASAPNav": "ASAP",
        "dueLaterNav": "Later",

        "dueTitle": "Due",
        "dueNowTitle": "due: now",
        "dueASAPTitle": "due: asap",
        "dueLaterTitle": "due: later",
        "dueNoItems": "No due tasks here, yet",
        "dueLoading": "Loading due tasks",
        "option.show.not.mine": "Show tasks of other people",

        "task.group.other": "Other tasks",
        "task.group.today": "Today",
        "task.group.overdue": "Overdue",
        "task.group.tomorrow": "Tomorrow",
        "task.group.current_week": "This week",
        "task.group.next_week": "Next week",
        "task.group.current_month": "This month",
        "task.group.next_month": "Next month",
        "task.group.current_year": "This year",
        "task.group.next_year": "Next year",
        "task.group.later": "Later",

        // Settings page
        "profile": "Your Profile",
        "e-mail": "Email",
        "pro.account": "Account",
        "ui.theme": "UI theme",
        "ui.theme.to.light": "Switch to Light",
        "ui.theme.to.dark": "Switch to Dark",
        "enable.session.debug": "Issues? Run a debug session",
        "debug.session.instructions":
            "The debug session is active. All you need\n" +
            "to do is reproduce the issue, then come\n" +
            "back to this screen and press the button below. Thanks!\n",
        
        "send.logs": "Send logs to the developers",
        "logout": "Sign out",
        "add_home_screen": "Add to Homescreen",

        "feedback_forum": "Feedback",
        "about": "App version and caches",
        "help": "How does Checkvist Mobile work?",

        //About page
        "app.version": "Built",
        "clean": "Clean all caches",
        "log": "View log",


        // Bookmarklet page
        "select.list": "Select a list",
        "empty.item.text": "/Edit text/",
        "remove.attachment": "Remove attachment?",
        "add.list.item.title": "Add list item",
        "tryProToAttach": "Start Checkvist PRO trial on profile page to attach files :)",
        "tryProForDarcula": "Start Checkvist PRO trial to use Darcula scheme :)",

        "done": "Done",
        "cancel": "Cancel",

        // Textarea for tasks
        "listNameInputPlaceholder": "Enter or copy/paste list items from any editor",
        "hint.close": "hide",
        "hint.separate.empty.line": "Separate list items with an empty line",
        "hint.indent.subtasks": "Indent with a space for subtasks",

        // Notes/Attachments panel
        "notesAttachments_header": "Notes & Attachments",
        "notesAttachments_addNote": "New note",
        "notesAttachments_editNote": "Edit",
        "notesAttachments_deleteNote": "Delete",
        "notesAttachments_deleteNoteConfirmation": "Delete this note?",
        "imagePreviewError_Offline": "Sorry, cannot load the image while offline",
        "imagePreviewError_Other": "Sorry, cannot load the image from the server",
        "imagePreviewProgress": "Loading image preview...",

        "saveMenuItem": "Save",
        "cancelMenuItem": "Cancel",

        // Errors:
        "error.offline": "There are pending updates. They will be synced once you're online.",
        "error.bad_login": "Cannot update data, please <a href='javascript://' class='signOutLink'>sign out</a> and sign in again.",
        "error.read_only.task": "Cannot modify task in read-only list: %s",
        "error.item.not.found": "List item not found",

        _f: null
    }
};

var lang = messages.en;

window.I18N = {
    t: function(code) {
        var res = lang[code] || code;
        for(var i = 1; i < arguments.length; i ++) {
            res = res.replace(/%s/, arguments[i]);
        }
        return res;
    }
};

export default window.I18N;