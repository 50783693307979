import app from "app";

    var can = app.can;

    /**
     * @class SlidingPanel

     Usage:
     new SlidingPanel(".headerMenu", { handler: "#clickable_element", on_show: function(), on_hide: function() })

     Shown elements have classes slidingPanel slidingPanel--shown; in default (hidden) state should have
     @include move(0, 100%);
     */
    var SlidingPanel = can.Control({

        /**
         * @param {Element}  element
         * @param {Object}   options options
         * @param {string} options.handler
         * @param {Function} options.on_show
         * @param {Function} options.on_hide
         */
        init: function(element, options) {
            this._cleaners = new maxkir.DisposeSupport();

            if (this.options.handler) {
                $(this.options.handler).on("touchstart.sliding_panel mousedown.sliding_panel", function(e) {
                    if (this.is_shown()) {
                        this.hide();
                    }
                    else {
                        this.show();
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }.bind(this));
                var that = $(this.options.handler);
                this._cleaners.push(function () {
                    that.off(".sliding_panel");
                })
            }

            // Correctly initialize first state of the component
            this._shown = this.element.hasClass("slidingPanel--shown") && !this.element.hasClass('slidingPanel--hidden');
        },

        hide: function(fast) {
            if (!this.is_shown()) return;
            if (this._progress) clearTimeout(this._progress);

            this.element.removeClass("slidingPanel--shown");

            if (fast) {
                this.element.addClass("slidingPanel--hidden");
                if (this._progress) clearTimeout(this._progress);
            }
            else {
                this._progress = setTimeout(function() {
                    if (this.element) {
                        this.element.addClass("slidingPanel--hidden");
                    }
                }.bind(this), 400);
            }

            this._shown = false;
            this._call('on_hide');
        },

        show: function() {
            if (this.is_shown()) return;
            if (this._progress) clearTimeout(this._progress);

            if (this.element) {
                this.element.removeClass("slidingPanel--hidden");
                this._progress = setTimeout(function() {
                    if (this.element) {
                        this.element.addClass("slidingPanel--shown");
                    }
                }.bind(this), 10);
            }

            this._shown = true;
            this._call('on_show');
        },

        is_shown: function() {
            return this.element && this._shown;
        },

        _call: function(name) {
            if (typeof this.options[name] === 'function') {
                this.options[name](this);
            }
        },

        destroy: function() {
            this._cleaners.dispose();
            can.Control.prototype.destroy.call(this);
        }
    });

export default SlidingPanel;