// Uses:
// maxkir.extend
// maxkir.debug
// maxkir.visible
// maxkir.$

// Initialize maxkir.TreeModel
(function(maxkir) {

  var RepaintAddOn = function(tree) {
    tree._repaint_handlers = new maxkir.Listeners();

    tree.on_repaint = function() {
      this._repaint_handlers.notify_listener('on_repaint');
    };

    /** callback interface:
     *    on_repaint: function()
     * */
    tree.add_on_repaint = function(callback) {
      this._repaint_handlers.add_listener(callback)
    }
  };




  maxkir.TreeModel = function(parentId, skip_li_class) {
    this._root = parentId;
    this._skip_class = skip_li_class;
    if (maxkir.TreeProvider) {
      maxkir.TreeProvider.reset();
    }

    RepaintAddOn(this);
  };

  /**
   * Let's build tree on DOM. Root is id of parent node for the tree.
   * Tree is UL/LI - based
   * */
  maxkir.extend(maxkir.TreeModel, {
    _root: null,

    getRoot: function() {
      return this._root;
    },

    setRoot: function(rootNode) {
      maxkir.debug('set tree root to ' + rootNode);
      this._root = rootNode;
    },

    is_visible: function() {
      var e = maxkir.$(this._root);
      while(e && e.tagName != "BODY") {
        if (maxkir.getStyle(e, "display") == "none") {
          return false;
        }
        e = e.parentNode;
      }
      return e;
    },

    isLeaf: function(node) {
      return 0 === this.getNodes(node, true).length;
    },

    getFirstNode: function() {
      var nodes = this.getNodes(this._root);
      return nodes.length > 0 ? nodes[0] : null;
    },

    /**
     * return array of child nodes
     */
    getNodes: function(parentNode, includeHidden) {
      var children = [];
      var visible_cache = {};

      var el = maxkir.$(parentNode);
      if (el) {
        if (el.tagName === 'UL') {
          this._processUlChildren(children, el, includeHidden, visible_cache);
        }
        else if (el.tagName === 'LI') {
          var childs = el.childNodes;
          for (var i = 0; i < childs.length; i ++) {
            if (childs[i].tagName === 'UL') {
              this._processUlChildren(children, childs[i], includeHidden, visible_cache);
            }
          }
        }
      }

      return children;
    },

    /**
     * return parent of a node or null if parent null or if no parent available
     */
    getParent: function(node) {
      if (node == null || node == this._root) return null;

      var el = maxkir.$(node);
      if (!el || !el.parentNode) return null;
      if (el.tagName == 'LI') {
        var ul = el.parentNode;
        if (ul.id == this._root) return ul.id;
        return ul.parentNode.id;
      }

      return node == this._root ? null : el.parentNode.id;
    },

    /**
     * @return Array of parents of a node. I.e. result[0] = id of the top-most parent (skipping getRoot()).
     * Returns null if node is not in the tree. Node itself is not included.
     */
    getParents: function(node) {
      if (node == null) return [];

      var parents = [];
      var p = this.getParent(node);
      while (p != null && p != this.getRoot()) {
        parents.push(p);
        p = this.getParent(p);
      }
      parents.reverse();
      return p == this.getRoot() ? parents : null;
    },

    /**
     * @param node
     * @return {number} number of parents for the given node; -1 if node is not in tree
     */
    getLevel: function(node) {
      var parents = this.getParents(node);
      return parents ? parents.length : -1;
    },

    /**
     * @param node
     * @return {String|null} top level parent item for given node
     */
    getTopParent: function(node) {
      var parents =  this.getParents(node);
      if (parents) {
        if (parents.length > 0) {
          return parents[0];
        }
        else {
          if(maxkir.$(node)) return maxkir.$(node).id;
        }
      }

      return null;
    },

    /**
     * @callback ForeachAction
     * @param {string} ForeachAction.node_id
     * @param {number} ForeachAction.idx 0-based index of the node
     * @param {string|null} ForeachAction.parent_node
     */

    /**
     * @param {ForeachAction} action - action to be run
     * @param {String} [root] - root element to start operation from
     * @param {boolean} run_action_for_root if set, the action will be called for root element as well
     * @param {boolean} skip_hidden if set, the action will be called only for visible elements
     * */
    foreach: function(action, root, run_action_for_root, skip_hidden) {
      if (!root) root = this.getRoot();
      var c = this.getNodes(root, !skip_hidden);
      for (var i = 0; i < c.length; i ++) {
        if (false !== action.call(this, c[i], i, root)) {
          this.foreach(action, c[i], false, skip_hidden);
        }
      }
      if (run_action_for_root) {
        action.call(this, root, 0);
      }
    },

    /**
     * @return {Array} array of all visible nodes excluding root
     */
    get_visible_nodes: function() {
      var visibleNodes = [];
      this.foreach(function(node) {
        visibleNodes.push(node);
      }, null, false, true);
      return visibleNodes;
    },

    print: function() {
      var content = "<pre>";
      content += this._printChildren(this._root, 0);
      content += "</pre>";
      maxkir.$('treeDebug').innerHTML = content;
    },

    _fast_visible: function(el, visible_cache) {
//      el = maxkir.$(el);
//      console.info("enter", el)

      if (!el) return false;
      if (el.id == this._root) return true;
      var result = visible_cache[el.id];
      if (result === true || result === false) {
//        console.info("hit", el)
        return result;
      }

      if (el.className && el.className.indexOf('hidden') >= 0) {
        if (el.id) visible_cache[el.id] = false;
        return false;
      }
      
      var res = maxkir._treat_visible_by_default || maxkir.visible(el);
      //console.info(el, res)
      //console.trace()
      if (el.id) visible_cache[el.id] = res;
      return res;
    },

    _processUlChildren: function(children, ulElement, includeHiddenNodes, visible_cache) {
      if (!includeHiddenNodes && !this._fast_visible(ulElement, visible_cache)) {
        return;
      }
      var childs = ulElement.childNodes;
      for (var i = 0; i < childs.length; i ++) {
        var child = childs[i];
        if (this._include_node(child, includeHiddenNodes, visible_cache)) {
          children.push(child.id);
        }
      }
    },

    _include_node: function(child, includeHiddenNodes, visible_cache) {
      if (child.id && child.tagName == 'LI') {

        var class_name = child.className;
        if (!includeHiddenNodes && !this._fast_visible(child, visible_cache)) return false;

        if (!this._skip_class || class_name.indexOf(this._skip_class) < 0) {
          return true;
        }
      }
      return false;
    },

    _printChildren: function(parent, level) {
      var content = "";
      var children = this.getNodes(parent);
      for (var i = 0; i < children.length; i ++) {
        for (var j = 0; j < level; j ++) {
          content += "    ";
        }
        content += children[i] + "\n";
        content += this._printChildren(children[i], level + 1);
      }
      return content;
    }

  });

})(window.maxkir);




