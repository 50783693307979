define(function (require, exports, module) {

    var maxkir = require('app/maxkir');
    var User = require('app/model/user');



    /**
     * @typedef {Object} ParsedContent
     * @property {string} new_content 
     * @property {Object} tags (string -> boolean) 
     * @property {Date} due 
     * @property {boolean} is_pro 
     * @property {Array.<number>} assignee_ids 
     * @property {string} smart_syntax_addon 
     * 
     * 
     * Handles parsing of task content
     * 
     * @class TaskTextParser
     */
    const TaskTextParser = {

        /**
         * @param {Task} task
         * @return {String}
         */
        smart_syntax_attributes: function(task, is_pro) {
            return maxkir.CommonRender.smart_syntax_attributes(task.serialize(),
                function(tag) { return tag;}, function(user_id) {
                    var u = User.store[user_id];
                    return u && u.username ? maxkir.CommonRender.user_short_name(u.username) : "unknown";
                }, is_pro);
        },

        /**
         * @param {String} content
         * @param {boolean} is_pro
         * @return Deferred which resolves to a object of class {@link ParsedContent}
         */
        parse_content: function(content, is_pro) {
            /**
             * @type ParsedContent
             */

            content = maxkir.CommonRender.update_priorities_at_start(content);

            var resultData = {smart_syntax_addon: '',
                assignee_ids: [], due: null, tags: {}, new_content: content, is_pro: is_pro, mark: null};
            
            var assignee_names = [];

            var index = content.length;

            //-------------
            // Parse and collect data from content line:
            while (true) {
                var next_token = this._get_last_token(content, index);
                var token = next_token.token;

                if (token[0] === '#') {
                    resultData.tags[token.substring(1)] = false;
                    resultData.smart_syntax_addon += " " + token;
                }
                else if (token[0] === '^' && !resultData.due) {
                    resultData.due = this._preprocess_due(token.substring(1));
                    resultData.smart_syntax_addon += " " + token;
                }
                else if (token[0] === '!' && !resultData.mark) {
                    resultData.mark = parseInt(token.substring(1));
                    resultData.smart_syntax_addon += " " + token;
                }
                else if (token[0] === '@' && is_pro) {
                    assignee_names.push(token.substring(1));
                }
                else {
                    break;
                }
                index = next_token.index;
            }

            if (assignee_names.length === 0 || !is_pro) {
                resultData.new_content = content.substring(0, index).trim();
                
                return Promise.resolve(resultData);
            }
            else {
                var that = this;
                var prepareUserName = function(s) {
                    return maxkir.CommonRender.user_short_name(s).toLowerCase();
                };
                
                return User.findAll({skip_server: true}).then(function(users) {
                    var username2user = {};
                    for(var i = 0; i < users.length; i ++) {
                        var user = users[i];
                        username2user[prepareUserName(user.username)] = user;
                    }
                    maxkir.debug("Got user map for command " + that, username2user);

                    var not_found = "";
                    assignee_names.forEach(function(new_name) {
                        var assignee = username2user[prepareUserName(new_name)];

                        if (assignee) {
                            resultData.assignee_ids.push(parseInt(assignee.id));
                            resultData.smart_syntax_addon += " @" + maxkir.CommonRender.user_short_name(assignee.username);
                        }
                        else {
                            not_found += " @" + new_name;
                        }
                    });

                    resultData.new_content = content.substring(0, index).trim() + not_found;
                    
                    return resultData;
                });

            }
        },

        /**
         * @param text
         * @param index the index which should not be included to the result.
         * @return {{token: string, index: number}}
         * @private
         */
        _get_last_token: function(text, index) {

            var is_space = function(c) {
                return c === ' ' || c === '\n' || c === '\r' || c === '\t';
            };
            var is_separator_char = function(c) {
                return c === '^' || c === '@' || c === '#' || c === '!';
            };

            var is_next_digit = (idx) => {
                const c = text[idx + 1];
                return c && (c >= '0' && c <= '9');
            };

            var last_idx = index;
            index --;
            while (index >= 0 && is_space(text[index])){
                // skip spaces
                index --;
            }
            while (index >= 0 && (!is_space(text[index]) || is_next_digit(index))){
                // collect non-spaces, considering special case for due date
                index --;
            }
            index ++;

            if (index > 1 && is_space(text[index - 1]) && is_separator_char(text[index])) {
                return {index: index, token: text.substring(index, last_idx).trim()}
            }
            else {
                return {token: "", index: -1};
            }
        },

        _preprocess_due: function(due) {
            var one_day = 1000*3600*24,
                now_time = new Date().getTime();
            var lc = due.toLowerCase();
            if (lc.indexOf('tom') === 0) {
                return new Date(now_time + one_day);
            }
            if (lc.indexOf('tod') === 0) {
                return new Date();
            }
            if (lc === 'asap') {
                return "ASAP";
            }
            if (lc.indexOf('yesterday') === 0) {
                return new Date(now_time - one_day);
            }

            var WEEKDAYS = {
                'sunday': 0, 'sun': 0,
                'monday': 1, 'mon': 1,
                'tuesday': 2, 'tue': 2,
                'wednesday': 3, 'wed': 3,
                'thursday': 4, 'thu': 4, 'thur': 4,
                'friday': 5, 'fri': 5,
                'saturday': 6, 'sat': 6
            };

            var weekdayIdx = WEEKDAYS[lc];
            if (typeof weekdayIdx === 'number') {
                var day_now = new Date().getDay();
                if (day_now >= weekdayIdx) {
                    weekdayIdx += 7;
                }
                return new Date(now_time + one_day * (weekdayIdx - day_now))
            }

            return due;
        }

    };

    module.exports = TaskTextParser;
});
