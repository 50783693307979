import app from "app";

import Page from "app/pages/page";
import install_home_screen from "install_home_screen";
import darcula_mode from "darcula_mode";
import util from "../jquery.util";


export default Page.extend('SettingsPage', {
            defaults: {
                template: "settings_page.mustache",
                page_title: "Checkvist: " + I18N.t("settings").toLocaleLowerCase()
            }
        },
        {
            get_model: function() {
                return app.current_user;
            },

            onRender: function() {
                var that = this;
                app.current_user.fetch_user_and_save().then(function () {
                    that.update_view();
                });

                install_home_screen.add_callback(() => {
                    $('.addHomeScreen').show();
                });
                this.update_view();
            },

            update_view: function () {
                if (darcula_mode.is_darcula()) {
                    $('.settings__toLight').show();
                    $('.settings__toDark').hide();
                }
                else {
                    $('.settings__toLight').hide();
                    $('.settings__toDark').show();
                }

                if (maxkir._debug) {
                    $('.debugSession').addClass("debugSession--on");
                }
                else {
                    $('.debugSession').removeClass("debugSession--on");
                }

                if (maxkir.has_errors > 0) {
                    $('.errors_count').html("(errors found: " + maxkir.has_errors + ")");
                }
            },

            ".settings__startTrial click": function (element, e) {
                var that = this;
                app.current_user.start_trial().then(function () {
                    that.render_page();
                }, function () {
                    util.alert("Sorry, cannot start trial.");
                    that.render_page();
                });

                e.preventDefault();
                $('.settings__startTrial').attr("disabled", true);
            },

            ".settings__toLight click": function (element, e) {
                darcula_mode.set_darcula(false);

                e.preventDefault();
                $('.settings__toLight').attr("disabled", true);
                this.update_view();
            },

            ".settings__toDark click": function (element, e) {
                if (!app.current_user.pro) {
                    e.preventDefault();
                    util.alert(I18N.t('tryProForDarcula'));
                    return;
                }

                darcula_mode.set_darcula(true);
                e.preventDefault();
                this.update_view();
            },

            ".debugSession__toggleLink click": function(element, e) {
                e.preventDefault();
                this._toggle_debug();
            },

            _toggle_debug: function() {
                maxkir.enable_debug(!maxkir._debug);
                this.update_view();
            },

            ".addHomeScreen click": function (element, e) {
                install_home_screen.request_add();
            },


            ".debugSession__sendLogs click": function() {

                const that = this;
                const u = app.current_user;
                const message = "User: https://checkvist.com/admin/users/" + u.attr("id") + "/edit \n\n" +
                    maxkir.local_log_text();

                $.ajax({
                    type: "post",
                    url: app.config.api_url + "/log/create",
                    data: {"message": message}
                }).then(function() {
                    util.alert("Done, thanks!");
                    that._toggle_debug();
                }, function() {
                    util.alert("Error sending the log, please try again later");
                });
            }

        });