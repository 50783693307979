define(function (require, exports, module) {
    var app = require('app');
    var maxkir = require('app/maxkir');
    var User = require('app/model/user');

    var can = app.can;

    /**
     * @constructor
     */
    var CompletionNode = function(text, alternative_text) {
        this.text = text;
        this.alt_text = alternative_text;
        this.leaf = true;
    };
    /**
     * @param {Array<CompletionNode>} childNodes
     */
    CompletionNode.prototype.add_children = function(childNodes) {
        this.children = childNodes;
        this.leaf = false;
    };

    /**
     * @interface CompleterModel
     */
    var CompleterModel = function(){};
    /**
     * @return {Array<CompletionNode>}
     */
    CompleterModel.prototype.get_nodes = function() {};

    /**
     * @constructor
     */
    var CompletionHelperComponent = can.Control.extend({
        /**
         * @lends CompletionHelperComponent
         */
        init: function (element, options) {
            this._log("Init CompletionHelperComponent");
            this.element.append("<div class='completerContainer'>" +
                "<a href='#' class='completer_cancel iconButton'><i class='fa fa-times'></i></a>" +
                "<div class='completerItems'></div>" +
                "</div>");
            this._completer_element = this.element.find(".completerItems");
            
            if (app.is_testing()) {
                this._completer_element.data('completer', this);
            }

            this._completer_element.on("touchstart mousedown", ".completionEl", function(e) {
                this._hide_disabled = true;
            }.bind(this));
            
            this._completer_element.on("click", ".completionEl", function(e) {
                this._hide_disabled = false;
                e.preventDefault();
                e.stopPropagation();
                
                this._do_completion($(e.target));
            }.bind(this));
            
            this.element.find(".completer_cancel").on("touchstart mousedown", function(e) {
                this._hide_disabled = false;
                e.preventDefault();
                e.stopPropagation();
                
                this.hide();
            }.bind(this));
            
            this.hide();
        },
        
        _do_completion: function(element) {
            var token_info = this.options.input_model.get_token_info();
            var text = element.text();

            this._log("Do completion with '" + text + "' for token " + token_info.text + " at " + token_info.at);

            this.options.input_model.replace_token(token_info, text);
        },

        destroy: function () {
            this.element.find(".completer_cancel").off();
            this._completer_element.off();
            this._nodes = null;
            this._log("Dispose CompletionHelperComponent");
        },
        
        show: function() {
            if (!this._show_timeout) {
                this._show_timeout = setTimeout(function() {
                    this._completer_element.parent().addClass('shown');
                    $('.mainContainer').addClass('withCompleter');
                    this._show_timeout = null;
                }.bind(this), 200);
            }
        },
        
        hide: function() {
            if (this._hide_disabled) {
                return;
            }
            if (this._show_timeout) {
                clearTimeout(this._show_timeout);
                this._show_timeout = null;
            }
            this._completer_element.parent().removeClass('shown');
            $('.mainContainer').removeClass('withCompleter');
        },

        /**
         * @param {CompleterModel} model
         */
        update_from_model: function(model) {
            this._nodes = model.get_nodes();
            this.update_view();
        },
        
        update_view: function() {
            var nodes = this._nodes;

            if (!nodes || !nodes.length) {
                this.hide();
            }
            else {
                var token_info = this.options.input_model.get_token_info();

                var content = "";

                // Flatten the level if only one sub-level
                if (nodes.length == 1 && nodes[0].children) {
                    nodes = nodes[0].children;
                }
                
                var preparedToken = token_info.text.toLocaleLowerCase();
                var matches_token = function(text) {
                    var t = text.replace(/\s+/, '').toLocaleLowerCase();
                    return t.startsWith(preparedToken) && t != preparedToken; 
                };

                // Apply filter
                var filter_by_text = function(nodes) {
                    return nodes.filter(function(node) {
                        node._curr_text = null;
                        if (matches_token(node.text)) {
                            node._curr_text = node.text;
                        }
                        else if (node.alt_text && matches_token(node.alt_text)) {
                            node._curr_text = node.alt_text;
                        }
                        // console.warn(node._curr_text)
                        return node._curr_text;
                    });
                };

                var new_nodes = filter_by_text(nodes);

                if (!new_nodes.length) {
                    // Try to find child subnodes which match result
                    nodes.forEach(function (node) {
                        if (node.children) {
                            new_nodes = new_nodes.concat(filter_by_text(node.children));
                        }
                    });
                }
                
                if (!new_nodes.length) {
                    this.hide();
                }
                else {
                    // show
                    this._completer_element.parent().removeClass('vertical');
                    for(var i = 0; i < new_nodes.length; i ++) {
                        var t = new_nodes[i]._curr_text;
                        var leafClass = new_nodes[i].leaf ? ' leaf' : '';

                        content += "<a href='#' class='completionEl" + leafClass + "' tabIndex='-1'>" + t + "</a>";
                    }

                    this._completer_element.html(content);
                    var height = this._completer_element.height();
                    if (height > 50) {
                        this._completer_element.parent().addClass('vertical');
                    }

                    this.show();
                }
            }
        },

        _log: function() {
            app.debug.apply(app, arguments);
        }
    });

    /**
     * @param {Array<CompletionNode>} nodes
     * @param {String} parentNodeText
     * @return {Array<CompletionNode>}
     */
    var wrap_with_parent_node = function(nodes, parentNodeText) {
        if (nodes.length > 1) {
            var parent = new CompletionNode(parentNodeText);
            parent.add_children(nodes);
            return [parent];
        }
        return nodes;
    };

    /**
     * @constructor
     */
    var UpdatedAtCompletionProvider = function() {
        var RANGES = ["1h", "today", "yesterday", "week", "last week"];

        var result = [];
        var added = {};
        return {
            start_processing: function() {
                result = [];
                added = {};
            },

            /**
             * @param {Task} task
             */
            process_task: function(task) {
                var updated_at = task.updated_at;
                if (updated_at) {
                    for(var i = 0; i < RANGES.length; i ++) {
                        var range = RANGES[i];
                        if (!added[range] && maxkir.dates.within(range, updated_at)) {
                            result.push(new CompletionNode("changed: " + range));
                            added[range] = true;
                        }
                    }
                }
            },

            get_completion_nodes: function() {
                return wrap_with_parent_node(result, "changed:");
            }
        }
    };
    
    /**
     * @constructor
     */
    var TagCompletionProvider = function() {
        var result = [];
        var added = {};
        return {
            start_processing: function() {
                result = [];
                added = {};
            },

            /**
             * @param {Task} task
             */
            process_task: function(task) {
                var tags = task.tags;
                if (tags) {
                    if (tags.attr) tags = tags.attr();
                    for(var tag in tags) {
                        if (!added[tag]) {
                            result.push(new CompletionNode("#" + tag, "tag: " + tag));
                            added[tag] = true;
                        }
                    }
                }
            },

            get_completion_nodes: function() {
                result.sort(function(a,b) {
                    return a.text.localeCompare(b.text);
                });

                return wrap_with_parent_node(result, "#");
            }
        }
    };
    
    /**
     * @constructor
     */
    var DueCompletionProvider = function() {
        var RANGES = ["overdue", "now", "any", "asap", "today", "tomorrow", "current week", "next week", "current month", "next month"];
        var result = [];
        var added = {};
        return {
            start_processing: function() {
                result = [];
                added = {};
            },

            /**
             * @param {Task} task
             */
            process_task: function(task) {
                var due = task.due;
                if (due) {
                    for(var i = 0; i < RANGES.length; i ++) {
                        var range = RANGES[i];
                        if (!added[range] && maxkir.dates.within(range, due)) {
                            if (i > 2 || task.open()) {
                                var node = new CompletionNode("due: " + range, "^" + range);
                                node._pos = i;
                                result.push(node);
                                added[range] = true;
                            }
                        }
                    }
                }
            },

            get_completion_nodes: function() {
                result.sort(function(a,b) {
                    return a._pos - b._pos;
                });

                return wrap_with_parent_node(result, "due:");
            }
        }
    };
    
    /**
     * @constructor
     */
    var PriorityCompletionProvider = function() {
        var result = [];
        var added = {};
        return {
            start_processing: function() {
                result = [];
                added = {};
            },

            /**
             * @param {Task} task
             */
            process_task: function(task) {
                var priority = task.priority_index();
                if (priority > 0 && !added[priority]) {
                    added[priority] = true;
                    result.push(new CompletionNode("priority: " + priority, "color: " + priority));
                }
            },

            get_completion_nodes: function() {
                result.sort(function(a,b) {
                    return a.text.localeCompare(b.text);
                });
                if (result.length > 0) {
                    result.unshift(new CompletionNode("priority: any", "color: any"));
                }

                return wrap_with_parent_node(result, "priority:");
            }
        }
    };
    
    /**
     * @constructor
     */
    var AssigneeCompletionProvider = function() {
        var result = [];
        var added = {};
        return {
            start_processing: function() {
                result = [];
                added = {};
            },

            /**
             * @param {Task} task
             */
            process_task: function(task) {
                var assignees = task.assignee_ids;
                if (assignees && assignees.length) {
                    for(var i = 0; i < assignees.length; i ++) {
                        var user_id = assignees[i];
                        var user = User.store[user_id]; 
                        if (user) {
                            var username = maxkir.CommonRender.user_short_name(user.username);
                            if (!added[username]) {
                                added[username] = true;
                                result.push(new CompletionNode("@" + username, "assignee: " + username));
                            }
                        }
                    }
                }
            },

            get_completion_nodes: function() {
                result.sort(function(a,b) {
                    return a.text.localeCompare(b.text);
                });

                return wrap_with_parent_node(result, "@");
            }
        }
    };
    
    /**
     * @constructor
     */
    var HasNoteAttachmentCompletionProvider = function() {
        var result_note = false;
        var result_attachment = false;
        return {
            start_processing: function() {
                result_note = false;
                result_attachment = false;
            },

            /**
             * @param {Task} task
             */
            process_task: function(task) {
                result_note = result_note || task.notes && task.notes.length > 0;
                result_attachment = result_attachment || task.uploads && task.uploads.length > 0;
            },

            get_completion_nodes: function() {
                var res = [];
                if (result_attachment) {
                    res.push(new CompletionNode("has: attachment"));
                }
                if (result_note) {
                    res.push(new CompletionNode("has: note"));
                }

                return wrap_with_parent_node(res, "has:");
            }
        }
    };
    
    module.exports = {
        CompletionHelperComponent: CompletionHelperComponent,
        CompleterModel: CompleterModel,
        TagCompletionProvider: TagCompletionProvider,
        TaskFilterProviders: [
            new TagCompletionProvider(),
            new AssigneeCompletionProvider(),
            new DueCompletionProvider(),
            new PriorityCompletionProvider(),
            new UpdatedAtCompletionProvider(),
            new HasNoteAttachmentCompletionProvider()
        ]
    };
});