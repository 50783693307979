import BaseTaskCommand from "app/commands/base_task_command";

import $ from "jquery";
import app from "app";

/**
 * @class CreateNoteCommand
 */
var CreateNoteCommand = BaseTaskCommand.extend({
    type: "create_note"
}, {

    /**
     * @param {Task} task task which should be placed to another place
     */
    init: function(task) {
        BaseTaskCommand.prototype.init.apply(this, arguments);
        this.uuid = maxkir.uuid();
        this.store_attribute("note_content", "uuid");
    },

    set_note_content: function(text) {
        this.note_content = text;
    },

    /**
     * @return {Deferred} Deferred, which resolves to boolean value whether server update is needed. If rejected - command will be rejected, without server update.
     */
    create_update_tasks_local_deferred: function() {
        return this.do_create_update_tasks_local_deferred(function add_note(t) {

            var notes = t.get_notes_array();
            var uuid = this.uuid;
            if (notes.some(function (note) { return note.id === uuid})) {
                // Already there
                return null;
            }

            notes.push({
                id: this.uuid,
                comment: this.note_content,
                user_id: app.current_user.id,
                task_id: t.id,
                username: app.current_user.username || 'you',
                updated_at: new Date().toISOString()
            });

            t.attr('notes', notes);
            return t.save();
        }.bind(this));
    },

    update_command_ajax_params: function(ajax_params) {
        var task = this.task;

        $.extend(ajax_params, {
            url: app.config.api_url + '/checklists/' + task.checklist_id + "/tasks/" + task.id + "/comments.json",
            data: {
                token: app.current_user.token,
                "comment[comment]": this.note_content
            },
            dataType: "json",
            type: 'POST'
        });
    },

    /**
     * @param {Array} response_json
     */
    get_promises_on_server_response: function(response_json) {
        var that = this;
        return [new Promise(function(resolve, reject) {
            var notes = that.task.get_notes_array();
            var processed = false;
            notes.forEach(function(note) {
                if (note.id === that.uuid) {
                    $.extend(note, response_json);
                    that.task.attr('notes', notes);
                    processed = true;
                    that.task.save().then(resolve, reject);
                }
            });

            if (!processed) {
                resolve();
            }
        })];
    },

    toString: function() {
        return BaseTaskCommand.prototype.toString.call(this) + ";note_id=" + this.uuid;
    }
});

CreateNoteCommand.init_command_factory();

export default CreateNoteCommand;
