define(function (require, exports, module) {

    var app = require('app');
    var commands = require('app/command_queue');
    var maxkir = require('app/maxkir');
    var SlidingPanel = require('app/controls/sliding_panel').default;
    var can = app.can;

    /**
     * @class ProgressIndicatorControl
     */
    var ProgressIndicatorControl = can.Control({

        init: function(element, options) {

            // Insert the progress icon just before the central element.
            // It will put it to the left-most position on the right side.
            var titleH1 = element.find('.windowTitle');

            $(titleH1).after('<div class="iconButton" id="offline_icon"><i class="far fa-cloud-upload"></i></div>');

            var that = this;
            app.view_template("_offline_message.mustache", {}, {}, function(result) {
                $('.mainContainer').prepend(result);
                that._finalize_init();
            });
        },
        
        _finalize_init: function() {
            var show_progress_action = maxkir.delay_action(function() {
                $('#offline_icon').show();
            }, 200);

            var menu = this._create_message();
            this.progress_listener = {
                show_progress: function() {
                    show_progress_action.start();
//              $('#offline_icon').addClass("rotate");
                },

                stop_progress: function() {
//              $('#offline_icon').removeClass("rotate");
                },

                hide_progress: function() {
                    show_progress_action.stop();
                    menu.hide();
                    $('#offline_icon').hide();
                }

            };

            commands.add_listener(this.progress_listener);

        },

        _create_message: function() {
            var hide_timeout;
            var message = new SlidingPanel(".offlineMessage", {
                handler: '#offline_icon',
                on_show: function() {
                    commands.process_commands();
                    hide_timeout = setTimeout(function() {
                        message.hide();
                    }, 4000);
                },
                on_hide: function() {
                    clearTimeout(hide_timeout);
                }
            });
            return message;
        },

        destroy: function() {
            app.debug("Destroy progress_indicator");
            if (this.progress_listener) {
                commands.remove_listener(this.progress_listener);
                this.progress_listener = null;
            }
            can.Control.prototype.destroy.call(this);
        }


    });

    module.exports = ProgressIndicatorControl;
});