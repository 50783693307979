const app = require('app');

const file_storage_promise = function() {
    return app.storage.open_db().then(function(server) {
        return server['files_storage']
    });
};

/**
 * @param {String} uuid
 * @returns {Promise<UploadItem>}
 */
const file_storage_get = function (uuid) {
    return file_storage_promise().then(function(table) {
        return table.get(uuid);
    })
};

export { file_storage_promise, file_storage_get };