define(function (require, exports, module) {

    var app = require('app');
    var CachingModel = require('app/model/caching_model');

    /**
     * @class ListState
     *
     * Properties: id (same as list.id)
     */
    var ListState = CachingModel.extend("mxListState", {

        get_table_name: function() {
            return "list_states";
        },

        /**
         * Return a deferred which resolves to a JSON array of records for this model
         * @param params findAll/findOne parameters
         * @returns {Deferred}
         */
        get_load_data_deferred: function(params) {
            throw new Error("get_load_data_deferred should not be called directly for ListState");
        }
    }, {
        task_collapsed: function(task_id) {
            var collapsed = !this._init_expanded().attr(task_id.toString());
//            this.log("Is task collapsed: " + task_id, collapsed);
            return collapsed;
        },

        set_collapsed: function(task_id, collapsed) {
            this.log("Set task collapsed: " + task_id, collapsed);
            if (collapsed) {
                this._init_expanded().removeAttr(task_id.toString());
            }
            else {
                this._init_expanded().attr(task_id.toString(), true);
            }
        },

        _init_expanded: function() {
            if (!this.expanded_tasks) {
                this.attr('expanded_tasks', {});
            }
            return this.attr('expanded_tasks');
        }


    });

    module.exports = ListState;
});
