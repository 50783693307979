// depends on core.js
// depends on listeners.js

// should_hide: function(node_id)
// See add_listener/remove_listener

// Usages:
// maxkir.$
// maxkir.*ClassName
// maxkir.debug
// maxkir.highlight_text
// maxkir.set_selection

// TaskData: {content: content, tags: [], }
//
// maxkir.FilterState (opt)
// maxkir.Print (opt)
// maxkir.DataCompleter (opt)
/**
 * @typedef {Object} FilterTreeModel
 * @property {Function} getRoot
 * @property {Function} getParent(node_id)
 * @property {Function} isLeaf(node_id)
 * @property {Function} foreach(action: ForeachAction)
 */
/**
 * @typedef {Object} TreeProvider
 * @property {Function} tree_nav (nullable)
 * @property {Function} tree_model (returns FilterTreeModel)
 */
/**
 * @typedef {Object} TaskDataProvider - API to return model data (TaskData or Task)
 * @property {Function} from_node_id
 */
/**
 * @typedef {Object} FilterResult - record which describes filter info for a given node
 * @property {String} matched - string which is not empty if there is direct match on this node and which contains types of matches
 * @property {boolean} hidden - true if this node should not be visible on the screen
 */
/**
 *
 * @param maxkir
 * @param {String} filterId DOM id of a filtering text input
 * @param {TreeProvider} TreeProvider
 * @param {TaskDataProvider} TaskDataProvider
 * @param {Object} [ToggleSupport] Tree toggling support
 * @param {Function} ToggleSupport.do_expand(TaskData) Node expander callback
 * @param {Function} ToggleSupport.do_collapse(TaskData) Node collapser callback
 * @param {Function} ToggleSupport.is_collapsed(TaskData) return true if task is currently collapsed
 * @return {{findInput: findInput, show: show, clear_search_field: clear_search_field, is_hidden: is_hidden, is_active: is_active, is_filtered: is_filtered, is_filtered_by_text: is_filtered_by_text, can_filter: can_filter, highlight_text: highlight_text, add_filter: add_filter, remove_filter: remove_filter, createDelayedFilter: createDelayedFilter, doFilter: doFilter, cssClass4: cssClass4, has_matched_ancestor: has_matched_ancestor, update_search_field: update_search_field, filters: filters, parse_input: parse_input, val: val, parse_input_line: parse_input_line, task_data: task_data, should_hide: should_hide, activate: activate, passivate: passivate, mark_focused: mark_focused, mark_unfocused: mark_unfocused, _f: null}}
 * @constructor
 */
maxkir.FindFilterProvider = function(maxkir, filterId, TreeProvider, TaskDataProvider, ToggleSupport) {

  var findInput = function() {
    return maxkir.$(filterId);
  };

  // Text Filter:
  var TextFilter = {

    _txt_lines: [],

    parse_line: function(line, original_line) {
      return this._parse_text(line);
    },

    accept_item: function(task_data) {
      var lines = this.extract_text_lines(task_data);
      var res = '';
      for(var i = 0; i < lines.length; i ++) {
        if (!this.should_hide_by_text(lines[i][0])) {
          var match_type = lines[i][1];
          if (res.length) {
            if (res.indexOf(match_type) === -1) {
              res += '|' + match_type;
            }
          }
          else {
            res = match_type;
          }
        }
      }
      return res ? res : null;
    },
    
    get_active: function() {
      return this._txt_lines.length > 0;
    },

    _parse_text: function(line) {

      var terms = line.split(/\s+/);
      var txt_lines = [];
      for(var i = terms.length; i-- > 0;) {
        var term = terms[i];
        if (term.length > 0) {
          txt_lines.push(term.toLowerCase());
        }
      }

      this._txt_lines = txt_lines;

      return txt_lines;
    },

    /**
     * Also used when filtering by Notes
     * @param text
     * @return {boolean}
     */
    should_hide_by_text: function(text) {

      // Remove tags to avoid matching within HTML tag attributes
      var cr = maxkir.CommonRender;
      if (cr) {
        text = cr.stripTags(cr.format_for_rendering(text, false));
      }
      
      var lines = this._txt_lines;
      // Now, search by text
      for(var i = lines.length; i-- > 0;) {
        if (text.toLowerCase().indexOf(lines[i]) < 0) {
          // text line was not found
          return true;
        }
      }

      return false;
    },

    /**
     * @param task_data
     * @return {*[][]} array of arrays X, X[0] - text to search in, X[1] - text ID of the match (text, notes, etc)
     */
    extract_text_lines: function(task_data) {
      return [[task_data.content, "text"]];
    }

  };





  return {
    _filters: [],
    _filter_line: '',
    _unmatched: {},
    _direct_match: {},
    _hidden: {},
    _expanded_nodes: {},
    _listeners: new maxkir.Listeners(),

    TextFilter: TextFilter,

    findInput: function() {
      return findInput();
    },

    /**
     * @return {RegExp} Regular expression which matchs any supported term in this search field, which can be used for completion
     */
    regexp: function() {
      const res = [];
      this._filters.each((f) => {
        if (f.regexp) {
          res.push(f.regexp().source);
        }
      })
      return new RegExp("(" + res.join("|") + ")", 'gi');
    },

    show: function(text, keep_selection, force_set) {

      if (maxkir.$('what') && maxkir.Search && !keep_selection) {
        maxkir.Search.activate();
      }

      var input = findInput();
      if (!maxkir.visible(input) && !force_set) return;

      if (maxkir.Print && maxkir.Print.is_shown() && !force_set) {
        return;
      }

      if (force_set) {
        input.value = text;
      }
      else if (text.length > 0) {
        var text_pos = input.value.indexOf(text);
        var next_char = input.value.charAt(text_pos + text.length);
        while (text_pos >= 0 && (next_char != ' ' && next_char.length > 0)) {
          text_pos = input.value.indexOf(text, text_pos + text.length + 1);
          next_char = input.value.charAt(text_pos + text.length);
        }

        if (text_pos < 0) {
          if (input.value != '') {
            input.value += " ";
          }
          input.value += text;
        }
        else {
          input.value = input.value.substr(0, text_pos) + input.value.substr(text_pos + text.length);
          if (input.value.charAt(text_pos) == ' ') {
            input.value = input.value.substr(0, text_pos) + input.value.substr(text_pos + 1);
          }
          input.value = input.value.trim();
        }
      }

      this.activate(keep_selection);
      if (text.length > 0) {
        this.doFilter(force_set);
      }
    },

    clear_search_field: function() {
      if (!findInput()) return;

      findInput().value = '';
      this.refresh_filter();
    },

    refresh_filter: function() {
      this.doFilter(true);
      var tree = this.tree_nav();
      if (tree) {
        tree.fix_selection(true);
      }
    },

    /**
     * @typedef {Object} FilterListener - listener with some methods, optional ones
     * @property {function(string)} node_shown
     * @property {function(string)} node_hidden
     * @property {function(boolean)} focus_changed
     * @property {Function} filter_done - all filtering finished
     */
    /**
     * @param {FilterListener} l
     */
    add_listener: function(l) {
      this._listeners.add_listener(l);
    },

    /**
     * @param {FilterListener} l
     */
    remove_listener: function(l) {
      this._listeners.remove_listener(l);
    },
    
    dispose: function() {
      this._listeners.dispose();
      this._filters = [];
    },

    /**
     * @param node_id
     * @return FilterResult
     */
    get_result: function(node_id) {
      return {
        hidden: this.is_hidden(node_id),
        matched: this._direct_match[node_id],

        has_match: function(txt) {
          return this.matched && this.matched.indexOf(txt) >= 0;
        }

      }
    },

    /**
     * @return Array<String>
     */
    get_matches: function() {
      return Object.keys(this._direct_match);
    },

    /**
     * Explicitly exclude node from hiding until next re-filter
     * @param node_id
     */
    mark_shown: function(node_id) {
      maxkir.debug("mark_shown: " + node_id);
      delete this._hidden[node_id];
    },

    is_hidden: function(node_id) {
      var result = !!this._hidden[node_id];
      //maxkir.debug("is_hidden: " + node_id + " " + result);
      return result;
    },

    /** @return true if filtering field is active */
    is_active: function() {
      return this._active;
    },

    is_filtered: function() {
      return this._is_filtered;
    },

    is_filtered_by_text: function() {
      var input = findInput();
      return input && input.value.trim().length > 0;
    },

    can_filter: function() {
      return this.tree_model() != null;
    },

    tree_model: function() {
      // On list index page it is a different model
      var result = TreeProvider.tree_model();
      return result ? result : (this.tree_nav() ? this.tree_nav().tree_model() : null);
    },

    tree_nav: function() {
      return TreeProvider.tree_nav();
    },

    highlight_text: function(txt) {
      var txt_lines = this.text_lines();
      if (txt_lines.length == 0) return txt;
      return maxkir.highlight_text(txt, txt_lines);
    },

    /**
     * @return {Array<String>}
     */
    text_lines: function() {
      return TextFilter._txt_lines || [];
    },

    /**
     * @callback LineParser
     * @param {String} line line to be parsed
     * @param {String} original_line original line, before any parsing
     * @return {String}
     */

    /** Filter methods:
     * @typedef {Object} CustomFilter
     * @property {LineParser} CustomFilter.parse_line
     * @property {LineParser} CustomFilter.regexp - if present, returns a regexp which matches token of this filter
     * @property {LineParser} CustomFilter.get_active - true, if can filter out data, i.e. active. If return value 'strict_filter', sub-items of the matched item won't be considered as match for other filters
     * @property {LineParser} [CustomFilter.get_subfilters] - return optional array of subfilters according to parsed data, to be matched across hierarchy
     * @property {Function} CustomFilter.accept_item - should return string ID of type of filter if item should be shown at all (parameters - task_data, is_leaf)
     * 
     *     Dimmed items are those which were not accepted (usually parents of matched ones)
     *     May be we should consider all items mathing to the leafs?
     */
    
    /**
     * @param {CustomFilter} filter
     */
    add_filter: function(filter) {
      this._filters.push(filter);
      this.clear_cache();
    },

    remove_filter: function(filter) {
      this._filters = this._filters.filter(function(f) { return f !== filter; });
      this.clear_cache();
    },

    createDelayedFilter: function() {
      var action = maxkir.delay_action(function() {
        if (this._filter_line || this._should_filter()) {
          // Filter only if there was a previous filter or entered value is > 2 chars
          this.doFilter();
        }
      }.bind(this), 300);

      return action.start.bind(action);
    },

    _should_filter: function() {
      var not_latin = (c) => c.toLowerCase() === c.toUpperCase();
      var not_number = (c) => c < '0' || c > '9';

      var v = this.val();
      return v.length > 2 || (v.length > 0 && not_latin(v.charAt(0)) && not_number(v.charAt(0)))
    },

    /**
     * @param {boolean} [forceSync] if true, refiltering will be performed even if there are no changes in the filter input string, also the filtering will be performed synchronously
     * @param {boolean} [skip_node_css_update] if true, tree nodes won't be updated with CSS
     */
    doFilter: function(forceSync, skip_node_css_update) {
      if (this._filter_line === this.val()) {
        maxkir.debug("Filter text didn't change");
        if (!forceSync) {
          return;
        }
        else {
          maxkir.info("Filtering forced");
        }
      }
      else {
        maxkir.info("Filtering text change from '" + this._filter_line + "' to '" + this.val() + "'");
      }
      this._filter_line = this.val();

      this.parse_input();
      this.do_filter_task_tree(forceSync, skip_node_css_update);
    },
    
    clear_cache: function() {
      // console.trace("Clear cache")
      this._filter_line = '';
    },
    
    _log_time: function(msg) {
      if (msg) {
        maxkir.debug("Perf: " + msg + ": " + 
            (new Date().getTime() - this._time) + "ms, whole time: " +
            (new Date().getTime() - this._time_init));
      }
      else {
        this._time_init = new Date().getTime();
      }
      this._time = new Date().getTime();
    },

    reset_filter_caches: function() {
      // console.warn("reset_filter_caches");
      this._direct_match = {};
      this._unmatched = {};
      this._hidden = {};
      this._active_filters_cache = null;
      this._tree_model = this.tree_model();
    },

    /**
     * Runs filter on the given line and returns matched nodes
     * @param line
     * @return {Array<String>}
     */
    run_filter_detached: function(line) {
      this.parse_input(line)
      this._collect_hidden_nodes();
      return this.get_matches();
    },

    /**
     * @param {boolean} forceSync if true, the operation will be synchronous
     * @param {boolean} skip_node_css_update if true, tree nodes won't be updated with CSS
     */
    do_filter_task_tree: function(forceSync, skip_node_css_update) {
      var tree_model = this.tree_model();
      if (!tree_model) {
        maxkir.warn("No tree model in filter");
        return;
      }

      var that = this;

      // Separate variable used to interrupt current filtering if the value of filter has changed
      var savedLine = this._filter_line;

      this._log_time();
      maxkir.debug(">>> DO_FILTER_TASK_TREE");

      this._is_filtered = this._collect_hidden_nodes();

      this._log_time("Should_hide");

      maxkir.debug("Hidden nodes: " + Object.keys(that._unmatched));
      maxkir.debug("Direct matches: " + Object.keys(that._direct_match));

      for(var n in this._unmatched) {
        if (this._unmatched.hasOwnProperty(n)) {
          this._hidden[n] = this._unmatched[n];
        }
      }

      that.run_if_unchanged(savedLine, forceSync, function () {

        this._collapse_previously_expanded();
        this._log_time("_collapse_previously_expanded");
        
        if (this.is_filtered()) {
          maxkir.debug("expand_and_show_parents");
          var shown_parents = {};
          tree_model.foreach(function (node_id) {
            if (that._direct_match[node_id]) {
              that.expand_and_show_parents(node_id, tree_model, shown_parents);
            }
          });
          this._log_time("expand_and_show_parents");
        }

        this.run_if_unchanged(savedLine, forceSync, function() {
          maxkir.debug("update_tree_all, skip CSS: " + skip_node_css_update);
          this.update_tree_all(skip_node_css_update);
          this._log_time("update_tree_all");

          // Make sure selection is visible:
          var tree_nav = this.tree_nav();
          if (tree_nav) {
            if (tree_nav.get_all_selection().every(function(el) {return this.is_hidden(el)}, this)) {
              if (this.first_to_select) {
                tree_nav.set_selection(this.first_to_select.id);
              }
              else {
                tree_nav.selectFirstInTree();
              }
            }
          }

          this.update_search_field();
          if (maxkir.FilterState) {
            maxkir.FilterState.updateFilter( that._filter_line );
          }
          this._log_time("Done filtering")
        });

      });

    },

    _collect_hidden_nodes: function() {
      var _is_filtered = false;
      var that = this;
      this.tree_model().foreach(function(node_id) {
        var hide = that.should_hide(node_id);
        if (hide) {
          that._unmatched[node_id] = true;
          _is_filtered = true;
        }
      });
      return _is_filtered;
    },

    _collapse_previously_expanded: function() {
      "use strict";

      var tree = this.tree_nav();
      
      if (!tree) return;

      maxkir.debug("collapse_previously_expanded: " + Object.keys(this._expanded_nodes));
      var keep_state = this.is_filtered_by_text() ? {} : this._collect_selection_parents();
      for(var node_id in this._expanded_nodes) {
        var data = this.task_data(node_id);
        if (data && !keep_state[node_id] && !ToggleSupport.is_collapsed(data)) {
          delete this._expanded_nodes[node_id];
          ToggleSupport.do_collapse(data);
        }
      }
    },

    _collect_selection_parents: function() {

      var keep_state_for = {};

      var tree = this.tree_nav();
      if (tree) {
        var tree_model = this._tree_model;
        tree.get_all_selection().forEach(function(node) {
          "use strict";
          var parent_id = tree_model.getParent(node);
          while(parent_id) {
            keep_state_for[parent_id] = true;
            parent_id = tree_model.getParent(parent_id);
          }
        }, this);
      }

      return keep_state_for;
    },


    run_if_unchanged: function(savedFilterLine, forceSync, func) {

      if (forceSync) {
        func.apply(this);
        return;
      }

      var that = this;
      setTimeout(function() {
        if (savedFilterLine == that.val()) {
          func.apply(that);
        }
        else {
          maxkir.debug("Filtering interrupted: '" + savedFilterLine + "' is not '" + that.val() + "'");
        }
      }, 20);
    },

    _expand: function(node_id) {
      var data = this.task_data(node_id);
      if (data) {
        maxkir.debug("expand " + node_id);
        if (ToggleSupport.is_collapsed(data)) {
          this._expanded_nodes[node_id] = true;
          ToggleSupport.do_expand(data);
        }
      }
    },

    expand_and_show_parents: function(node_id, tree_model, shown_parents) {
      if (shown_parents[node_id]) {
        return;
      }

      maxkir.debug("expand_and_show_parents " + node_id);

      var parent_id = tree_model.getParent(node_id);

      while(parent_id) {
        if (shown_parents[parent_id]) {
          return;
        }
        shown_parents[parent_id] = true;

        this._expand(parent_id, true);
        this.mark_visible(parent_id);
        parent_id = tree_model.getParent(parent_id);
      }
    },

    mark_visible: function(node_id) {
      maxkir.debug("show " + node_id);
      delete this._hidden[node_id];
    },

    update_tree_all: function(skip_node_css_update) {
      var tree_model = this.tree_model();
      var root = tree_model.getRoot();
      var that = this;

      this.cssClasses = {};
      this.first_to_select = null;
      tree_model.foreach(function(node_id, idx, parent_id) {

        that.cssClasses[node_id] = "";

        var node = maxkir.$(node_id);
        if (that._unmatched[node_id]) {
          if (!skip_node_css_update){
            maxkir.addClassName(node, "dimmed");
          } 
          that.cssClasses[node_id] += "dimmed";
        }
        else {
          if (!skip_node_css_update) {
            maxkir.removeClassName(node, "dimmed");
          }

          if (!that.first_to_select) {
            that.first_to_select = node;
          }
          else {
            if (node && (node.compareDocumentPosition(that.first_to_select) & Node.DOCUMENT_POSITION_FOLLOWING)) {
              that.first_to_select = node;
            }
          }
        }

        if (that.has_matched_ancestor(root, parent_id, tree_model)) {
          that.mark_visible(node_id);
        }

        if (that.is_hidden(node_id)) {
          if (!skip_node_css_update) {
            maxkir.addClassName(node, "hiddenByFilter");
          }
          that.cssClasses[node_id] += " hiddenByFilter";
          that._listeners.notify_listener('node_hidden', node_id);
        }
        else {
          if (!skip_node_css_update) {
            maxkir.removeClassName(node, "hiddenByFilter");
          }
          that._listeners.notify_listener('node_shown', node_id);
        }
      });

      this._listeners.notify_listener('filter_done');
    },

    cssClass4: function(node_id) {
      if (!this.cssClasses) return "";
      return this.cssClasses[node_id] || "";
    },

    has_matched_ancestor: function(root, node, tree_model) {
      while(node) {
        if (node == root) return false;
        if (!this._unmatched[node]) return true;
        node = tree_model.getParent(node)
      }
      return false;
    },



    update_search_field: function() {
      if (this.is_filtered_by_text()) {
        maxkir.addClassName(findInput(), 'filtered');
      }
      else {
        maxkir.removeClassName(findInput(), 'filtered');
      }
    },

    filters: function() {
      return this._filters.slice().concat([TextFilter]);
    },

    active_filters: function() {
      if (this._active_filters_cache) {
        return this._active_filters_cache;
      }
      
      var res = [], 
          filters = this.filters(),
          l = filters.length;
      
      for(var i = 0; i < l; i ++) {
        var f = filters[i];
        if (f.get_active()) {
          if (typeof f.get_subfilters === 'function') {
            res = res.concat(f.get_subfilters());
          }
          else {
            res.push(f);
          }
        }
      }

      this._active_filters_cache = res;
      return res;
    },

    /** parse input string and prepare data for fast filtering */
    parse_input: function(line) {
      if (typeof line === "undefined") {
        line = this.val();
      }
      this.reset_filter_caches();
      this.parse_input_line(line);
    },

    val: function() {
      return findInput() ? findInput().value.trim() : "";
    },

    parse_input_line: function(line) {

      var original_line = line;
      var filters = this.filters();
      for(var i = 0; i < filters.length; i ++) {
        var filter = filters[i];
        if (filter.parse_line) {
          line = filter.parse_line(line, original_line);
        }
      }
      return line;
    },

    task_data: function(node_id) {
      return TaskDataProvider.from_node_id(node_id, this._tree_model);
    },

    /**
     * Overridable
     */
    should_hide: function(node_id) {
      var data = this.task_data(node_id);
      if (!data) {
        return false;
      }
      return this._should_hide(node_id);
    },

    _should_hide: function(node_id) {
      var unaccepted = this.active_filters().slice();
      var direct_match_candidate;
      var should_hide_now;
      var that = this;

      // https://discuss.checkvist.com/t/boolean-operations-in-search-and-saved-searches/151/6
      // Request from xBergade
      var force_strict_filter = localStorage.getItem('strict_filter');
      
      var remove_accepted = function(filters, data, curr_node_id) {
        var res = [], l = filters.length;
        for(var i = 0; i < l; i ++) {
          var match_value = filters[i].accept_item(data, that._tree_model.isLeaf(curr_node_id));

          if (!match_value) {
            if (force_strict_filter || filters[i].get_active() === 'strict_filter' ) {
              // That means, we should not analyze parents - strict match is needed with the requested node
              should_hide_now = true;
            }
            res.push(filters[i]);
          }
          else {
            if (curr_node_id === node_id) {
              // if requested node has some match for some condition in the filter
              direct_match_candidate = direct_match_candidate ?
                                       direct_match_candidate + "|" + match_value : match_value;
            }
          }
        }
        return res;
      };
      
      var curr_node_id = node_id;
      while(curr_node_id && unaccepted.length > 0) {

        var task_data = this.task_data(curr_node_id);
        if (!task_data) break;
        
        unaccepted = remove_accepted(unaccepted, task_data, curr_node_id);
        if (should_hide_now) {
          return true;
        }
        
        curr_node_id = this._tree_model.getParent(curr_node_id);
      }

      if (direct_match_candidate && unaccepted.length === 0) {
        // console.warn(node_id, direct_match_candidate)
        this._direct_match[node_id] = direct_match_candidate;
      }
      return unaccepted.length > 0;
    },

    activate: function(keep_selection) {
      this._active = true;
      var pos = findInput().value.length;
      maxkir.set_selection(findInput(), pos, pos);
      findInput().focus();
      if (this.tree_nav() && !keep_selection) {
        this.tree_nav().hide_selection();
        this.tree_nav().unfreeze_selection();
        this.tree_nav().unfreeze_selection();
      }
      this.mark_focused();

      if (this.completer) {
        this.completer.trigger_activation();
      }
    },

    passivate: function() {
      this._active = false;
      if (!findInput()) {
        return;
      }

      this.mark_unfocused();
      findInput().blur();

      // Set timeout in order to keep active while onClick is processed when selecting completer item
      setTimeout(function() {
        if (this.completer && !this._active) {
          this.completer.hide();
        }
      }.bind(this), 250);

      var tree = this.tree_nav(); 
      if (tree && !tree.selection_visible()) {
        tree.fix_selection(true);
      }

      if (!this.can_filter()) {
        var that = this;
        setTimeout(function() {
          if (!that._active) {
            that.clear_search_field();
          }
        }, 1000);
      }
    },

    mark_focused: function() {
      maxkir.addClassName(findInput(), 'focused');
      this._listeners.notify_listener('focus_changed', true);
    },

    mark_unfocused: function() {
      maxkir.removeClassName(findInput(), 'focused');
      this._listeners.notify_listener('focus_changed', false);
    },

    _f: null
  }


};

//==========================================================================
maxkir.FindFilterProvider.TagFilter = {
  TAG: /(?:^|\s)(?:#|tag:\s*)(\S+)/g,

  regexp: function() {
    return /(?:#|tag:\s*)(\S+)/g;
  },

  parse_line: function (line) {
    return this._parse_tags(line);
  },

  accept_item: function(task_data) {
    return this._matches(task_data) ? "tag" : null
  },

  get_active: function() {
    return this.tags.length > 0;
  },

  get_subfilters: function() {
    var res = [];
    for(var i = 0; i < this.tags.length; i ++) {
      var sub_filter = {};
      sub_filter.accept_item = this.accept_item;
      sub_filter._matches = this._matches;
      sub_filter.get_active = this.get_active;
      sub_filter.tags = [this.tags[i]];
      res.push(sub_filter);
    }
    return res;
  },

  _parse_tags: function(line) {
    var tags = [];
    line = line.replace(this.TAG, function(str, tag) {
      tags.push(tag);
      return "";
    });
    this.tags = tags;
    return line;
  },

  _matches: function(data) {
    var t_new = [];
    this.tags.forEach(function(tag_name) {
      if (!data.tags || !data.tags.hasOwnProperty(tag_name)) {
        t_new.push(tag_name);
      }
    });
    return t_new.length == 0;
  }
};
//==========================================================================
//==========================================================================
maxkir.FindFilterProvider.DueFilter = {
  DUE: /(?:due:\s*|\^)(asap|overdue|yesterday|now|any|none|(this |current |next |previous |last )?week|(this |current |next |previous |last )?month|[-\w, \/\\]+\d{2}|today|tomorrow)\b/i,

  regexp: function() {
    return this.DUE;
  },

  parse_line: function(line, original_line) {
    this.has_scope = original_line.match(maxkir.FindFilterProvider.StatusFilter.SCOPE);

    return this._parse_due(line);
  },

  accept_item: function(task_data, is_leaf) {
    if (task_data.status && !this.has_scope) return null;
    
    return !(this.should_hide_by_due && this.should_hide_by_due(task_data, is_leaf)) ? "due" : null;
  },

  get_active: function() {
    return this.should_hide_by_due != null;
  },

  _matches: function(data, is_leaf) {
    if (this.d === 'none' && !is_leaf) return false;
    return maxkir.dates.within(this.d, data.due)
  },

  _parse_due: function(line) {
    this.should_hide_by_due = null;
    return line.replace(this.DUE, function(str, due) {

      this.d = due.toLowerCase();
      this.should_hide_by_due = function (task_data, is_leaf) {
        return !this._matches(task_data, is_leaf);
      }.bind(this);

      return "";
    }.bind(this));
  }

};
//==========================================================================
maxkir.FindFilterProvider.StatusFilter = {
  SCOPE: /in:\s*(closed|all|open)\b/i,

  regexp: function() {
    return this.SCOPE;
  },

  parse_line: function(line, original_line) {
    this.has_due = original_line.match(maxkir.FindFilterProvider.DueFilter.DUE);

    return this._parse_status(line);
  },

  accept_item: function(task_data) {
    if (this.should_hide_by_status != null && this.should_hide_by_status(task_data)) return null;

    if (this.has_due) {
      if (this.should_hide_by_status == null && task_data.status) return null; // hide completed items
    }
    return "status";
  },

  get_active: function() {
    return this.should_hide_by_status != null ? 'strict_filter' : null;
  },

  _parse_status: function(line) {

    this.should_hide_by_status = null;
    return line.replace(this.SCOPE, function(str, status) {
      if (status == "closed") {
        this.should_hide_by_status = function(task_data) { return !task_data.status; }
      }
      else if (status == "open") {
        this.should_hide_by_status = function(task_data) { return task_data.status > 0; }
      }
      return "";
    }.bind(this));
  }

};
//==========================================================================
maxkir.FindFilterProvider.ChangedFilter = {
  CHANGED: /(changed|created|updated):\s*(\d+([hdw])\w*|today|yesterday|(this |current |previous |last )?week|(this |current |previous |last )?month|[-\w, \/\\]+\d{2})\b/g,

  regexp: function() {
    return this.CHANGED;
  },

  parse_line: function(line, original_line) {
    return this._parse_changed(line);
  },

  accept_item: function(task_data) {
    if (this.should_hide_by_updated_at) {
      return  (!this._should_hide(this.should_hide_by_updated_at, task_data.updated_at)) ? "updated" : null;
    }
    if (this.should_hide_by_created_at) {
      return  (!this._should_hide(this.should_hide_by_created_at, task_data.created_at)) ? "created" : null;
    }
    return null;
  },

  get_active: function() {
    return (this.should_hide_by_updated_at != null || this.should_hide_by_created_at != null) ? "strict_filter" : null;
  },

  _parse_changed: function(line) {

    this.should_hide_by_created_at = null;
    this.should_hide_by_updated_at = null;
    return line.replace(this.CHANGED, function(str, attr, when) {
      const checker = (date) => { return !maxkir.dates.within(when, date); }
      const function_name = attr === 'created' ? 'should_hide_by_created_at' : 'should_hide_by_updated_at';
      this[function_name] = checker;
      return "";
    }.bind(this));
  },

  _should_hide: function(checker_func, datetime) {
    if (!checker_func) return false;

    const date = new Date(datetime);
    if (!date || isNaN(date)) return true;
    return checker_func(date);
  }
};
//==========================================================================
maxkir.FindFilterProvider.NotAttachmentFilter = {
  ATTACHMENT_NOTE_BACKLINK: /\bhas:\s?(attachment|note|backlink)\b/ig,

  regexp: function() {
    return this.ATTACHMENT_NOTE_BACKLINK;
  },

  parse_line: function(line, original_line) {
    return this._parse_has(line);
  },

  accept_item: function(task_data) {
    if (this.withNotes && !this._should_hide_by_note(task_data)) return "has_note";
    if (this.withAttachments && !this._should_hide_by_attachment(task_data)) return "has_attachment";
    if (this.withBacklinks && !this._should_hide_by_backlinks(task_data)) return "has_backlink";
    return null;
  },

  get_active: function() {
    return this._get_active() ? "strict_filter" : null;
  },

  _get_active: function() {
    return this.withAttachments || this.withNotes || this.withBacklinks;
  },

  _parse_has: function(line) {

    var withAttachments = false;
    var withNotes = false;
    var withBacklinks = false;
    line = line.replace(this.ATTACHMENT_NOTE_BACKLINK, function(str, clr) {
      if (clr === "attachment") {
        withAttachments = true;
      }
      else if (clr === 'note') {
        withNotes = true;
      }
      else if (clr === 'backlink') {
        withBacklinks = true;
      }
      return "";
    });
    this.withAttachments = withAttachments;
    this.withNotes = withNotes;
    this.withBacklinks = withBacklinks;
    return line;
  },

  _should_hide_by_attachment: function(task_data) {
    if (!this.withAttachments) return false;

    if (task_data.details && task_data.details['uploads_count'] > 0) {
      return false;
    }
    if (task_data.uploads && task_data.uploads.length > 0) {
      return false;
    }

    return true;
  },

  _should_hide_by_note: function(task_data) {
    if (!this.withNotes) return false;
    if (task_data.comments_count > 0) return false;
    if (task_data.notes && task_data.notes.length > 0) return false;

    return true;
  },

  _should_hide_by_backlinks: function(task_data) {
    if (!this.withBacklinks) return false;
    if (task_data.backlink_ids && task_data.backlink_ids.length > 0) return false;
    return true;
  }

};
//==========================================================================
maxkir.FindFilterProvider.LinkFilter = function(context) {
  this.extract_text_lines = context.extract_text_lines;
}
maxkir.extend(maxkir.FindFilterProvider.LinkFilter, {

  LINK: /\bhas:\s?(link|hyperlink)\b/ig,

  parse_line: function(line, original_line) {
    return this._parse_links_condition(line);
  },

  accept_item: function(task_data) {
    if (this.withLinks) {
      return this._should_hide_by_link(task_data);
    }
    return null;
  },

  get_active: function() {
    return this.withLinks ? "strict_filter" : null;
  },

  _parse_links_condition: function(line) {
    var withLinks = false;
    line = line.replace(this.LINK, function(str, clr) {
      withLinks = true;
      return "";
    });
    this.withLinks = withLinks;
    return line;
  },

  _should_hide_by_link: function(task_data) {
    var lines = this.extract_text_lines(task_data);
    var res = '';
    for(var i = 0; i < lines.length; i ++) {
      if (!this._should_hide_from_text(lines[i][0], task_data.id)) {
        var match_type = lines[i][1];
        if (res.length) {
          if (res.indexOf(match_type) === -1) {
            res += '|' + match_type;
          }
        }
        else {
          res = match_type;
        }
      }
    }
    return res ? res : null;
   },

   _should_hide_from_text: function(content, task_id) {
    var txt = maxkir.CommonRender.format_for_rendering(content, true, {
      task_id: task_id,
    })

    if (txt.indexOf("/checklists/") > 0 || txt.indexOf("/cvt/") > 0) {
      return false;
    }

    var http = txt.indexOf("http")
    if (http > 0 && (txt.charAt(http - 1) === '"' || txt.charAt(http - 1) === "'")) {
      return false;
    }

    return true;
  }

});
