define(function (require, exports, module) {

    var app = require('app');
    var maxkir = require("app/maxkir");
    var TagRenderer = require("app/controls/tag_renderer");
    var FilterControl = require("app/controls/filter_field_control");
    var Completer = require("app/controls/completion_helper_component");
    var List = require("app/model/list");

    var can = app.can;

    // List section should have ID ul#home_list
    // Text control should have class  input.filter_or_search

    /**
     * @class ListFilterControl
     */
    var ListFilterControl = can.Control.extend({
        init: function(element, options) {
            this.init_with_lists(options.lists);
        },

        destroy: function() {
            this.off();
            if (this._filter_control)
                this._filter_control.destroy();
            
            if (this.list_filter)
                this.list_filter.dispose();
        },

        init_with_lists: function(lists) {
            var self = this;
            var tree_model = new maxkir.TreeModel('home_list');

            var tree_nav = new maxkir.TreeNavigation(tree_model, 'home_list');
            self.list_filter = maxkir.FindFilterProvider(maxkir, self.search_field().id, {
                tree_nav: function() {return tree_nav;},
                tree_model: function() {return tree_model;}
            }, {
                from_node_id: function (node_id) {
                    var list_data = $("#" + node_id).data('list');
                    if (list_data) {
                        return {
                            content: list_data.name,
                            tags: list_data.tags
                        }
                    }
                    return null;
                }
            });
            self.list_filter.add_filter(maxkir.FindFilterProvider.TagFilter);

            self.list_filter.add_listener({
                filter_done: function() {
                    setTimeout(function() {
                        self.rerender(lists);
                    }, 10)
                }
            });

            self.rerender(lists);
            this.on();
            
            this._filter_control = new FilterControl(this.element, { 
                filter: self.list_filter,
                completer_model: this._create_completer_model()
            });
        },

        /**
         * @return CompleterModel
         */
        _create_completer_model: function () {
            var that = this;
            var providers = [new Completer.TagCompletionProvider()];

            return {
                get_nodes: function() {
                    providers.forEach(function(provider) {
                        provider.start_processing();
                    });

                    that._lists.forEach(function(list) {
                        providers.forEach(function(provider) {
                            provider.process_task(list);
                        });
                    });

                    var res = [];
                    providers.forEach(function(provider) {
                        res = res.concat(provider.get_completion_nodes())
                    });
                    return res;
                }
            }
        },

        /**
         * @returns {Element}
         */
        search_field: function() {
            return this.element.find(".filter_or_search")[0];
        },

        rerender: function(lists) {
            var that = this;
            if (!that.list_filter) return;

            this._lists = lists.attr();
            
            List.sort_by_user_updated_at(this._lists);
            var content = '<ul class="homeList"  id="home_list">';
            this._lists.forEach(function(list) {
                content += that._render_list_item(list);
            });
            content += '</ul>';

            $(".listsSection").html(content);
            this._lists.forEach(function(list) {
                var el = document.getElementById('list_' + list.id);
                if (el) {
                    $(el).data('list', list);
                }
            });
        },
        
        _render_list_item: function(list) {
            var content = '';
            var listCss = "list ";
            listCss += this.list_filter.cssClass4('list_' + list.id);
            listCss += " " + (List.has_data(list) ? "" : "noData");
            listCss += " " + (list.is_local ? "listLocal" : "");
            content += '<li id="list_' + list.id + '" class="' + listCss + '">';

            if (typeof list.tags !== 'undefined') {
                content += TagRenderer.get_html(list.tags);
            }

            content += this.list_filter.highlight_text(maxkir.CommonRender.sanitize_text(list.name));
            if (list.read_only) {
                content += '<span class="readOnly"><i class="far fa-lock"/> ' +  I18N.t('readOnly') + '</span>';
            }
            return content + '</li>';
        },
        

        /**
         * @param text text to set to filter
         */
        refilter_with_text: function(text) {
            this._filter_control.refilter_with_text(text);
        },
        
        "{lists} change": function(lists, event, property, operation) {
            var that = this;
            // console.warn("Lists changed: ", event, property, operation);

            // Case when items added/removed or sorting should change - full rerender 
            if (operation === 'add' || operation === 'remove' || property.indexOf("updated_at")) {
                this.rerender(this.options.lists);
                return;
            }

            // Case when a list item is changed:
            var list = event.target;
            if (list) {
                var li = $('#list_' + list.id);
                if (!li.length) return;
                
                app.debug("Rerender list item", list);
                li.replaceWith(that._render_list_item(list));
                $('#list_' + list.id).data('list', list);
            }
        },

        "#home_list > li touchstart": function(element, event) {
            // To make sure :active selector is set on touchd elements
        },

        "#home_list > li click": function(element, event) {
            if (this.options.onClick) {
                this.options.onClick.call(this, element, event);
            }
        },

        ".tag click": function(element, event) {
            event.stopPropagation();
            this.refilter_with_text("#" + element.text());
        }

    });

    module.exports = ListFilterControl;
});