import app from "app";
import maxkir from "app/maxkir";

import BaseAddPage from "./base_add_page";

import List from "app/model/list";

import commands from "app/command_queue";

import BookmarkletCommand from "app/commands/bookmarklet_command";

import ListFilterControl from "app/controls/list_filter";

import import_textarea from "app/controls/import_content";
import import_textarea_hints from "app/controls/import_hints";

import SlidingPanel from "app/controls/sliding_panel";

const can = app.can;

import util from 'app/jquery.util';


const AddUploadControl = can.Control({
    init: function (element) {
        this.files = element.find('.filesSection');
    },

    "input[type=file] change": function (input, event) {

        if (input[0].files.length == 1) {
            const file = input[0].files[0];

            const span = this.create_attachment_element(file);

            const photos = this.files.find(".photos");
            photos.prepend(span);
            photos.show("slow");
        }

    },

    "input[type=file] click": function (input, event) {

        if (!app.current_user.pro) {
            event.preventDefault();
            util.alert(I18N.t('tryProToAttach'));
        }

    },

    create_attachment_element: function (file) {
        const span = $("<span class='upload-placeholder'/>");
        span.data("upload-file", file);
        span.attr("title", file.name);

        this.prepare_element_content(file, span);
        this.install_click_handler(span);

        return span;
    },

    install_click_handler: function (span) {
        span.on("click", function () {
            if (confirm(I18N.t("remove.attachment"))) {
                const photos = span.parent(".photos");
                span.remove();
                if (photos.find(".upload-placeholder").length == 0) {
                    photos.hide("slow");
                }
            }
        });
    },

    prepare_element_content: function (file, span) {
        if (file.type.indexOf("image/") < 0) {
            span.append('<i class="far fa-file-text"/>');
        }
        else {
            const reader = new FileReader();
            reader.onload = function (loadEvent) {
                const preview = $('<img/>');
                span.append(preview);
                preview[0].src = loadEvent.target.result;
            };
            reader.readAsDataURL(file);
        }
    },

    destroy: function () {
        maxkir.info("Destroy AddUploadControl"); // works :)
        can.Control.prototype.destroy.call(this);
    }
});


/**
 * @class BookmarkletPage
 */
const BookmarkletPage = BaseAddPage.extend('BookmarkletPage', {
        defaults: {
            template: "bookmarklet_page.mustache",
            page_title: "Checkvist: " + I18N.t("quick.add").toLocaleLowerCase()
        }
    },
    {
        onRender: function () {
            BaseAddPage.prototype.onRender.apply(this, arguments);

            this.init_list_filter();
            this.upload_control = new AddUploadControl(this.element);

            if (this.keeper) {
                this.keeper.dispose();
            }

            import_textarea_hints(this.element, this.clicker);

            if (this.options.params) {
                $('#import_content').val(this.get_initial_text(this.options.params));
            }
            this.keeper = import_textarea.init_field();

            $('#import_content').focus();
            $('#import_content').click();  // Trying to force showing the keyboard
        },

        get_initial_text: function(params) {
            var pre_fill = params.title || "";
            if (params.url) {
                pre_fill = params.title ?
                    "[" + params.title + "](" + params.url + ")" : params.url;
            }

            if (params.text) {
                if (pre_fill) {
                    pre_fill += "\n\n";
                }
                pre_fill += params.text;
            }
            return pre_fill;
        },


        get_add_button_code: function() {
            return 'done';
        },

        get_main_field_value: function() {
            return $('#import_content').val();
        },

        create_and_run_command: function() {
            const that = this;
            new BookmarkletCommand().init_from($("#bookmarklet")).then(function (command) {
                commands.push_command(command);
                that.clear_form();
                cv_route.back();

            }, function (err) {
                $('.addPageHeader__submit')[0].disabled = false;
                app.msg.show_error("Cannot send note with attachment, database error: " + err);
            })
        },

        on_empty_submit: function() {
            if ($(".upload-placeholder").length) {
                $('#import_content').val(I18N.t('empty.item.text'));
                this.do_submit();
            }
            else {
                this.cancel_add();
            }
        },



        destroy: function () {
            if (this.upload_control) {
                this.upload_control.destroy();
            }
            if (this.keeper) {
                this.keeper.dispose();
            }
            BaseAddPage.prototype.destroy.call(this);
        },


        get_model: function () {
            return this.options;
        },

        ".listChooser change": function (select, event) {
            this.init_list_filter_text(select);
        },

        "#bookmarklet submit": "_submit_handler",

        on_note_focus: function() {
            BaseAddPage.prototype.on_note_focus.apply(this, arguments);

            const that = this;
            setTimeout(function fix_scroll() {
                app.debug('Avoid non-needed scrolling on iOS on page open');
                that._fix_scroll();
            }, 500);
        },

        _fix_scroll: function () {
            //console.trace();
            window.scrollTo(0, 0);
        },


        clear_form: function () {
            this.keeper.clear_value();

            $(".photos").hide("slow");
            $(".photos").html("");
        },

        "#checklist_id_text touchstart": function (element, e) {
            const that = this;
            this.click_handler('cancel_back_bookmarklet', e, function () {
                that.initialise_list_chooser(element, e);
            });
        },

        "#checklist_id_text click": function (element, e) {
            const that = this;
            this.click_handler('cancel_back_bookmarklet', e, function () {
                that.initialise_list_chooser(element, e);
            });
        },

        "#checklist_id_text focus": function () {
            this.initialise_list_chooser.apply(this, arguments);
        },

        initialise_list_chooser: function (element, event) {
            if (document.activeElement) {
                document.activeElement.blur();
            }
            element.blur();
            event.preventDefault();

            if (this._active_chooser) return;
            this.show_list_selector();
            this._active_chooser = true;
        },

        SELECTOR_DELAY: 300,
        show_list_selector: function () {
            const height = $("main")[0].offsetHeight;

            const section = $('.listSelectorSection');
            section.css({
                top: height + "px",
                height: height + "px"
            });
            section.show();
            new SlidingPanel(section).show();

            setTimeout(function () {
                // Due to fixed header and footer:
                $('footer').hide();
                $('header').hide();
                $('header.listSelector').show();

                app.debug("List selector shown");
            }, this.SELECTOR_DELAY);
        },

        do_hide_list_selector: function () {
            if (!this._active_chooser) {
                return false;
            }

            window.scrollTo(0, 1);
            this._active_chooser = false;
            const section = $('.listSelectorSection');
            new SlidingPanel(section).hide();
            setTimeout(function () {

                section.hide();
                app.debug("List selector hidden");

                // Should be done later to avoid showing of headerMenu of main page
                // when "Cancel" is pressed
                $('footer').show();
                $('header').show();
                $('header.listSelector').hide();

            }, this.SELECTOR_DELAY);

            return true;
        },

        goBack: function() {
            this.cancel_add();
        },

        cancel_add: function () {
            if (this.do_hide_list_selector()) {
                return;
            }

            cv_route.back();
        },

        default_back_url: function () {
            return this.options.list_id ? "/app/list/" + this.options.list_id : "/app/index.html";
        },

        init_list_filter: function () {
            const that = this;
            const select = $('select.listChooser');
            if (!select.length) {
                app.debug("Skip list selector - no target to append to");
                return;
            }

            List.findAll({sort_func: List.sort_by_user_updated_at}, function add_lists_to_ui(lists) {

                let to_select = app.storage.get_item("bookmarklet_list_id");
                app.debug("Appending lists to list selector, to select: " + to_select);

                let options = "";
                for (let i = 0; i < lists.length; i++) {
                    const list = lists[i];
                    if (!list.read_only(null)) {
                        const selected = list.id == to_select ? " selected" : "";
                        options += '<option value="' + list.id + '" ' + selected + '>' + can.esc(list.name) + '</option>\n';
                    }
                }
                select[0].innerHTML = options;
                if (!to_select) {
                    select[0].selectedIndex = 0;
                }
                app.debug("List selector filled with options");

                that.init_list_filter_text(select);
                new ListFilterControl(".listSelectorSection", {
                    onClick: function (li, event) {
                        event.stopPropagation();

                        that.do_hide_list_selector();
                        select.val(li.attr("id").substr("list_".length)); // Select in combobox
                        that.init_list_filter_text(select);
                        app.storage.set_item("bookmarklet_list_id", select.val());
                    },
                    lists: lists
                });
                app.debug("List filter prepared");

            });
        },

        init_list_filter_text: function (select) {
            $('#checklist_id_text').val(select.find("option:selected").text());
        },

        f_: null
    });
export default BookmarkletPage;
