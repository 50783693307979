
import BaseAddPage from "./base_add_page";

import commands from "app/command_queue";
import Task from "app/model/task";
import add_note from "app/controls/import_content";

import CreateNoteCommand from "app/commands/create_note_command";

/**
 * @class AddNotePage
 */
const AddNotePage = BaseAddPage.extend('AddNotePage', {
            defaults: {
                template: "add_note_page.mustache",
                page_title: "Checkvist: " + I18N.t("addNewNoteTitle").toLocaleLowerCase()
            }
        },
        {
            onRender: function() {
                BaseAddPage.prototype.onRender.call(this);

                $('.newNote').focus();
                if (this.keeper) {
                    this.keeper.dispose();
                }
                this.keeper = add_note.init_field();
            },

            default_back_url: function () {
                return "/app/list/" + this.options.list_id;
            },

            get_add_button_code: function() {
                return 'create';
            },

            get_main_field_value: function() {
                return $('.newNote').val();
            },

            create_and_run_command: function() {

                const that = this;
                Task.findLocal(this.options.task_id).then(function(task) {
                    const cmd = new CreateNoteCommand(task);
                    cmd.set_note_content(that.get_main_field_value().trim());
                    commands.push_command(cmd).finally(function () {
                        maxkir.info("Go back after adding a note");
                        if (that.keeper) {
                            that.keeper.clear_value();
                        }
                        if (cv_route.getCurrentPath().indexOf("new_note") >= 0) {
                            cv_route.back();
                        }
                    });

                })

            },

            destroy: function () {
                if (this.keeper) {
                    this.keeper.dispose();
                }

                BaseAddPage.prototype.destroy.call(this);
            }
        });


export default AddNotePage;
