import app from "./app";

export default {
    is_darcula() {
        return app.current_user && app.current_user.pro && maxkir.get_flag("ui.theme") === "darcula";
    },

    set_darcula(darcula, temporary) {
        if (darcula) {
            if (!temporary) {
                maxkir.set_flag("ui.theme", "darcula");
            }

            $('<link rel="stylesheet" type="text/css" href="/js/app/darcula.css">').appendTo(document.head)
        }
        else {
            if (!temporary) {
                maxkir.set_flag("ui.theme", null);
            }

            $(document.querySelectorAll("link[href='/js/app/darcula.css']")).remove();
        }
    },

    enable_darcula_if_needed() {
        if (!this.is_darcula()) {
            return
        }

        this.set_darcula(true);
    }

}