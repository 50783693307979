import app from 'app'
import $ from "jquery";

class ClickHandler {

    /**
     * @param {jQuery} element
     */
    constructor(element) {
        this.el = element;
        if (!element) {
            console.error("No element for clicks!");
        }

        this.touchstartHandlers = {};
        this.suffix = "ch" + Math.random().toString().substr(2);
        this.click_handler = app.util.create_click_processor();
    }

    /**
     * @param {String} [cssSelector] to listen click on
     * @param {String} identity for debug and identity
     * @param {Function} handler has a param - event
     */
    add_click_handler(cssSelector, identity, handler) {
        const that = this;
        let start_pos;

        const touchstartHandler = function (event) {
            const parents = cssSelector ? that.el[0].querySelectorAll(cssSelector) : that.el[0];
            if (!parents.length) {
                return;
            }

            parents.forEach((parent) => {
                if (event.target === parent || parent.contains(event.target)) {
                    start_pos = app.util.touch_location(event);
                }
            });
        };

        this.el[0].addEventListener('touchstart', touchstartHandler, {passive: true});
        this.touchstartHandlers[this.suffix] = touchstartHandler;

        this.el.on(`touchend.${this.suffix} click.${this.suffix}`, cssSelector, function (event) {

            if (start_pos && event.type === 'touchend' && app.util.distance(start_pos, event) > 20) {
                return;
            }

            const element = $(this);
            that.click_handler(identity, event, function () {
                handler(event, element);
            });
        })
    }

    dispose() {
        this.el.off("." + this.suffix);
        this.el[0].removeEventListener('touchstart', this.touchstartHandlers[this.suffix], {passive: true});
        this.click_handler = null;
    }
}

export default ClickHandler;