define(function (require, exports, module) {

    var app = require('app');
    var maxkir = require("app/maxkir");
    var PositionFixedHider = require("app/controls/position_fixed_hider");
    var CompletionHelperComponent = require("app/controls/completion_helper_component").CompletionHelperComponent;
    var can = app.can;

    // List section should have ID ul#home_list
    // Text control should have class  input.filter_or_search

    /**
     * @class 
     */
    var FilterControl = can.Control.extend({
        /**
         * @constructor 
         * @param element
         * @param {Object} [options]
         */
        init: function(element, options) {
            var that = this;
            this._filter = options.filter;
            var f = this.search_field();
            f.addClass('filter_or_search');
            f.show();
            f.wrapAll('<div class="filter_wrapper"/>');
            
            this._wrapper()
                .append('<i class="far fa-search search_icon"/>')
                .append('<i class="far fa-times clear_filter" style="display: none;"/>')
                .on("touchstart click", ".clear_filter", function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    
                    this._filter.clear_search_field();
                    this.deactivate();
                }.bind(this));
            
            this._filter.delayedFilter = this._filter.createDelayedFilter();

            this._filter_listener = {
                filter_done: function() {
                    this._update_cross();
                }.bind(this)
            }; 
            this._filter.add_listener(this._filter_listener);
            
            this._completer = new CompletionHelperComponent(this._wrapper(), {
                input_model: {
                    current_text: function() {
                        return f[0].value;
                    },
                    
                    replace_token: function(token_info, text) {
                        var v = this.current_text();
                        
                        f[0].value = v.substr(0, token_info.at) + text + v.substr(token_info.at + token_info.text.length);
                        maxkir.set_selection_range(f[0], [token_info.at + text.length, token_info.at + text.length]);
                        f.focus();
                        that.update_filter();
                    },

                    /**
                     * @return {{text: string, at: number}} current token information
                     */
                    get_token_info: function() {
                        var v = this.current_text();
                        var range = maxkir.get_selection_range(f[0]);
                        var token = '';
                        for (var i = range[0] - 1; i >= 0; i --) {
                            var ch = v.charAt(i);
                            if (/\s/.test(ch)) {
                                break;
                            }
                            token = ch + token;
                        }
                        return {text: token, at: range[0] - token.length};
                    }
                } 
            });
            
            this._filter.hide_completer = function() {
                that._completer.hide();
            }
        },
        
        _wrapper: function() {
            return $('.filter_wrapper'); 
        },
        
        deactivate: function() {
            PositionFixedHider.unfixAndHideKeyboard();
            if (this._filter) {
                this._filter.passivate();
            }
        },

        destroy: function() {
            this._wrapper().off("touchstart click");

            if (this._filter) {
                this._filter.remove_listener(this._filter_listener);
                this._filter = null;
            }
            can.Control.prototype.destroy.call(this);
        },

        /**
         * @returns {Element}
         */
        search_field: function() {
            return $(this._filter.findInput());
        },

        /**
         * @param text text to set to filter
         */
        refilter_with_text: function(text) {
            this._filter.show(text);
        },
        
        update_filter: function() {
            this._filter.delayedFilter();
        },
        
        update_completer: function(data_changed) {
            if (this.options.completer_model) {
                if (data_changed || !this._nodes) {
                    this._completer.update_from_model(this.options.completer_model);
                }
                else {
                    this._completer.update_view();
                }
            }
        },

        _update_cross: function() {
            this._wrapper().find(".clear_filter")[this._filter.is_filtered_by_text() ? 'show' : 'hide']();
        },

        ".filter_or_search focus": function() {
            PositionFixedHider.disableFixedPosition(true);
            this._filter.activate();
            if (this.options.completer_model) {
                this.update_completer();
            }
            
            if (this.blur_timeout) {
                window.clearTimeout(this.blur_timeout);
                this.blur_timeout = null;
            }
        },
        ".filter_or_search blur": function() {
            // To fix issue when hiding keyboard changes page layout 
            // so list names are not clickable on index page on iOS  (due to position_fixed_hider)
            
            // also to delay hiding completer when clicking on completion link
            this.blur_timeout = setTimeout(function() {
                if (!maxkir._ignore_blur) {
                    this._completer.hide();
                    this.deactivate();
                }
            }.bind(this), 100)
        },
        ".filter_or_search change": function() {
            this.update_filter();
        },

        ".filter_or_search keyup": function(element, event) {
            switch (event.keyCode) {
                case 8: // backspace
                    this.update_completer();
                    this.update_filter();
                    break;
                case 13: // enter
                    break;
                case 27: // escape
                    this._filter.passivate();
                    break;
                case 9: // tab
                case 37: // left
                case 38: // up
                case 39: // right
                case 40: // down
                case 16: // Shift
                case 91: // Meta? Alt?
                case 17: // Ctrl
                    this.update_completer(); break;
                    break;
                default:
                    this.update_completer();
                    this.update_filter();
            }
        }

    });

    module.exports = FilterControl;
});