import BaseAddPage from "./base_add_page";

import CreateListCommand from "app/commands/create_list_command";
import BookmarkletCommand from "app/commands/bookmarklet_command";

import commands from "app/command_queue";
import import_textarea from "app/controls/import_content";
import import_textarea_hints from "app/controls/import_hints";

import TempObjects from "app/model/temp_objects";

/**
 * @class AddListPage
 */
const AddListPage = BaseAddPage.extend('AddListPage', {
            defaults: {
                template: "add_list_page.mustache",
                page_title: "Checkvist: " + I18N.t("addNewList").toLocaleLowerCase()
            }
        },
        {
            onRender: function() {
                BaseAddPage.prototype.onRender.call(this);

                $('.newListName').focus();
                if (this.keeper) {
                    this.keeper.dispose();
                }
                import_textarea_hints(this.element, this.clicker);

                this.keeper = import_textarea.init_field();
                $('#list_name').focus();
                $('#list_name').click();  // Trying to force showing the keyboard
            },

            get_add_button_code: function() {
                return 'create';
            },

            get_main_field_value: function() {
                return $('#list_name').val();
            },

            create_and_run_command: function() {
                const that = this;

                const listCmd = new CreateListCommand(this.get_main_field_value());
                commands.push_command(listCmd);

                const tasks_content = $('#import_content').val().trim();
                if (tasks_content) {
                    const taskCmdPromise = new BookmarkletCommand().init_simple(listCmd.list_id, tasks_content, 0, 1, []);
                    taskCmdPromise.then(function (cmd) {
                        if (that.keeper) {
                            that.keeper.clear_value();
                        }

                        commands.push_command(cmd);
                    });
                }

                commands.process_commands().always(function () {
                    TempObjects.find_replacement_id(listCmd.list_id).catch(function () {
                        return listCmd.list_id;
                    }).then(function (id) {
                        cv_route.goTo("/app/list/" + id);
                    });
                    return Promise.resolve();
                })
            },

            ".newListName change,.newListName keyup": function () {
                // Update list title with new list name
                $('.windowTitle .title').text($('.newListName').val());
            },


            destroy: function () {
                if (this.keeper) {
                    this.keeper.dispose();
                }

                BaseAddPage.prototype.destroy.call(this);
            }
        });


export default AddListPage;
