define(function (require, exports, module) {
    
    var $ = require('jquery');
    var noty = require('lib/jquery.noty.packaged');


    module.exports = {
        SUPPORTS_TOUCH: 'ontouchstart' in window,

        /**
         * 
         * Creates a function which should be used to process 'touchstart click' events
         * 
         * @typedef {Function} ClickProcessor
         * @param {string} ClickProcessor.non-empty name of the event 
         * @param {Event} ClickProcessor.event 
         * @param {Function} ClickProcessor.action for the event 

         * @return {ClickProcessor}
         */
        create_click_processor: function() {
            var has_touch = {};

            return function(name, event, action) {
                event.preventDefault();
                event.stopPropagation();
                maxkir.info("Click event: " + event.type + " " + name + " " + !!has_touch[name]);

                if (event.type.startsWith('touch')) {
                    has_touch[name] = true;
                    action();
                    
                    setTimeout(function () {
                        has_touch[name] = false;
                    }, 1000);
                }
                else if (event.type.startsWith('click') && !has_touch[name]) {
                    action();
                }
            }
        },

        /**
         * 
         * @param event
         * @return [clientX, clientY, pageX, pageY]
         */
        touch_location: function(event) {
            event = event.originalEvent || event;

            var touch_pos = function(touch) { return [touch.clientX, touch.clientY, touch.pageX, touch.pageY]; };

            switch(event.type) {
                case "mousedown":
                case "mouseup":
                    return touch_pos(event);
                case "touchstart":   return touch_pos(event.touches[0]);
                case "touchmove":
                case "touchend":     return touch_pos(event.changedTouches[0]);
            }
            throw Error("Unknown event for location: " + event.type, event);
        },

        /**
         *
         * @param Array[clientX, clientY, pageX, pageY] start
         * @param event
         */
        distance: function(start, event) {
            const end = this.touch_location(event);
            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            return Math.sqrt(dx*dx + dy*dy);
        },

        smooth_scroll: function(scrollTop) {
            maxkir.smoothScrollBy(scrollTop, null, 200);
        },

        scroll_to: function(element, elementHeight, scrollToTopIfPossible) {
            if (!element || !element.length) return;


            if (scrollToTopIfPossible) {
                maxkir.scrollTo2(element[0], 90, document.documentElement.clientHeight * 2 / 3, 200, document.body);
            }
            else {
                maxkir.scrollTo2(element[0], 90, 50 + elementHeight, 200, document.body);
            }
        },

        is_equal: function(a, b) {
            var size = 0;
            // This algorithm is not very efficient and correctly processes only top-level properties

            var ne = function(key, o1, o2) {
                // Special case for 'kinda date' fields
                if (key.indexOf("updated_at") >= 0 && (typeof o1 === 'string')) {
                    return Date.parse(o1) != Date.parse(o2);
                }

                return JSON.stringify(o1) != JSON.stringify(o2);
            };

            for (var key in a) {
                if (a.hasOwnProperty(key)) {
                    // Count the expected number of properties.
                    size++;
                    // Compare each member, not deeply but still
                    //console.info(key, a[key], b[key], b.hasOwnProperty(key), JSON.stringify(a[key]), JSON.stringify(b[key]))
                    if (!b.hasOwnProperty(key) || ne(key, a[key], b[key])) return false;
                }
            }
            // Ensure that both objects contain the same number of properties.
            for (key in b) {
                if (b.hasOwnProperty(key)) {
                    size--;
                }
                if (size < 0) return false;
            }
            // Should be zero
            return !size;
        },

        dump: function(arr) {
            var res = '[';
            if (arr) {
                arr.forEach(function (a) {
                    res += '{id=' + a.id + ";_cid=" + a._cid + 
                        ";content=" + a.content + 
                        ";children=" + a.children + 
                        "}";
                });
            }
            else {
                return '<null>';
            }
            return res + ']';
        },

        /**
         * @param text
         * @param okText
         * @param okCallback on OK, Promise will be resolved to the value provided by this callback
         * @return {Promise}
         */
        confirmation: function(text, okText, okCallback) {

            if (typeof QUnit !== 'undefined') {
                return Promise.resolve(okCallback());
            }

            return new Promise((_resolve, _reject) => {

                noty({
                    text: text,
                    layout: 'bottomRight',
                    theme: 'relax',
                    type: 'checkvist',
                    animation: {
                        open: "animated slideInUp",
                        close: "animated slideOutDown"
                    },
                    modal: true,
                    closeWith: ['button'],
                    callback: {
                        afterClose: function () {
                            _reject("CANCEL")
                        }
                    },
                    buttons: [
                        { addClass: 'btn buttonPrimary', text: okText, onClick: function ($noty) {

                                // this = button element
                                // $noty = $noty element

                                _resolve(okCallback());
                                $noty.close();
                            } },
                        { addClass: 'btn buttonLight', text: I18N.t('cancel'), onClick: function ($noty) {
                                $noty.close();
                            } }
                    ]
                });

            });
        },

        alert: function(text) {

            if (typeof QUnit !== 'undefined') {
                return;
            }

            noty({
                text: text,
                type: 'checkvist',
                layout: 'topRight',
                theme: 'relax',
                // animation: {
                //     open: "animated slideInUp",
                //     close: "animated slideOutDown"
                // },
                modal: true,
                closeWith: ['button'],
                buttons: [
                    { addClass: 'btn buttonPrimary', text: "Got it", onClick: function ($noty) {

                            // this = button element
                            // $noty = $noty element

                            $noty.close();
                        }
                    }
                ]
            });
        }
    };
    
});