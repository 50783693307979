define(function (require, exports, module) {
    
    require('app/maxkir');
    require('app/maxkir/value_keeper');

    /**
     * @type {{init_field: init_field}}
     */
    var ImportContentFieldHelper = {

        /**
         * @return {maxkir.ValueKeeper} to be disposed!
         */
        init_field: function () {

            var el = $('.addNote');

            if (maxkir.Safari || maxkir.FF) {
                var ps = el.attr('placeholder');
                el.attr('placeholder', ps.replace(/\n+\s*/g, " // "));
            }

            var result = new maxkir.ValueKeeper(el[0]);
            if (el.val()) {
                // This select() has a side-effect that keyboard is not shown on Android automatically
                // so we don't want to do it when there is no value in the field
                el.select();
            }
            return result;
        }
    }; 

    module.exports = ImportContentFieldHelper; 
});