maxkir.BaseRenderExtension = {
  _rerendering: false,

  without_rerender: function(callback) {
    this._rerendering = true;
    try {
      callback()
    }
    finally {
      this._rerendering = false;
    }
  },

  rerendering: function () {
    return this._rerendering;
  },

  _update_task: function (task_id, context) {
    if (context.is_pwa) {
      var page = CV.current_page();
      if (page && page.task_tree) {
        page.task_tree().force_rerender_task_now([task_id]);
      }
    }
    else {
      maxkir.Task.updateTask(task_id);
      maxkir.ProgressCounter && maxkir.ProgressCounter.on_task_updated(maxkir.Task.data(task_id));
    }
  },

}