
/**
 * @typedef {Object} Upload
 * @property {String} id
 * @property {String} uuid
 * @property {String} [upload_link]
 * @property {String} url
 * @property {String} thumb_url
 * @property {String} the_file_name
 * @property {String} the_content_type
 * @property {String} the_updated_at
 * @property {number} the_file_size
 * @property {Object} [user]
 */


maxkir.UploadShared = {

  IMG_REG: /\[img: ?([^\]]+)]/g,
  IMG_REG_MD: /!\[([^\]]*)]\(([^)]+)\)/g,

  /**
   * @param {Upload} upload
   * @returns {boolean}
   */
  is_img: function(upload) {
    return upload && upload.the_content_type &&
           upload.the_content_type.indexOf('image/') === 0;
  },

  is_pdf: function(upload) {
    return upload && upload.the_content_type &&
           upload.the_content_type.indexOf('/pdf') > 0;
  },

  is_txt: function(upload) {
    return upload && upload.the_content_type &&
           upload.the_content_type.indexOf('/text') > 0;
  },

  is_archive: function(upload) {
    return upload && upload.the_content_type &&
           upload.the_content_type.indexOf('zip') > 0;
  },

  /**
   * @param {Array<Upload>} uploads
   * @param {String} txt
   * @returns String
   */
  convert_images: function(uploads, txt) {

    if (-1 === txt.indexOf("![") && -1 === txt.indexOf("[img")) {
      return txt;
    }

    var render_img = function(url, url_key, title, width, height) {
      var scale = 100;
      if (title) {
        if (title.charAt(title.length - 1) === '%') {
          var match = title.match(/\|?(\d+)%$/);
          if (match) {
            scale = match[1];
            title = title.substring(0, match.index);
          }
        }
        if (match = title.match(/\|?(\d+)x(\d+)/)) {
          width = match[1];
          height = match[2];
          title = title.substring(0, match.index);
        }
      }

      var img_css = '';

      if (!maxkir.isWidget) { // Scale images only on bigger screens
        if (width > 0 && height > 0) {
          var w = (width * scale/100.0);
          img_css = "width:" + w + "px;height:auto;";

          if (width > 800 && scale < 100) {
            // Limit image size by max-width
            img_css += "max-width:" + parseInt(scale) + "%;";
          }
        }
        else {
          img_css += "max-width:" + parseInt(scale) + "%;";
        }
      }

      title = title ? maxkir.escapeHtml(title) : '';
      return "<div class='renderedImages'><img src='" + maxkir.escapeHtml(url) + "' alt=\"" + title +
          "\" style='" + img_css +
          "' data-imgUrl='" + maxkir.escapeHtml(url_key) +
          "' data-scale='" + scale +
          "'></div>";
    };

    // New style for img: tag rendering (Markdown)
    txt = txt.replace(this.IMG_REG_MD, function(match, title, url) {
      if (url.indexOf('/') >= 0) {
        return render_img(url, url, title);
      }
      else {
        for(var i = 0; i < uploads.length; i ++) {
          if (uploads[i].the_file_name === url && this.is_img(uploads[i])) {
            return render_img(uploads[i].url, url, title, uploads[i].width, uploads[i].height);
          }
        }
      }
      return match;
    }.bind(this));

    // Old style for img: tag rendering
    txt = txt.replace(this.IMG_REG, function(match, url) {

      for(var i = 0; i < uploads.length; i ++) {
        if (uploads[i].the_file_name === url && this.is_img(uploads[i])) {
          return render_img(uploads[i].url, url,'', uploads[i].width, uploads[i].height);
        }
      }
      return match;
    }.bind(this));

    return txt;
  }

};
maxkir.RenderExtensions.add_renderer({
  process_text: function(text, context) {
    return maxkir.UploadShared.convert_images(context.uploads || [], text)
  }
});
